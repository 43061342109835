import withStyles from "@material-ui/core/styles/withStyles";
import { LoadPanel } from "devextreme-react";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import * as W94F1000Actions from "../../actions/w94/W94F1000/W94F1000-actions";
import Config from "../../config/index";
import ButtonCustom from "../common/button/button-custom";
import ButtonExportReport from "../common/button/button-export-report";

const styles = theme => {
    return {
        btnPrint: {
            textTransform: 'uppercase',
            padding: '8px 15px',
            marginLeft: 'auto',
            [theme.breakpoints.down(768)]: {
                '& span>.mgl5': {
                    display: 'none'
                }
            },
        },
        iframe: {
            maxWidth: '100%',
            maxHeight: 'calc(100% - 30px)',
            width: '100%',
            height: '100%'
        },
        reportTitle: {
            display: 'flex',
            fontSize: '18px',
            fontWeight: 700,
            marginBottom: 15,
            textTransform: 'uppercase'
        },
        btnExportReport: {
            marginTop: -2
        }
    }
};

class W94F1001Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDetailLoaded: false,
            detailChart: null
        }
        this.paramGetReports = {
            MReportID: ""
        }
    }

    getInfo = (flag) => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("MReportID")) {
            return { MReportID: url.get("MReportID") };
        } else if (location && location.state) {
            return {
                MReportID: location.state.MReportID
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W94F1000");
                return null;
            }
            return false;
        }
    };

    componentDidMount = () => {
        if (!this.getInfo(true)) return null;
        const { MReportID } = this.getInfo();
        const { listGroupChart } = this.props;
        if (_.isEmpty(listGroupChart)) { // Không có gọi api
            this.getDataReports(MReportID);
        } else { // Có data chỉ cần filter
            const detailChart = listGroupChart.find(obj => obj.MReportID === MReportID);
            !_.isEmpty(detailChart) ? this.setState({ detailChart }) : Config.popup.show("INFO", Config.lang("Khong_co_du_lieu"))
        }
    };

    getDataReports = (MReportID = "") => {
        const params = {
            MReportID
        }
        this.setState({ reportDetailLoaded: true })
        this.props.W94F1000Actions.getGroupList(params, (err, data) => {
            this.setState({ reportDetailLoaded: false })
            if (err) {
                let message = err.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && !_.isEmpty(data)) {
                this.setState({ detailChart: data });
            }
        });
    }

    onPrint = (detailChart) => {
        if (!detailChart) return;
        //create new window with function print
        this.tempWindow = window.open("", "Print", "width=800,height=930");
        const title = document.createElement('h1');
        if (detailChart.MReportName) {
            title.innerHTML = detailChart.MReportName;
            title.style = "width:100%; height: 40px; display:flex; align-item:center; justify-content: center; font-size: 20; padding: 10px;";
            this.tempWindow.document.body.appendChild(title);
        }
        //copy irame chart powerbi
        const newIframe = this.iframeView.cloneNode(true);
        newIframe.style = "width: calc(21cm - 20px); height: calc(24.5cm - 20px);";
        this.tempWindow.document.body.appendChild(newIframe);
        newIframe.onload = () => {
            //button print
            const btn = document.createElement("button");
            btn.innerHTML = Config.lang("In_bao_cao");
            btn.style = "margin-right: 10px; padding: 10px; position: absolute; right:60px; cursor: pointer;"
            btn.onclick = () => {
                btn.style.opacity = "0";
                btnClose.style.opacity = "0";
                this.tempWindow.focus();
                this.tempWindow.print();
                setTimeout(() => {
                    btn.style.opacity = "1";
                    btnClose.style.opacity = "1";
                }, 200)
            };
            //button close popup
            const btnClose = document.createElement("button");
            btnClose.innerHTML = Config.lang("Dong");
            btnClose.style = "margin-right: 10px; padding: 10px; position: absolute; right:0px; cursor: pointer";
            btnClose.onclose = () => {
                btn.style.opacity = "1";
                btnClose.style.opacity = "1";
            };
            btnClose.onclick = () => {
                this.tempWindow.close();
            };
            //add button to window
            this.tempWindow.document.body.prepend(btn);
            this.tempWindow.document.body.prepend(btnClose);
        }
    };

    onBack = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W94F1000"
        })
    };

    render() {
        const { classes } = this.props;
        const { detailChart, reportDetailLoaded } = this.state;
        if (!this.getInfo(true) || _.isEmpty(detailChart)) return null;
        return (
            <div id={"frmW94F1001"} className={"page-container"} style={{ height: '100%' }}>
                <LoadPanel
                    shadingColor={"rgba(0,0,0,0.4)"}
                    position={{ my: "center", of: "#content" }}
                    visible={reportDetailLoaded}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <div className={classes.reportTitle}>{Config.lang("Danh_sach_bao_cao")}
                    <ButtonCustom className={`mgl5 ${classes.btnExportReport}`}
                        icon={'fas fa-arrow-alt-left'}
                        color={"text-orange"}
                        isAwesome={true}
                        name={Config.lang("CRM_Quay_lai")}
                        onClick={() => this.onBack()} />
                    <ButtonExportReport
                        className={`mgl5 ${classes.btnExportReport}`}
                        name={Config.lang("Xuat_bao_cao")}
                        onClick={() => this.onPrint(detailChart)} />
                </div>
                {detailChart && detailChart.EmbedCode &&
                    <iframe ref={ref => this.iframeView = ref}
                        className={classes.iframe}
                        title={"My chart"}
                        frameBorder={"none"}
                        src={detailChart.EmbedCode}
                        allowFullScreen={true}
                    />
                }
            </div>
        )
    }
}

export default compose(connect((state) => ({
    dataGroupList: state.w94F1000.dataGroupList,
}), (dispatch) => ({
    W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch),
})), withStyles(styles, { withTheme: true }))(W94F1001Page);
