/**
 * @copyright 2020 @ DigiNet
 * @author TRANGHOANG
 * @create 2020/04/16 10:37
 */

import React from 'react';
import {
    Col, Form,
    FormGroup,
    Row,
    Collapse
} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19F2000Actions from "../../actions/w19/w19F2000/w19F2000-actions";
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
import * as w19F2001Actions from "../../actions/w19/w19F2001/w19F2001-actions";
import * as userActions from "../../actions/user-actions";
import {DateBox, CheckBox as CheckBoxExtreme} from "devextreme-react";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import * as templateDropdownActions from "../../actions/template-dropdown-actions";
import moment from "moment";
import 'moment/locale/vi'
import Link from "../common/link/link";
import { Checkbox } from '@material-ui/core';
import Combo from '../common/dropdown/dropdown-new';
import NumberFormat from "react-number-format";
import _ from "lodash";
import ButtonExport from '../common/button/button-export';
import InlineSVG from "react-inlinesvg";
moment.locale('vi');

const FormID = "W19F2000";
class W19F2000Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
            collapse: false,
            voucherNum: '',
            dateFrom: '',
            dateTo: '',
            salesPersonID: '',
            customerID: '',
            groupSalesID: '',
            typeTime: '',
            search: '',
            resetSP: false,
            isDetail: 0,
            inventories: []
        };

        this.filter = {
            skip: 0,
            limit: 50,
        };

        this.isReload = !!props.location.state;
        this.mode = '';
        this.timer = null;
        this.timeDeplay = 3000;
        this.isFilterUpdating = false;
    };

    toggle() {
        const {collapse} = this.state;

        this.setState({
            collapse: !collapse,
            salesPersonID: '',
            customerID: '',
            voucherNum: '',
            time: '',
            groupSalesID: '',
        });
    }

    loadGrid() {
        const uName = Config.profile.UserID;
        const divisionID = Config.profile.DivisionID || '';
        const {voucherNum, groupSalesID, salesPersonID, customerID, dateFrom, dateTo, search, isDetail, inventories} = this.state;
        const {skip, limit} = this.filter;
        const InventoryArr = inventories.map(item => item.value);
        const params = {
            UserID: uName,
            DivisionID: divisionID,
            Language: Config.language || '84',
            StrSearch: search,
            skip: skip,
            limit: limit,
            VoucherNum: voucherNum,
            GroupSalesID: groupSalesID,
            SalesPersonID: salesPersonID,
            CustomerID: customerID,
            DateFrom: dateFrom,
            DateTo: dateTo,
            DataType: "",
            InventoryID: JSON.stringify(InventoryArr),
            IsDetail: isDetail ? 1 : 0
        };

        this.props.w19F2000Actions.loadGrid(params, () => {
            if (this.isFilterUpdating) this.isFilterUpdating = false;
        });
    }

    onAddNew = () => {
        const param = {
            ...this.state
        };

        Config.setStoreState(this, param);
        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F2040',
            state: {
                mode: 'add',
                // formCall: 'W19F2000'
            }
        })
    };

    getNumValue() {
        const param = {
            tranTypeID: 'NotQuantitySalesOrder'
        };

        this.props.w19F2001Actions.getNumValue(param, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = _.get(error, "message", Config.lang("Loi_chua_xac_dinh"));
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }

        })
    };

    onRedirect(state) {

        const param = {
            ...this.state
        };

        Config.setStoreState(this, param);
        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F2040',
            state
        })
    }
    onEdit =(e) =>{
        const dataRow = e.row.data;
        const param = {
            Language: Config.language || '84',
            QuotationID: dataRow.QuotationID,
            DivisionID: dataRow.DivisionID

        };
        this.props.w19F2000Actions.checkEdit(param,(error,data) =>{
            if (error) {

                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (!data) {
                let message =  Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data.Status === 0) {
                this.onRedirect({
                    mode: "edit",
                    QuotationID: param.QuotationID,
                    LinkMaster: dataRow.LinkMaster,
                    //DivisionID: param.DivisionID,
                    // formCall: 'W19F2000'
                })
                return false
            }
            Config.popup.show('INFO', data.Message);
        })
    }
    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch = (text) => {
        this.filter.skip = 0;
        this.setState({
            search: text
        }, () => {
            this.loadGrid();
        });
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D05F1621", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    renderAction(e) {
        let customerPer = 0;
        const {data} = e || {};
        if (e && e.data.AStatusID !== 1) customerPer = this.state.iPermission;

        return (
            <GridActionBar
                isPer={customerPer}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEdit(e)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e)
                }}
                btnHamburger={[
                    {
                        name: Config.lang("CRM_Tien_do_don_hang"),
                        icon: 'fas fa-sliders-h text-orange',
                        // disabled: iPermissionOrder < 2,
                        // className: iPermissionOrder < 2 ? 'isDisabled' : '',
                        onClick: () => {
                            // if (iPermissionOrder >= 2) {
                                browserHistory.push({
                                    pathname: Config.getRootPath() + "W19F1021",
                                    state: {...data, ScreenID: 'W19F2000'}
                                });
                            // }
                        }
                    },
                ]}
            />
        );
    }

    loadFilters() {
        Config.getStoreState(this, () => {
            this.isFilterUpdating = true;
            this.loadGrid();
        });
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.isReload) this.loadData();
        this.loadFilters();
        if (this.state.iPermission <= 0) return;
        this.getNumValue();
        await this.loadCboInventoryAndDecimal(); //Combo
        await this.loadCboInventoryAndDecimal(true); // Decimal
    };

    loadCboInventoryAndDecimal = (isDecimal = false) => {
        const params = {
            UserID: Config.profile.UserID || "",
            Language: Config.language || "84",
            DivisionID: "",
            StrSearch: "",
            skip: 0,
            limit: 1,
            CompanyID: "",
            VoucherNum: "",
            GroupSalesID: "",
            SalesPersonID: "",
            CustomerID: "",
            DateFrom: null,
            DateTo: null,
            IsDetail: 0,
            DataType: isDecimal ? "LoadOriginalDecimal" : "cbmInventoryID"
        };
        this.props.w19F2000Actions.loadCboInventoryAndDecimal(params, (error) => {
            if (error) {
                const message = _.get(error, "message", Config.lang("Loi_chua_xac_dinh"));
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    onDelete(data) {

        const rowData = data.row.data;
        const param = {
            Language: Config.language || '84',
            QuotationID: rowData.QuotationID,
            DivisionID: rowData.DivisionID

        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.w19F2000Actions.deleteGrid(param, (error,data) => {
                if (error) {

                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }

                if (!data) {
                    let message =  Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if ( data.Status === 0) {
                    notify( data.Message || Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
                    this.loadGrid();

                }else{
                    Config.popup.show('INFO', data.Message);
                }




            })
        })

    }

    loadGridTimeout() {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this.loadGrid();
        }, this.timeDeplay / 3);
    }

    loadData() {
        //Function lấy từ combo typetime. Mặc đinh load ban đầu lấy dateform và dateto lấy từ sever trả ra.
        const sLanguage = Config.language || '84';
        const param = {
            "sLanguage": sLanguage,
            "listType": 'W19F1020_TypeTime',
        };

        this.props.templateDropdownActions.cboTypeTime(param, (error, data) => {

            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            let defaultTimeType = 0;
            if (data && data.length > 0) {
                const defaultValue = data.filter((row) => {
                    return row.IsDefault === 1;
                });

                if (defaultValue.length > 0) {
                    defaultTimeType = defaultValue[0].ID;
                }

            }
            this.getDateFromTo(defaultTimeType);
        });
    }

    getDateFromTo(ID) {
        switch (ID) {
            case 'TODAY':
                this.setState({
                    dateFrom: moment().startOf('day').format('YYYY-MM-DD'),
                    dateTo: moment().endOf('day').format('YYYY-MM-DD')
                });
                break;
            case 'YESTERDAY':
                this.setState({
                    dateFrom: moment().add(-1, 'day').startOf('day').format('YYYY-MM-DD'),
                    dateTo: moment().add(-1, 'day').endOf('day').format('YYYY-MM-DD')
                });
                break;
            case 'THISWEEK':
                this.setState({
                    dateFrom: moment().startOf('week').format('YYYY-MM-DD'),
                    dateTo: moment().endOf('week').format('YYYY-MM-DD')
                });
                break;
            case 'LASTWEEK':
                this.setState({
                    dateFrom: moment().add(-1, 'week').startOf('week').format('YYYY-MM-DD'),
                    dateTo: moment().add(-1, 'week').endOf('week').format('YYYY-MM-DD')
                });
                break;
            case 'THISMONTH':
                this.setState({
                    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
                    dateTo: moment().endOf('month').format('YYYY-MM-DD')
                });
                break;
            case 'LASTMONTH':
                this.setState({
                    dateFrom: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
                    dateTo: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
                });
                break;
            case 'THISQUARTER':
                this.setState({
                    dateFrom: moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'),
                    dateTo: moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD')
                });
                break;
            case 'LASTQUARTER':
                this.setState({
                    dateFrom: moment().quarter(moment().quarter() - 1).startOf('quarter').format('YYYY-MM-DD'),
                    dateTo: moment().quarter(moment().quarter() - 1).endOf('quarter').format('YYYY-MM-DD')
                });
                break;
            case 'THISYEAR':
                this.setState({
                    dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
                    dateTo: moment().endOf('year').format('YYYY-MM-DD')
                });
                break;
            case 'LASTYEAR':
                this.setState({
                    dateFrom: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
                    dateTo: moment().add(-1, 'year').endOf('year').format('YYYY-MM-DD')
                });
                break;
            default:
                this.setState({
                    dateFrom: moment().startOf('day').format('YYYY-MM-DD'),
                    dateTo: moment().endOf('day').format('YYYY-MM-DD')
                });
                break;
        }
        this.loadGrid();
    }

    changeTime = (e) => {
        this.filter.skip = 0;
        const {mode, TypeTime, DateTo, DateFrom} = e;
        let param = {
            typeTime: TypeTime,
        };
        if (!this.isFilterUpdating) param = {...param, dateTo: DateTo, dateFrom: DateFrom};
        const {dateFrom, dateTo, collapse} = this.state;

        if (collapse && mode === 'init' && dateFrom && dateTo) return;
        this.setState(param, () => {
            this.loadGrid();
        });

    };

    changeVoucherNum = e => {
        this.filter.skip = 0;
        this.setState({
            voucherNum: e.target.value
        });
        this.loadGridTimeout();
    };

    changeSalesGroup = e => {
        this.filter.skip = 0;
        this.setState({
            groupSalesID: e,
            salesPersonID: '',
            resetSP: true
        });
        this.loadGrid();
    };

    changeCustomer = e => {
        this.filter.skip = 0;
        this.setState({
            customerID: e.target.value
        });
        this.loadGridTimeout();
    };

    changeSalesPerson = e => {
        this.filter.skip = 0;
        this.setState({
            resetSP: false,
            salesPersonID: e ? e.SalesPersonID : null
        });
        this.loadGrid();
    };

    changeDateFrom = e => {
        this.filter.skip = 0;
        this.setState({
            dateFrom: e
        });
        this.loadGrid();
    };

    changeDateTo = e => {
        this.filter.skip = 0;
        this.setState({
            dateTo: e
        });
        this.loadGrid();
    };

    onDetail = (e) => {
        const data = e.row.data;
        if (!e || !data.QuotationID) return null;

        return {
            pathname: Config.getRootPath() + 'W17F2040',
            state: {
                mode: 'view',
                // formCall: 'W19F2000',
                QuotationID:data.QuotationID,
                LinkMaster:data.LinkMaster,
            }
        };
    };

    storeState = () => {
        const param = {
            ...this.state
        };
        Config.setStoreState(this, param);
    };

    renderVoucherNum = (e) => {
        const {data} = e;
        /* eslint-disable */
        return (
            <Link getData={() => this.onDetail(e)}
                  storeState={this.storeState}>
                {data.VoucherNum && data.VoucherNum}
            </Link>
        )
        /* eslint-enable */
    };

    onChangeIsDetail = () => {
        const { isDetail } = this.state;
        this.setState({ isDetail: !isDetail }, () => this.loadGrid());
    };

    handleDeleteInventory = (inventories) => {
        this.setState({ inventories }, () => this.loadGrid());
    };

    handleAddInventory = (inventories) => {
        this.setState({ inventories }, () => this.loadGrid());
    };

    renderNumberFormat = (e) => {
        if (!e) return false;
        let limitDecimal = 0;
        const { column: { dataField }, row: { data } } = e;
        const { decimalScaleColumn } = this.props;
        let decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        switch (dataField) {
            case "CQuantity":
            case "AfterDesCQuantity":
            case "UnitPrice":
            case "AfterDesUnitPrice":
            case "AfterDesCAmount":
                limitDecimal = _.get(decimalScaleColumn.find(item => item.ID === dataField), "OriginalDecimal", 0);
                break;
            default:
                break;
        }
        if (!_.isEmpty(decimalValue) && _.isString(decimalValue)) decimalValue = _.toNumber(decimalValue);
        return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
    };

    headerCellRender = (e) => {
        const { column: { dataField, caption } } = e;
        if (e?.cellElement?.style) {
            e.cellElement.style.position = 'relative';
            e.cellElement.style.paddingRight = '20px';
        }
        const allDataPin = Config.getLocalStorage("PIN_COLUMN", true)?.[Config.profile.UserID];
        const currentDataPin = allDataPin?.[FormID] || [];
        const currentFixed = e.component.columnOption(dataField, "fixed");
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span type={"h5"}>{caption || ""}</span>
                <InlineSVG width={18} height={18}
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0
                    }}
                    onClick={() => {
                        e.component.columnOption(dataField, "allowSorting", false);
                        e.component.columnOption(dataField, "fixed", !currentFixed);
                        if (!currentFixed) {
                            Config.setLocalStorage("PIN_COLUMN", JSON.stringify({ [Config.profile.UserID]: { ...allDataPin, [FormID]: [...currentDataPin, dataField] } }));
                        } else {
                            if (!_.isEmpty(currentDataPin)) {
                                const dataPinFilter = [...new Set(currentDataPin.filter(item => item !== dataField))]
                                Config.setLocalStorage("PIN_COLUMN", JSON.stringify({ [Config.profile.UserID]: { ...allDataPin, [FormID]: dataPinFilter } }));
                            }
                        }
                    }}
                    src={require(currentDataPin.includes(dataField) ? "../../images/svg/Pin.svg" : "../../images/svg/UnPin.svg")} />
            </div>)
    };

    propsColumn = (dataField) => {
        const currentDataPin = Config.getLocalStorage("PIN_COLUMN", true)?.[Config.profile.UserID]?.[FormID] || [];
        const fixed = currentDataPin.includes(dataField);
        return {
            fixed,
            headerCellRender: this.headerCellRender,
            visibleIndex: null,
            fixedPosition: "left",
            minWidth: 120
        };
    };

    render() {
        const {dataGrid, dataCboInventory} = this.props;
        const {skip, limit} = this.filter;
        const {
            typeTime, dateTo, dateFrom, groupSalesID, customerID, salesPersonID,
            iPermission, voucherNum, collapse, search, resetSP, isDetail, inventories
        } = this.state;
        const btnCRM = [
            {
                buttonName: Config.lang("CRM_Xuat_du_lieu"),
                buttonType: 'button',
                icon: 'fas fa-file-excel text-green',
                align: 'right',
                disabled: false,
                onClick: () => {
                    if (this.exportRef.getWrappedInstance().onClick) {
                        this.exportRef.getWrappedInstance().onClick("0")
                    }
                }
            },
            {
                buttonName: Config.lang("CRM_Loc"),
                buttonType: 'button',
                icon: 'fas fa-filter text-yellow',
                align: 'right',
                disabled: false,
                onClick: () => {
                    this.toggle()
                }
            }];
        const InventoryArr = inventories.map(item => item.value);
        const paramExport = [
            { "id": "UserID", "type": "VarChar", "value": Config.profile.UserID },
            { "id": "Language", "type": "VarChar", "value": Config.language || '84' },
            { "id": "DivisionID", "type": "VarChar", "value": Config.profile.DivisionID },
            { "id": "StrSearch", "type": "NVarChar", "value": search },
            { "id": "skip", "type": "Int", "value": skip },
            { "id": "limit", "type": "Int", "value": limit },
            { "id": "CompanyID", "type": "VarChar", "value": customerID },
            { "id": "VoucherNum", "type": "VarChar", "value": voucherNum },
            { "id": "GroupSalesID", "type": "VarChar", "value": groupSalesID },
            { "id": "SalesPersonID", "type": "VarChar", "value": salesPersonID },
            { "id": "CustomerID", "type": "VarChar", "value": Config.profile.SalesPersonID || '' },
            { "id": "DateFrom", "type": "DATETIME", "value": dateFrom },
            { "id": "DateTo", "type": "DATETIME", "value": dateTo },
            { "id": "IsDetail", "type": "TINYINT", "value": isDetail ? 1 : 0 },
            { "id": "DataType", "type": "VarChar", "value": 'REPORT' },
            { "id": "InventoryID", "type": "Array", "value": InventoryArr },
            { "id": "ReportTypeID", "type": "VarChar", "value": 'W19F2000' },
        ];
        return (
            <div className="page-container">
                <ButtonExport
                    className="hide"
                    ref={ref => this.exportRef = ref}
                    reportTypeID={"W19F2000"}
                    id={'slave1'}
                    onlyShowPopup
                    param={paramExport}
                />
                <div className="grid-title">{Config.lang("CRM_Danh_sach_don_hang")}</div>
                <EventTracking action={"FORM"} label={"W19F2000"}/>
                <Form horizontal={true}>
                    <Collapse in={collapse}>
                        <div>
                            <FilterCondition resetSP={resetSP}

                                             voucherNum={voucherNum}
                                             onChangeVoucherNum={this.changeVoucherNum}

                                             salesGroup={groupSalesID}
                                             onChangeSalesGroup={this.changeSalesGroup}

                                             customer={customerID}
                                             onChangeCustomer={this.changeCustomer}

                                             salesPersonID={salesPersonID}
                                             onChangeSalesPerson={this.changeSalesPerson}

                                             typeTime={typeTime}
                                             onChangeTime={this.changeTime}

                                             dateFrom={dateFrom}
                                             onChangeDateFrom={this.changeDateFrom}

                                             dateTo={dateTo}
                                             onChangeDateTo={this.changeDateTo}

                                             isDetail={isDetail}
                                             inventories={inventories}
                                             dataCboInventory={dataCboInventory}
                                             onChangeIsDetail={this.onChangeIsDetail}
                                             handleDeleteInventory={this.handleDeleteInventory}
                                             handleAddInventory={this.handleAddInventory}
                            />
                        </div>
                    </Collapse>
                    <FormGroup style={{marginBottom: 0}}>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <GridContainer
                                dataSource={dataGrid}
                                totalItems={dataGrid && dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0}
                                itemPerPage={limit}
                                skipPerPage={skip}
                                onAddNew={this.onAddNew}
                                isPer={iPermission}
                                buttonCRM={btnCRM}
                                height={Config.getHeightGrid()}
                                listPerPage={[10, 20, 30, 50]}
                                valueSearch={search}
                                onSearch={this.onSearch}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}  
                                gridProps={{
                                    onCellPrepared: (e) => {
                                        if (e?.rowType === "header" && e?.cellElement) {
                                            e.cellElement.style.position = 'relative';
                                        }
                                    }
                                }}
                            >

                                <Column
                                    cellRender={(data) => this.renderAction(data)}
                                    caption={Config.lang('CRM_Hanh_dong')}
                                    alignment={'center'}
                                    allowSorting={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    width={120}
                                />
                                <Column
                                    {...this.propsColumn("DivisionID")}
                                    dataField={'DivisionID'}
                                    caption={Config.lang("CRM_Don_vi_ban")}
                                    allowSorting={true}
                                />
                                <Column
                                    {...this.propsColumn("VoucherNum")}
                                    dataField={'VoucherNum'}
                                    caption={Config.lang("CRM_So_don_hang")}
                                    cellRender={(e) => this.renderVoucherNum(e)}
                                    allowSorting={true}
                                />
                                <Column
                                    {...this.propsColumn("QuotationDate")}
                                    dataField={'QuotationDate'}
                                    alignment={'center'}
                                    dataType={'date'}
                                    format={'dd/MM/yyyy'}

                                    caption={Config.lang("CRM_Ngay_lap")}
                                />
                                <Column
                                    {...this.propsColumn("CreateDate")}
                                    dataField={'CreateDate'}
                                    alignment={'center'}
                                    dataType={'date'}
                                    format={'dd/MM/yyyy HH:mm:ss'}
                                    caption={Config.lang("Ngay_tao")}
                                />
                                <Column
                                    {...this.propsColumn("SalesPersonName")}
                                    dataField={'SalesPersonName'}
                                    caption={Config.lang("CRM_Nguoi_lap")}

                                />
                                <Column
                                    {...this.propsColumn("ObjectID")}
                                    dataField={'ObjectID'}
                                    caption={Config.lang("CRM_Ma_khach_hang")}
                                />
                                <Column
                                    {...this.propsColumn("CustomerNameU")}
                                    dataField={'CustomerNameU'}
                                    caption={Config.lang("CRM_Khach_hang")}
                                />
                                <Column
                                    {...this.propsColumn("ValidFrom")}
                                    dataField={'ValidFrom'}
                                    alignment={'center'}
                                    dataType={'date'}
                                    format={'dd/MM/yyyy'}
                                    caption={Config.lang("CRM_Ngay_hieu_luc")}
                                    width={150}
                                />
                                <Column
                                    {...this.propsColumn("ValidTo")}
                                    dataField={'ValidTo'}
                                    alignment={'center'}
                                    dataType={'date'}
                                    format={'dd/MM/yyyy'}
                                    width={150}
                                    caption={Config.lang("CRM_Ngay_het_hieu_luc")}
                                />
                                <Column
                                    {...this.propsColumn("SalesPersonNameU")}
                                    dataField={'SalesPersonNameU'}
                                    caption={Config.lang("CRM_NVKD")}
                                />
                                <Column
                                    {...this.propsColumn("DescriptionU")}
                                    dataField={'DescriptionU'}
                                    caption={Config.lang("CRM_Ghi_chu")}
                                    alignment={'left'}

                                />
                                <Column
                                    {...this.propsColumn("AStatus")}
                                    dataField={'AStatus'}
                                    caption={Config.lang("CRM_Trang_thai")}
                                />
                                <Column
                                    {...this.propsColumn("StatusVoucher")}
                                    dataField={'StatusVoucher'}
                                    caption={Config.lang("CRM_Trang_thai_thuc_hien")}
                                />
                                <Column
                                    {...this.propsColumn("IsInvenortyID")}
                                alignment={'center'}

                                    dataField={'IsInvenortyID'}
                                    caption={Config.lang("CRM_Da_co_mat_hang")}
                                    cellRender ={e =>   <Checkbox
                                                            color="primary"
                                                            inputProps={{readOnly:true}}
                                                            checked={e.data && !!e.data.IsInvenortyID}
                                                        />}
                                />
                                <Column
                                    {...this.propsColumn("QuotationNum")}
                                    dataField={'QuotationNum'}
                                    caption={Config.lang("CRM_So_bao_gia")}
                                />
                                <Column
                                    {...this.propsColumn("InventoryID")}
                                    width={180}
                                    visible={isDetail}
                                    dataField={"InventoryID"}
                                    caption={Config.lang("Ma_hang")}
                                />
                                <Column
                                    {...this.propsColumn("InventoryName")}
                                    width={270}
                                    visible={isDetail}
                                    dataField={"InventoryName"}
                                    caption={Config.lang("Ten_hang")}
                                />
                                <Column
                                    {...this.propsColumn("ShortName")}
                                    width={200}
                                    visible={isDetail}
                                    dataField={"ShortName"}
                                    caption={Config.lang("Ten_tat")}
                                />
                                <Column
                                    {...this.propsColumn("UnitID")}
                                    width={90}
                                    visible={isDetail}
                                    dataField={"UnitID"}
                                    caption={Config.lang("DVT")}
                                />
                                <Column
                                    {...this.propsColumn("CQuantity")}
                                    width={150}
                                    visible={isDetail}
                                    dataType={"number"}
                                    alignment={"right"}
                                    dataField={"CQuantity"}
                                    caption={Config.lang("So_luong_QD")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    {...this.propsColumn("AfterDesCQuantity")}
                                    width={150}
                                    visible={isDetail}
                                    dataType={"number"}
                                    alignment={"right"}
                                    dataField={"AfterDesCQuantity"}
                                    caption={Config.lang("So_luong_sau_DC")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    {...this.propsColumn("UnitPrice")}
                                    width={150}
                                    visible={isDetail}
                                    dataType={"number"}
                                    alignment={"right"}
                                    dataField={"UnitPrice"}
                                    caption={Config.lang("Don_gia")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    {...this.propsColumn("AfterDesUnitPrice")}
                                    width={150}
                                    visible={isDetail}
                                    dataType={"number"}
                                    alignment={"right"}
                                    dataField={"AfterDesUnitPrice"}
                                    caption={Config.lang("Don_gia_sau_DC")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    {...this.propsColumn("AfterDesCAmount")}
                                    width={150}
                                    visible={isDetail}
                                    dataField={"AfterDesCAmount"}
                                    dataType={"number"}
                                    alignment={"right"}
                                    caption={Config.lang("Thanh_tien_sau_DC")}
                                    cellRender={this.renderNumberFormat}
                                />
                            </GridContainer>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

export class FilterCondition extends React.PureComponent {

    onChangeTime(data, mode) {
        if (!data) return false;
        const {DateFrom, DateTo, ID} = data;
        const time = {
            mode: mode,
            TypeTime: ID,
            DateFrom: DateFrom ? moment(DateFrom).format('YYYY-MM-DD') : null,
            DateTo: DateTo ? moment(DateTo).format('YYYY-MM-DD') : null,
        };

        this.props.onChangeTime && this.props.onChangeTime(time);
    }


    render() {
        const {
            voucherNum, onChangeVoucherNum, onChangeCustomer, onChangeSalesPerson,
            onChangeSalesGroup, salesPersonID, onChangeDateTo, typeTime, salesGroup,
            dateFrom, dateTo, onChangeDateFrom, customer, resetSP, dataCboInventory,
            isDetail, inventories, handleAddInventory, handleDeleteInventory, onChangeIsDetail
        } = this.props;

        return (
            <React.Fragment>
                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={'control-label'}>{Config.lang('CRM_So_don_hang')}</label></Col>
                            <Col lg={8} md={8}>
                                <input
                                    value={voucherNum}
                                    onChange={(e) => {
                                        onChangeVoucherNum && onChangeVoucherNum(e)
                                    }}
                                    className={'form-control'}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={'control-label'}>{Config.lang('CRM_Khach_hang')}</label></Col>
                            <Col lg={8} md={8}>
                                <input
                                    value={customer}
                                    onChange={(e) => {
                                        onChangeCustomer && onChangeCustomer(e)
                                    }}
                                    className={'form-control'}/>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Nhom_kinh_doanh")}</label>
                            </Col>
                            <Col lg={8} md={8}>
                                <DropDownSaleGroup showClearButton={true}
                                                   placeholder={Config.lang('CRM_Nhom_kinh_doanh')}
                                                   value={salesGroup}
                                                   onChange={(e) => {
                                                       onChangeSalesGroup && onChangeSalesGroup(e);
                                                   }}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                            </Col>
                            <Col lg={8} md={8}>
                                <DropDownSalePerson showClearButton={true}
                                                    placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                                    value={salesPersonID}
                                                    reset={resetSP}
                                                    groupSalesID={salesGroup}
                                                    onChange={(e) => {
                                                        onChangeSalesPerson && onChangeSalesPerson(e);
                                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={'control-label'}>{Config.lang('CRM_Thoi_gian')}</label>
                            </Col>
                            <Col lg={8} md={8}>
                                <DropDownTypeTime
                                    listType={"W19F1020_TypeTime"}
                                    value={typeTime}
                                    placeholder={""}
                                    onChange={(data, mode) => this.onChangeTime(data, mode)}
                                >
                                </DropDownTypeTime>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={6} md={6}>
                                <DateBox
                                    dateSerializationFormat={'yyyy-MM-dd'}
                                    width={"100%"}
                                    placeholder={Config.lang("CRM_Tu_ngay")}
                                    id={'CreateDateFrom'}
                                    openOnFieldClick={true}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    value={dateFrom}
                                    type={'date'}
                                    pickerType={"calendar"}
                                    showAnalogClock={false}
                                    displayFormat={'dd/MM/y'}
                                    onValueChanged={(e) => {
                                        onChangeDateFrom && onChangeDateFrom(e.value);
                                    }}
                                />
                            </Col>
                            <Col lg={6} md={6}>
                                <DateBox
                                    dateSerializationFormat={'yyyy-MM-dd'}
                                    width={"100%"}
                                    placeholder={Config.lang("CRM_Den_ngay")}
                                    id={'CreateDateTo'}
                                    openOnFieldClick={true}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    value={dateTo}
                                    type={'date'}
                                    pickerType={"calendar"}
                                    showAnalogClock={false}
                                    displayFormat={'dd/MM/y'}
                                    onValueChanged={(e) => {
                                        onChangeDateTo && onChangeDateTo(e.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={6} md={6}>
                        <Row>
                            <Col lg={4} md={4}>
                                <label className={"control-label"}>{Config.lang("Mat_hang")}</label>
                            </Col>
                            <Col lg={8} md={8}>
                                <Combo
                                    defaultValue={inventories}
                                    placeholder={Config.lang("Mat_hang")}
                                    dataSource={dataCboInventory}
                                    handleDelete={(e) => { handleDeleteInventory && handleDeleteInventory(e) }}
                                    onActionWhenSelectChange={(e) => { handleAddInventory && handleAddInventory(e) }}
                                    displayExpr={"customNameID"}
                                    valueExpr={"value"}
                                    isMulti={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={3}>
                        <CheckBoxExtreme
                            text={Config.lang("Hien_thi_chi_tiet")}
                            lang={Config.lang("Hien_thi_chi_tiet")}
                            value={!!isDetail}
                            onValueChanged={() => {
                                onChangeIsDetail && onChangeIsDetail();
                            }}
                        />
                    </Col>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default connect(state => ({
        dataGrid: state.w19f2000.dataGrid,
        numValue: state.w19f2001.numValue,
        decimalScaleColumn: state.w19f2000.dataGrid,
        dataCboInventory: state.w19f2000.dataCboInventory,
    }),
    (dispatch) => ({
        w19F2000Actions: bindActionCreators(w19F2000Actions, dispatch),
        w19F2001Actions: bindActionCreators(w19F2001Actions, dispatch),
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F2000Page);
