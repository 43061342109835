/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/04/16 10:30
 * @update 2019/04/16 10:30
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19f1010Actions from "../../actions/w19f1010-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config';
import {Column, Editing,} from 'devextreme-react/data-grid';
import {
    Row,
    Form,
    FormGroup,
    Col,
} from 'react-bootstrap';
import Combo from "../common/dropdown/combo";
import EventTracking from "../common/tracking/event";
import ComboTree from "../common/dropdown/dropdown-treeview.js";
import ButtonSave from "../common/button/button-save";
import {
    Label
} from "reactstrap";
import {
    CheckBox,
} from 'devextreme-react';
import {TreeList} from "devextreme-react/tree-list";
import notify from "devextreme/ui/notify";
import ButtonCustom from "../common/button/button-custom";
import {browserHistory} from "react-router";

import moment from "moment";
import 'moment/locale/vi'
moment.locale('vi');

class W19F1010Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            dataGroupSales: [],
            yearID:''
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: ''
        };
        this.dataGroupSales = [];
        this.defaultRowKeys = [];
        this.dataYear = [];
        // this.yearID = '';
        this.salesPersonID = '';
        this.groupSalesParentID = '';
        this.dataUpdate = [];

        this.showAllTree = false;// 1 show all 0 show not disabled
        this.mode = '';
        this.rowIndx = 0;
        this.rowData = ''

    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.comboGroupSales();
        this.comboYear();
        this.loadGrid();
    };

    comboYear() {
        const param = {
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "search": this.filter.search,
            "where": ''
        };
        this.props.w19f1010Actions.comboYearW19F1010(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else {
                if (data.data.rows.length > 0) {
                    this.dataYear = data.data.rows.map(o => {
                        const years = {
                            TranYear: o.TranYear,
                        };
                        return years;
                    });
                    const defaultYear = moment().year();
                    if (defaultYear)
                        // this.yearID = defaultYear;
                    this.setState({
                        yearID:defaultYear
                    });
                    this.loadGrid();
                } else {
                    return false;
                }
            }
        });
    }

    comboGroupSales() {
        const uName = Config.profile.UserID || '';
        const groupParam = {
            "UserID": uName,
            "Mode": 2,
            // storeName: 'W17P9000',
            // storeParam: JSON.stringify([
            //     {"name": "UserID", "type": "VarChar", "value": uName},
            //     {"name": "Mode", "type": "VarChar", "value": 2},
            // ])
        };
        this.props.w19f1010Actions.comboGroupSalesW19F1010(groupParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.data && data.data.length > 0) {
                this.setState({
                    dataGroupSales: data.data
                });
                const defaultValue = data.data.filter((row) => {
                    return row.GroupSalesParentID === '0';
                });
                if (defaultValue.length > 0) {
                    this.salesPersonID = defaultValue[0].GroupSalesID;
                    this.groupSalesParentID = defaultValue[0].GroupSalesParentID;
                    this.loadGrid();
                }
            } else {
                return false;
            }

        });
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1620",(iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    loadGrid() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            "SalesGroupID": this.salesPersonID,
            "Year": this.state.yearID,
            "skip": this.filter.limit,
            "limit": this.filter.limit,
        };
        this.props.w19f1010Actions.loadW19F1010(mnParam, (error, data) => {
            let dataF = [];
            if (data && data.data && data.data.length > 0) {
                if (this.showAllTree === false) {
                    dataF = data.data.filter((value) => {
                        return value.Disabled === 0;
                    });
                } else {
                    dataF = data.data;
                }
                this.setState({
                    treeData: dataF,
                });
                const defaultValue = data.data.filter((row) => {
                    return row.CodeParentID === '0';
                });
                if (defaultValue.length > 0) {
                    this.defaultRowKeys.push(defaultValue[0].CodeID);
                }
            }
        });
    }

    hideAddNewPopup() {
        this.setState({showPopup: false});
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.search = text;
        // this.loadGrid();
    }

    onChangeComboYear(data) {
        // this.yearID = data.TranYear;
        this.setState({
            yearID: data.TranYear
        });
        this.loadGrid();
    }

    onChangeComboGroupSales(data) {
        this.salesPersonID = data;
        this.loadGrid();
        const comboSelectedRowKeys = [...this.state.dataGroupSales];
        const parentID = this.salesPersonID;
        comboSelectedRowKeys.forEach((value) => {
            if (value.GroupSalesID === parentID) {
                this.groupSalesParentID = value.GroupSalesParentID;
            }
        });

    }

    updateGrid(e) {
        const element = e.editorElement;
        element.onchange = (arg) => {
            arg.target.value.replace(/[^0-9]/gi, 0);
            let format = /^[0-9]+$/;
            if (!format.test(arg.target.value)) {
                e.component.cellValue(e.row.rowIndex, e.dataField, 0);
                e.component.saveEditData();
                return false;
            }

            let data = e.component.option('dataSource');
            let rowData = {};
            rowData = e.row.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].CodeID === rowData.CodeID) {
                    data[i][e.dataField] = arg.target.value
                }
            }
            let sum = 0;
            let sum2 = 0;
            data.forEach((value) => {
                if ((value.CodeParentID === rowData.CodeParentID)) {
                    sum += parseInt(value[e.dataField])
                }
            });
            data.forEach((value) => {
                if ((value.CodeParentID === rowData.CodeID)) {
                    sum2 += parseInt(value[e.dataField])
                }
            });
            const found = data.find(function (element) {
                return element.CodeID === rowData.CodeParentID;
            });
            const found2 = data.find(function (element) {
                return element.CodeID === rowData.CodeID;
            });
            if (found && found[[e.dataField]] < sum) {
                Config.popup.show('INFO', `${Config.lang("CRM_Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom")}`, null, null, () => {
                    setTimeout(() => {
                        e.component.cellValue(e.row.rowIndex, e.dataField, 0);
                        e.component.saveEditData();
                        setTimeout(() => {
                            e.component.editCell(e.row.rowIndex, e.dataField);
                        }, 200)
                    }, 300);
                });
            }
            if (found2 && found2[[e.dataField]] < sum2) {
                Config.popup.show('INFO', `${Config.lang("CRM_Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet")}`, null, null, () => {
                    setTimeout(() => {
                        e.component.cellValue(e.row.rowIndex, e.dataField, 0);
                        e.component.saveEditData();
                        setTimeout(() => {
                            e.component.editCell(e.row.rowIndex, e.dataField);
                        }, 200)
                    }, 300);
                });
            }
        };
    }

    setStatusDisableValue(data, parentID, status) {
        data.forEach((value) => {
            if ((value.CodeParentID === parentID)) {
                value.Disabled = status;
                value.IsUpdateRow = 1;
                this.setStatusDisableValue(data, value.CodeID, status);
            }
        });
        return data;
    }

    rowUpdate(e) {
        this.dataUpdate = e.component.option('dataSource');
        let dataRowUpdate = this.state.treeData.filter(row => {
            return row.CodeID === e.data.CodeID && row.CodeType === e.data.CodeType && row.Name === e.data.Name;
        });
        if (dataRowUpdate) {
            dataRowUpdate[0].IsUpdateRow = 1;
        }
        let listMonth = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
        let rowData = e.data;

        let sumHorizontal = 0;
        for (let j = 0; j < listMonth.length; j++) {
            sumHorizontal += rowData[listMonth[j]];
        }
        rowData['YearTotal'] = sumHorizontal;
        e.data = rowData;
        e.component.refresh();
    }

    cellYear(e) {
        let listMonth = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
        let sumHorizontal = 0;
        for (let j = 0; j < listMonth.length; j++) {
            sumHorizontal += e.data[listMonth[j]];
        }
        return sumHorizontal.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    }


    onEventSave() {
        const gridSelectedRowKeys = [...this.state.treeData];
        let grid = [];
        gridSelectedRowKeys.forEach((value) => {
            if (value.IsUpdateRow === 1) {
                if (value.Disabled) {
                    value.Disabled = 1;
                } else {
                    value.Disabled = 0;
                }
                value.Year = this.state.yearID;
                grid.push(value);
            }
        });
        if (grid.length <= 0) {
            Config.popup.show('INFO', `${Config.lang("CRM_Ban_chua_nhap_chi_tieu_doanh_so")}`);
            return false;
        }
        grid.forEach((value) => {
            if ((value.CodeParentID === '0')) {
                value.CodeParentID = this.groupSalesParentID;
            }
        });
        const paramValue = {
            param: JSON.stringify(grid)
        };
        this.props.w19f1010Actions.saveW19F1010(paramValue, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({isSubmit: false});
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.code === 200) {
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                this.loadGrid();
            }
        });
    }

    onHistorical() {
        const labelFrom = Config.lang("CRM_Chi_tieu_doanh_so");
        const auditCode = 'W19F1010_Sales';
        const valueIDParam = ' ';
        const valueNameParam = ' ';
        browserHistory.push({
            pathname: Config.getRootPath() + 'W17F0003',
            state: {LabelParam: labelFrom, AuditCode: auditCode, ValueIDParam: valueIDParam, ValueNameParam:valueNameParam, FormID: 'W19F1010'}
        });
    }

    render() {
        const disableYear = moment().year();
        const {comboGroupSalesW19F1010} = this.props;

        return (
            <div className="page-container" id={'frmW19F1010'}>
                <EventTracking action={"FORM"} label={"W19F1010"}/>

                <div className="grid-title">{Config.lang('CRM_Chi_tieu_doanh_so')}</div>
                <div className="pdb10">
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={4} lg={12}>
                            <ButtonSave onClick={() => this.onEventSave()} className={"pull-left pdt5 mg25"}
                                        name={Config.lang("CRM_Luu")}/>
                            <ButtonCustom icon={'far fa-newspaper'} isAwesome={true}
                                          id={'btnNextW18F1011'}
                                          className={'pull-right'}
                                          onClick={() => this.onHistorical()}
                                          color={'text-purple'} name={Config.lang("CRM_Lich_su_chinh_sua")}
                            />
                        </Col>
                    </FormGroup>
                </div>
                <Form horizontal={true} style={{overflowX: 'hidden'}}>
                    <FormGroup>
                        <Col sm={4}>
                            <Row>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('CRM_Co_cau_to_chuc')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    {comboGroupSalesW19F1010 && <ComboTree
                                        dataSource={comboGroupSalesW19F1010.data}
                                        //reset={this.resetComboGroupSales}
                                        placeholder={Config.lang('CRM_Co_cau_to_chuc')}
                                        parentIdExpr={'GroupSalesParentID'}
                                        value={this.salesPersonID}
                                        valueExpr={'GroupSalesID'}
                                        displayExpr={'GroupSalesNameU'}
                                        isMultiple={false}
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboGroupSales(data)
                                        }}
                                    />}
                                </Col>
                            </Row>

                        </Col>

                        <Col sm={4} md={4} lg={4}>
                            <Row>
                                <Col sm={2} md={2} lg={2}>
                                    <Label className={'mgt5'}>{Config.lang('CRM_Nam_thang')}</Label>

                                </Col>
                                <Col sm={5} md={4} lg={4}>
                                    {this.dataYear && <Combo
                                        showHeader={false}
                                        dataSource={this.dataYear}
                                        placeholder={Config.lang("CRM_Nam_thang")}
                                        value={this.state.yearID}
                                        valueExpr="TranYear"
                                        displayExpr="TranYear"
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeComboYear(data)
                                        }}
                                    >
                                        <Column dataField={'TranYear'} caption="Mã" dataType={'string'}/>
                                    </Combo>}
                                </Col>
                                <Col sm={2}>
                                    <CheckBox
                                        className={'mgt5'}
                                        width={200}
                                        text={Config.lang("CRM_Hien_thi_chi_tieu_da_huy")}
                                        id={"Disabled"}
                                        onValueChanged={(e) => {
                                            this.rowIndx = 0;
                                            this.showAllTree = e.value;
                                            this.loadGrid()
                                        }}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col sm={12}>
                            <TreeList
                                id={'treeListW19F1010'}
                                dataSource={this.state.treeData || []}
                                columnAutoWidth={true}
                                allowColumnResizing={true}
                                columnResizingMode={"widget"}
                                showBorders={true}
                                onEditorPreparing={(e) => {
                                    if (e.dataField === "Disabled") return false;
                                    e.editorOptions.onFocusIn = args => {
                                        const input = args.element.querySelector('.dx-texteditor-input');
                                        input.select();
                                    };
                                }}
                                keyExpr={'CodeID'}
                                // height={'550px'}
                                disabled={this.state.yearID < disableYear}
                                parentIdExpr={'CodeParentID'}
                                autoExpandAll={true}
                                defaultExpandedRowKeys={this.defaultRowKeys}
                                onCellClick={(e) => {
                                    if (e.column.dataField === 'Disabled') {
                                        let data = e.component.option('dataSource');
                                        data = this.setStatusDisableValue(data, e.data.CodeID, e.data.Disabled);
                                        this.setState({
                                            treeData: data,
                                            disabled: data.Disabled,
                                        });
                                    }
                                }}
                                onRowUpdated={(e) => {
                                    this.rowUpdate(e);
                                }}
                                onEditorPrepared={(e) => {
                                    this.updateGrid(e);
                                }}
                            >
                                <Editing allowUpdating={true} mode={'cell'}/>
                                <Column dataField={'CodeParentID'} caption={Config.lang("CRM_Ma")} visible={false}
                                        dataType={'string'} fixed={true}>
                                </Column>
                                <Column dataField={'CodeID'} caption={Config.lang("CRM_Ma")} visible={false} width={240}
                                        dataType={'string'} fixed={true}/>
                                <Column dataField={'Name'} allowEditing={false} caption={Config.lang("CRM_Ten")}
                                        minWidth={200} fixed={true}/>
                                <Column dataField={'CodeType'} caption={Config.lang("Loai")} visible={false}
                                        width={"100%"}/>
                                <Column dataField={'JANUARY'} caption={Config.lang("CRM_Thang_1")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'FEBRUARY'} caption={Config.lang("CRM_Thang_2")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'MARCH'} caption={Config.lang("CRM_Thang_3")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'APRIL'} caption={Config.lang("CRM_Thang_4")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'MAY'} caption={Config.lang("CRM_Thang_5")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'JUNE'} caption={Config.lang("CRM_Thang_6")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'JULY'} caption={Config.lang("CRM_Thang_7")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'AUGUST'} caption={Config.lang("CRM_Thang_8")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'SEPTEMBER'} caption={Config.lang("CRM_Thang_9")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'OCTOBER'} caption={Config.lang("CRM_Thang_10")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'NOVEMBER'} caption={Config.lang("CRM_Thang_11")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'DECEMBER'} caption={Config.lang("CRM_Thang_12")} minWidth={80}
                                        format={'#,##0'}/>
                                <Column dataField={'YearTotal'} caption={Config.lang("CRM_Ca_nam")} minWidth={80}
                                        alignment={'right'}
                                        cellRender={(e) => {
                                            return this.cellYear(e)
                                        }}
                                        format={'#,##0'} allowEditing={false}/>
                                <Column dataField={'Year'} caption={Config.lang("CRM_Nam_thang")} visible={false}
                                        minWidth={80} allowEditing={false}/>
                                <Column dataField={'Disabled'} caption={Config.lang("CRM_Huy")} minWidth={50}
                                        dataType={'boolean'}
                                        filterType={!(!this.state.Disable || this.state.Disable === 0)}/>
                            </TreeList>

                        </Col>
                    </FormGroup>
                </Form>

            </div>
        )
    }
}

export default connect(state => ({
        loadW19F1010: state.w19f1010.loadW19F1010,
        comboGroupSalesW19F1010: state.w19f1010.comboGroupSalesW19F1010,
        comboYearW19F1010: state.w19f1010.comboYearW19F1010,
        saveW19F1010: state.w19f1010.saveW19F1010,
    }),
    (dispatch) => ({
        w19f1010Actions: bindActionCreators(w19f1010Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F1010Page);