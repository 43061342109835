/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */
import React, {memo, PureComponent} from 'react';
import {connect} from "react-redux";
import PageContainer from '../common/page-container/page-container'
import {Alert, Col, Form, FormGroup} from "react-bootstrap";
import ButtonBack from "../common/button/button-back";
import ButtonSave from "../common/button/button-save";
import {CheckBox, DateBox, Validator} from "devextreme-react";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import * as action from '../../actions/w20/w20F1003/w20f1003-actions';
import MultipleSwitch from '../common/button/button-switch'
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownCustomer from "../common/dropdown/dropdown-customer";
import DropDownCustomerLevel from "../common/dropdown/template/dropdown-customer-level";
import DropDownCompanyStatus from "../common/dropdown/template/dropdown-status";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import moment from "moment";
import Combo from "../common/dropdown/combo";
import {Column} from "devextreme-react/data-grid";
import {browserHistory} from "react-router";
import {CustomRule} from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import PropTypes from "prop-types";
import ButtonBasic from "../common/button/button-basic";
import {bindActionCreators} from "redux";
import * as userActions from "../../actions/user-actions";

const style = {
    alert: {
        padding: '5px'
    },
    lineDivision: {
        borderRight: '1px solid #9d8f8f'
    },
    searchButton: {
        width: '100%',
        background: '#4294fb',
        color: 'white',
        fontWeight: '700'
    },
    buttonSearchContainer: {
        textAlign: 'center'
    }
};

export class index extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCustomer: false,
            salesGroupID: '',
            dateFrom: '',
            dateTo: '',
            dataGrid: [],
            salesPersonID: '',
            iPermission: 0
        };
        this.isValidDate = true;
        this.init = false;
        this.skip = 0;
        this.limit = 15;
        this.mode = 0;
        this.selectedData = [];
        if (!props.location.state) browserHistory.push(Config.env.url + '/W20F1000');
        this.dataSourceGender = [{
            ID: 'M',
            Name: Config.lang('CRM_Nam1')
        }, {
            ID: 'F',
            Name: Config.lang('CRM_Nu')
        }
        ];

        this.customer = {
            customerID: '',
            customerLevelID: '',
            companyStatusID: '',
            isPartner: 0,
            isCompetitor: 0,
            isCustomer: 0
        };

        this.contact = {
            fullName: '',
            mobileNo: '',
            email: '',
            jobPosition: '',
            homeAddress: '',
            gender: '',
            isAdmin: 0,
        }

    }

    onStateChange = (mode, e) => {
        this.setState({
            [mode]: mode === 'salesPersonID' ? e ? e.SalesPersonID : '' : e
        })
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1923", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    onSelectionChanged = (currentPageData, e) => this.selectedData = e;

    onChangeTime = (data) => {
        this.setState({
            dateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            dateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        })
    };

    onChangeDate = (e) => {
        const name = e.component.option('name');
        this.setState({
            [name]: e.value
        })
    };

    componentDidMount = async () => {
        await this.loadPermission();
        this.getDataGrid();
    };

    // một vài combo trả ra object thay vì mã id
    onChangeDropDown = (mode, extension, e) => this[this.state.isCustomer ? 'customer' : 'contact'][mode] = extension ? e ? e[extension] : '' : e;

    onChangeInput = (e) => this.contact[e.target.name] = e.target.value;

    onChangeCheckbox = (e) => this[this.state.isCustomer ? 'customer' : 'contact'][e.component.option('name')] = e.value ? 1 : 0;

    onSearch = () => {
        this.skip = 0;
        this.mode = 1;
        this.getDataGrid();
    };

    getDataGrid = () => {
        if (!this.isValidDate) {
            Config.popup.show('INFO', Config.lang('CRM_Dieu_kien_loc_khong_hop_le'));
            this.setState({
                isCustomer: false
            });
            return false
        }

        const param = {
            ListCustomerID: this.props.location.state.id,
            Skip: this.skip,
            Limit: this.limit,
            IsCustomer: this.customer.isCustomer,
            IsPartner: this.customer.isPartner,
            IsCompetitor: this.customer.isCompetitor,
            CompanyStatus: this.customer.companyStatusID,
            CompanyKindName: this.customer.customerLevelID,
            GroupSalesID: this.state.salesGroupID,
            CompanyID: this.customer.customerID,
            SalesPersonID: this.state.salesPersonID,
            FullNameU: this.contact.fullName,
            MobileNo: this.contact.mobileNo,
            Email2: this.contact.email,
            JobPositionU: this.contact.jobPosition,
            HomeAddressU: this.contact.homeAddress,
            IsAdmin: this.contact.isAdmin,
            Gender: this.contact.gender,
            BirthDateFrom: this.state.dateFrom,
            BirthDateTo: this.state.dateTo,
            Mode: this.mode
        };

        action.getDataGrid(param, (error, data) => {
            if (error) return;
            this.setState({
                dataGrid: data
            })
        })
    };

    onChangePage = (page) => {
        this.skip = page * this.limit;
        this.getDataGrid();
    };

    onChangePerPage = (per) => {
        this.skip = 0;
        this.limit = per;
        this.getDataGrid();
    };

    onGoBack = () => browserHistory.goBack();

    onSave = () => {
        if (!this.selectedData.length) {
            Config.popup.show('INFO', Config.lang('CRM_Ban_chua_chon_lien_he'));
            return;
        }
        const data = this.selectedData.map(value => (
            {
                ContactID: value.ContactID,
                Email: value.Email,
                Email2: value.Email2,
                OfficeTelNo: value.OfficeTelNo,
                MobileNo: value.MobileNo,
                ListCustomerID: this.props.location.state.id
            }
        ));
        action.saveData({data: JSON.stringify(data)}, (error) => {
            if (error) {
                Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                return false
            }
            this.mode = 1;
            this.skip = 0;
            this.getDataGrid();
            this.gridContainer.clearSelectRow();
            this.selectedData = [];
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
        })
    };

    validateDate = (compareValue, mode, e) => {
        e.rule.message = '';
        this.isValidDate = true;
        if (mode === 1) {
            e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
            this.isValidDate = e.value <= compareValue;
            return e.value <= compareValue;
        } else {
            e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
            this.isValidDate = e.value >= compareValue;
            return e.value >= compareValue;
        }
    };

    render() {
        const {isCustomer, salesGroupID, dateFrom, dateTo, dataGrid, salesPersonID, iPermission} = this.state;

        return (
            <Ui isCustomer={isCustomer}
                skip={this.skip}
                limit={this.limit}
                dataSourceGender={this.dataSourceGender}
                salesGroupID={salesGroupID}
                salesPersonID={salesPersonID}
                dateFrom={dateFrom}
                dateTo={dateTo}
                dataGrid={dataGrid}
                iPermission={iPermission}

                onSearch={this.onSearch}
                getReference={e => this.gridContainer = e}
                onSelectionChanged={this.onSelectionChanged}
                validateDateFrom={this.validateDate.bind(this, this.state.dateTo, 1)}
                validateDateTo={this.validateDate.bind(this, this.state.dateFrom, 2)}
                onSave={this.onSave}
                onGoBack={this.onGoBack}
                onChangeCheckbox={this.onChangeCheckbox}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}
                onChangeSaleGroup={this.onStateChange.bind(this, 'salesGroupID')}
                onChangeGender={this.onChangeDropDown.bind(this, 'gender', 'ID')}
                onChangeTime={this.onChangeTime}
                onChangeDate={this.onChangeDate}
                onChangeInput={this.onChangeInput}
                onChangeSalePerson={this.onStateChange.bind(this, 'salesPersonID')}
                onChangeCustomer={this.onChangeDropDown.bind(this, 'customerID', null)}
                onChangeCustomerLevel={this.onChangeDropDown.bind(this, 'customerLevelID', 'CustomerLevelID')}
                onChangeCompanyStatus={this.onChangeDropDown.bind(this, 'companyStatusID', 'CompanyStatusID')}
                onSwitch={this.onStateChange.bind(this, 'isCustomer')}
            />
        )
    }
}

const Ui = memo((props) => {
    return (
        <PageContainer tracking={'W20F1003'} id={'w20F1003'} title={Config.lang('CRM_Tim_kiem_lien_he')}>
            <Form horizontal={true}>
                <FormGroup>
                    <Col lg={12}>
                        <ButtonBack name={Config.lang('CRM_Quay_lai')} onClick={props.onGoBack}/>
                        <ButtonSave className={'mgl5'} name={Config.lang('CRM_Luu')}
                                    onClick={props.onSave}
                                    disabled={props.iPermission < 2}/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col lg={4} md={4} style={style.lineDivision}>
                        <FormGroup>
                            <Col lg={12} md={12}>
                                <Alert style={style.alert} bsStyle="info">
                                    <strong>{Config.lang('CRM_Dieu_kien_loc')}</strong>
                                </Alert>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={12} md={12}>
                                <MultipleSwitch style={{width: '100%'}}
                                                value={props.isCustomer}
                                                onValueChange={props.onSwitch}
                                                switchedOffText={Config.lang('CRM_Khach_hang')}
                                                switchedOnText={Config.lang('CRM_Lien_he')}/>
                            </Col>
                        </FormGroup>
                        <div style={{display: props.isCustomer ? 'block' : 'none'}}>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <CheckBox
                                        name={'isCustomer'}
                                        onValueChanged={props.onChangeCheckbox}
                                        text={Config.lang("CRM_Khach_hang")}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <CheckBox
                                        name={'isPartner'}
                                        onValueChanged={props.onChangeCheckbox}
                                        text={Config.lang("CRM_Doi_tac")}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <CheckBox
                                        name={'isCompetitor'}
                                        onValueChanged={props.onChangeCheckbox}
                                        text={Config.lang("CRM_Doi_thu")}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownSaleGroup onChange={props.onChangeSaleGroup}
                                                       showClearButton={true}
                                                       placeholder={Config.lang('CRM_Nhom_kinh_doanh')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownSalePerson onChange={props.onChangeSalePerson}
                                                        showClearButton={true}
                                                        groupSalesID={props.salesGroupID}
                                                        placeholder={Config.lang('CRM_Nhan_vien_kinh_doanh')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownCustomer onChangeCustomer={props.onChangeCustomer}
                                                      salesPersonID={props.salesPersonID}
                                                      groupSalesID={props.salesGroupID}
                                                      placeHolder={Config.lang('CRM_Khach_hang')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownCustomerLevel onChange={props.onChangeCustomerLevel}
                                                           showClearButton={true}
                                                           placeholder={Config.lang('CRM_Phan_loai_khach_hang')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownCompanyStatus onChange={props.onChangeCompanyStatus}
                                                           showClearButton={true}
                                                           placeholder={Config.lang('CRM_Trang_thai')}/>
                                </Col>
                            </FormGroup>
                        </div>
                        {/**End Liên hệ */}
                        {/**Start Khách hàng */}
                        <div style={{display: props.isCustomer ? 'none' : 'block'}}>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <CheckBox
                                        name={'isAdmin'}
                                        onValueChanged={props.onChangeCheckbox}
                                        text={Config.lang("CRM_Lien_he_chinh")}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <input name={'fullName'} className={'form-control'}
                                           onChange={props.onChangeInput}
                                           placeholder={Config.lang('CRM_Ten_lien_he')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <Combo
                                        idDataGrid={'gender'}
                                        showHeader={false}
                                        showClearButton={true}
                                        placeholder={Config.lang('CRM_Gioi_tinh')}
                                        onActionWhenSelectChange={props.onChangeGender}
                                        dataSource={props.dataSourceGender}
                                        valueExpr="ID"
                                        displayExpr="Name"
                                    >
                                        <Column dataField={'Name'} dataType={'string'}/>
                                    </Combo>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <input name={'mobileNo'}
                                           className={'form-control'}
                                           onChange={props.onChangeInput}
                                           placeholder={Config.lang('CRM_Dien_thoai')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <input name={'email'}
                                           className={'form-control'}
                                           onChange={props.onChangeInput}
                                           placeholder={'Email'}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <input name={'jobPosition'}
                                           className={'form-control'}
                                           onChange={props.onChangeInput}
                                           placeholder={Config.lang('CRM_Chuc_vu')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <input name={'homeAddress'}
                                           className={'form-control'}
                                           onChange={props.onChangeInput}
                                           placeholder={Config.lang('CRM_Dia_chi')}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DropDownTypeTime placeholder={Config.lang('CRM_Ngay_sinh')}
                                                      listType={'W20F1002_TypeTime'} onChange={props.onChangeTime}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DateBox
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={"100%"}
                                        name={'dateFrom'}
                                        onValueChanged={props.onChangeDate}
                                        value={props.dateFrom}
                                        placeholder={Config.lang("CRM_Tu_ngay")}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                    >
                                        <Validator>
                                            <CustomRule validationCallback={props.validateDateFrom}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={12} md={12}>
                                    <DateBox
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={"100%"}
                                        name={'dateTo'}
                                        value={props.dateTo}
                                        onValueChanged={props.onChangeDate}
                                        placeholder={Config.lang("CRM_Den_ngay")}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        type={'date'}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                    >
                                        <Validator>
                                            <CustomRule validationCallback={props.validateDateTo}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                            </FormGroup>
                        </div>
                        <FormGroup>
                            <Col lg={12} md={12}>

                                <ButtonBasic domProps={{style: style.searchButton}} onClick={props.onSearch}
                                             disabled={props.iPermission < 1}>
                                    <i className="fas fa-search"/>{` ${Config.lang('CRM_Tim_kiem')}`}
                                </ButtonBasic>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col lg={8} md={8}>
                        <FormGroup>
                            <Col lg={12}>
                                <Alert style={style.alert} bsStyle="info">
                                    <strong>{Config.lang('CRM_Danh_sach_lien_he')}</strong>
                                </Alert>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={12}>
                                <GridContainer
                                    selection={{
                                        mode: 'multiple',
                                        allowSelectAll: true,
                                        showCheckBoxesMode: 'always'
                                    }}
                                    skipPerPage={props.skip}
                                    ref={props.getReference}
                                    listPerPage={[15, 20, 30, 50]}
                                    onSelectionChanged={props.onSelectionChanged}
                                    onChangePage={props.onChangePage}
                                    onChangePerPage={props.onChangePerPage}
                                    itemPerPage={props.limit}
                                    totalItems={props.dataGrid.total}
                                    dataSource={props.dataGrid.rows}>
                                    <Column
                                        dataField={'FullNameU'}
                                        caption={Config.lang("CRM_Ho_va_ten")}
                                    />
                                    <Column
                                        dataField={'CompanyNameU'}
                                        caption={Config.lang("CRM_Khach_hang")}
                                    />
                                    <Column
                                        dataField={'JobPositionU'}
                                        caption={Config.lang("CRM_Chuc_vu")}
                                    />
                                    <Column
                                        dataField={'Gender'}
                                        caption={Config.lang("CRM_Gioi_tinh")}
                                    />
                                    <Column
                                        dataField={'Birthday'}
                                        format={'dd-MM-yyyy'}
                                        dataType={'date'}
                                        alignment={'center'}
                                        caption={Config.lang("CRM_Ngay_sinh")}
                                    />
                                    <Column
                                        caption={`Email ${Config.lang('CRM_Cong_ty').toLowerCase()}`}
                                        dataField={'Email'}
                                    />
                                    <Column
                                        caption={`Email ${Config.lang('CRM_Ca_nhan').toLowerCase()}`}
                                        dataField={'Email2'}
                                    />
                                    <Column
                                        caption={`${Config.lang('CRM_Dien_thoai')} ${Config.lang('CRM_Cong_ty').toLowerCase()}`}
                                        dataField={'OfficeTelNo'}
                                    />
                                    <Column
                                        caption={`${Config.lang('CRM_Dien_thoai')} ${Config.lang('CRM_Di_dong').toLowerCase()}`}
                                        dataField={'MobileNo'}
                                    />
                                    <Column
                                        caption={'Skype'}
                                        dataField={'Skype'}
                                    />
                                    <Column
                                        caption={'Facebook'}
                                        dataField={'Facebook'}
                                    />
                                    <Column
                                        caption={Config.lang('CRM_Dia_chi')}
                                        dataField={'HomeAddressU'}
                                    />
                                    <Column
                                        caption={Config.lang('CRM_Ma_lien_he')}
                                        dataField={'ContactID'}
                                    />
                                </GridContainer>
                            </Col>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </Form>
        </PageContainer>
    )
});

index.propTypes = {
    listCustomerID: PropTypes.string
};

export default connect(null,
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        action: action
    }))(index)