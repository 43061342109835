/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19f1020Actions from "../../actions/w19/w19f1020/w19f1020-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config';
import {Column, Summary, TotalItem,} from 'devextreme-react/data-grid';
import {
    Form,
    Col, FormGroup, Row,
} from 'react-bootstrap';
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import * as w19F1001Actions from "../../actions/w19/w19F1001/w19F1001-actions";
import DynamicStepComponent from "../common/dynamic-step-progress-bar/dynamic-step-progress-bar";

import moment from "moment";
import 'moment/locale/vi'
import ButtonBack from "../common/button/button-back";
import _ from "lodash";

moment.locale('vi');

class W19F1021Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            showPopup: false,
            isVisible: false,
            dataCurrencyID: '',
            dataBaseCurrency: '',
            baseCurrencyID: '',
            caption: [],
            statusColumn: '',
            
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: ''
        };
        this.salesPersonID = '';
        this.dataBaseCurrency = '';
        this.dataCurrencyID = 'USD';
        this.mode = '';
        this.rowData = '';
        this.dataGird = [];
        this.OTotalAmount = '';
        this.CTotalAmount = '';
        this.dataCurrencyID = '';
        this.showGrid = false;

    };

    componentDidMount() {
        if (!this.getInfo()) return;
        this.loadMaster();
        this.loadGirdW19F1021();
        this.loadListStatus();
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('quotationID')) {
            return {
                quotationID: url.get('QuotationID'),
                tranTypeID: url.get('TranTypeID'),
                screenID: url.get('ScreenID')
            };
        } else if (location && location.state) {
            return {
                quotationID: location.state.QuotationID,
                tranTypeID: location.state.TranTypeID,
                screenID: location.state.ScreenID,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W19F1020');
                return null;
            }
            return false;
        }
    };

    loadListStatus = () => {
        const { quotationID } = this.getInfo();
        const param = {
            Language: Config.language || "84",
            FormID: "W19F1021",
            DataType: "Status",
            QuotationID: quotationID
        };
        this.props.w19f1020Actions.loadListStatus(param, (error) => {
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } 
        })
    };

    getStatusColumn(tranTypeID = '') {
        const param = {
            TranTypeID: tranTypeID
        };
        this.props.w19f1020Actions.getStatusColumn(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = _.get(error, "message", Config.lang("Loi_chua_xac_dinh"));
                        break;
                }
                Config.popup.show('INFO', message);
                return false;

            }
            if (data.length > 0) {
                this.setState({
                    statusColumn: data[0]
                });
            }


        })
    }

    loadCaption(cb) {
        // Do nguon ten va datafield cot dong
        this.props.w19F1001Actions.loadCaption((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = _.get(error, "message", Config.lang("Loi_chua_xac_dinh"));
                        break;
                }
                Config.popup.show('INFO', message);
                return false;

            }
            this.setState({
                caption: data
            });
            if (cb) {
                cb();
            }
            this.showGrid = true;

        })

    }

    componentWillMount() {
        const {tranTypeID} = this.getInfo();
        this.loadCaption(() => {
            this.getStatusColumn(tranTypeID);
        });
    }

    loadMaster() {
        const {quotationID} = this.getInfo();
        const uName = Config.profile.UserID;
        const uLanguage = Config.language || '84';
        const divisionID = Config.profile.DivisionID || '';
        const formID = 'W19F1021';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": formID,
            "QuotationID": quotationID,
            "DivisionID": divisionID,
        };
        this.props.w19f1020Actions.masterW19F1021(params, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
            } else if (data && data.length > 0) {
                this.setState({
                    dataCurrencyID: data[0].CurrencyID
                }, () => {
                    this.loadCurrencyW19F1021(this.state.dataCurrencyID)
                });
            }
        })
    }

    loadGirdW19F1021() {
        const {quotationID, tranTypeID} = this.getInfo();
        const uName = Config.profile.UserID;
        const uLanguage = Config.language || '84';
        const divisionID = Config.profile.DivisionID || '';
        const formID = 'W19F1021';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": formID,
            "QuotationID": quotationID,
            "DivisionID": divisionID,
            "TranTypeID": tranTypeID,
        };
        this.props.w19f1020Actions.dataGirdW19F1021(params, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
            } else if (data && data.length > 0) {
                this.setState({
                    dataGirdW19: data
                });
                this.OTotalAmount = data[0].OTotalAmount || "";
                this.CTotalAmount = data[0].CTotalAmount || "";
            } else {
                this.setState({
                    dataGirdW19: []
                });
                this.OTotalAmount = '';
                this.CTotalAmount = '';
            }
        })
    }

    goBack = () => {
        const {screenID} = this.getInfo();
        if (screenID) {
            browserHistory.push(Config.getRootPath() + screenID);
        } else {
            browserHistory.goBack();
        }
    };

    loadCurrencyW19F1021(curent) {
        this.props.w19F1001Actions.getBaseCurrency((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
            } else if (data && data.length > 0) {
                this.setState({
                    dataBaseCurrency: data[0].BaseCurrencyID
                });
                if (this.state.dataBaseCurrency !== curent) {
                    this.setState({
                        isVisible: true,
                    })
                }
            }
        });
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1611", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }


    unicodeConvert(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    }

    onSearch(text) {
        let grid = [...this.state.dataGirdW19], i = 0;
        for (i; i < grid.length; i++) {
            let stringSearch = grid[i].InventoryID + grid[i].ItemNameU;
            if (this.unicodeConvert(stringSearch).search(new RegExp(this.unicodeConvert(text), "i")) < 0) {
                grid[i].hide = true;
            } else {
                grid[i].hide = false;
            }
        }
        this.setState({
            dataGirdW19: grid
        });
    }

    render() {
        if (!this.getInfo(true)) return null;
        let visibleGrid = [];
        const {masterW19, statusList} = this.props;
        const {caption, statusColumn} = this.state;
        const dataCTotalAmount = this.CTotalAmount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const dataOTotalAmount = this.OTotalAmount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const {dataGirdW19} = this.state;
        dataGirdW19 && dataGirdW19.forEach((value) => {
            if (!value.hide || typeof value.hide === undefined) {
                visibleGrid.push(value);
            }
        });

        return (
            <div className="page-container" style={{margin: 0}} id={'clRequiredField'}>
                <EventTracking action={"FORM"} label={"W19F1021 "}/>
                <div style={{marginLeft: '10px'}} className="grid-title">{Config.lang("CRM_Chi_tiet_don_hang")}</div>

                <Form horizontal={true} id={'frmW18F1011'} style={{margin: '0 15px'}} onSubmit={e => {
                    e.preventDefault();
                }}>
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonBack name={Config.lang("CRM_Quay_lai")}
                                        onClick={this.goBack}/>
                        </Col>
                    </FormGroup>
                    {/*</Form>*/}
                    <FormGroup>
                        <Col xs={12} sm={12} lg={12} md={12}>
                            <div className="page-requiredField">
                                <FormGroup>
                                    <div className="grid-title title-field">
                                        <label>{Config.lang("CRM_Thong_tin_don_hang")}</label></div>
                                </FormGroup>
                                <FormGroup className={'pdl10'}>
                                    <Row>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_So_don_hang")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].VoucherNum ? masterW19[0].VoucherNum : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Bang_bao_gia")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].PriceListID ? masterW19[0].PriceListID : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Ma_khach_hang")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].CustomerID ? masterW19[0].CustomerID : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className={'pdl10'}>
                                    <Row>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Ngay_lap")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>
                                            {masterW19 && masterW19.length > 0 && masterW19[0] ? moment(masterW19[0].QuotationDate).format("DD/MM/YYYY") : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Loai_tien")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{this.state.dataCurrencyID}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Ten_khach_hang")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].CustomerNameU ? masterW19[0].CustomerNameU : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className={'pdl10'}>
                                    <Row>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].SalesPersonName ? masterW19[0].SalesPersonName : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Ty_gia")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].ExchangeRate ? masterW19[0].ExchangeRate : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Phuong_thuc_giao_hang")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].DeliveryMethodName ? masterW19[0].DeliveryMethodName : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className={'pdl10'}>
                                    <Row>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Dien_dai")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={6}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].DescriptionU ? masterW19[0].DescriptionU : null}
                                        </Col>
                                        <Col xs={12} sm={12} lg={2} md={2}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Noi_giao_hang")}</label>
                                        </Col>
                                        <Col className={'control-label'} xs={12} sm={12} lg={2}
                                             md={2}>{masterW19 && masterW19.length > 0 && masterW19[0].ShipAddressU ? masterW19[0].ShipAddressU : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </div>
                        </Col>
                    </FormGroup>
                    <DynamicStepComponent
                        step={masterW19 && masterW19.length > 0 && (masterW19[0].StatusID || 0)}
                        statusList={statusList} />
                    <FormGroup className={'grid-full-width'}>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {
                                this.showGrid && <GridContainer
                                    widthFull={true}
                                    dataSource={visibleGrid}
                                    columnResizingMode={'widget'}
                                    isPer={this.state.iPermission}
                                    onSearch={text => this.onSearch(text)}
                                    buttonCRM={[
                                        {
                                            buttonType: "label",
                                            dataFieldLb: `${dataOTotalAmount}`,
                                            buttonName: `${Config.lang("CRM_Tong_gia_tri_don_hang")}: `,
                                            className: 'pdr10',
                                            align: 'right',
                                        },
                                        {
                                            buttonType: "label",
                                            dataFieldLb: `${dataCTotalAmount}`,
                                            buttonName: `${Config.lang("CRM_Tong_gia_tri_don_hang_QD")}: `,
                                            className: `${this.state.isVisible === false ? 'hide' : ''} pdr10`,
                                            align: 'right',
                                        },
                                    ]}
                                >
                                    <Column
                                        dataField={'Action'}
                                        caption={Config.lang("STT")}
                                        alignment={'center'}
                                        cellRender={(e) => {
                                            return e.rowIndex + 1
                                        }}
                                        width={90}
                                    />
                                    <Column
                                        dataField={'InventoryID'}
                                        caption={Config.lang("CRM_Ma_hang_hoa")}
                                        width={120}
                                    />
                                    <Column
                                        dataField={'ItemNameU'}
                                        caption={Config.lang("CRM_Ten_hang_hoa")}
                                        width={400}
                                    />
                                    <Column
                                        dataField={'UnitID'}
                                        alignment={'center'}
                                        caption={Config.lang("CRM_DVT")}
                                        width={120}
                                    />
                                    <Column
                                        dataField={'Quantity'}
                                        caption={Config.lang("CRM_So_luong")}
                                        width={130}
                                    />
                                    <Column
                                        dataField={'CQuantity'}
                                        visible={this.state.isVisible}
                                        caption={Config.lang("CRM_So_luong_QD")}
                                        width={150}
                                    />
                                    <Column
                                        dataField={'OUnitPrice'}
                                        format={'#,##0'}
                                        caption={Config.lang("CRM_Don_gia_truoc_thue")}
                                        width={200}
                                    />
                                    <Column
                                        dataField={'OAmountTmp'}
                                        caption={Config.lang("CRM_Thanh_tien")}
                                        format={'#,##0'}
                                        width={250}
                                    />
                                    <Column
                                        dataField={'CAmountTmp'}
                                        caption={Config.lang("CRM_Thanh_tien_QD")}
                                        format={'#,##0'}
                                        visible={this.state.isVisible}
                                        width={250}
                                    />
                                    {caption.length > 0 && caption.map((value, index) => {
                                        let propertyACOL = 'Display' + value.ColID;
                                        if (!value.ColShortU.includes('QĐ')) {
                                            return (<Column key={index}
                                                            dataField={value.ColID}
                                                            dataType={'number'}
                                                            format={'#,##0'}
                                                            width={150}
                                                            visible={statusColumn ? statusColumn[propertyACOL] === 1 : true}
                                                            caption={value.ColShortU}/>)
                                        } else {
                                            let formatColID = value.ColID.replace('C_', '');
                                            let propertyCCOL = 'Display' + formatColID;

                                            return (<Column key={index}
                                                            dataField={value.ColID}
                                                            dataType={'number'}
                                                            format={'#,##0'}
                                                            width={150}
                                                // visible={statusColumn ? statusColumn[propertyCCOL] === 1:this.state.isVisible }
                                                            visible={(statusColumn ? statusColumn[propertyCCOL] === 1 : true) && this.state.isVisible}
                                                            caption={value.ColShortU}/>)
                                        }
                                    })}
                                    <Column
                                        dataField={'Amount'}
                                        width={200}
                                        format={'#,##0'}
                                        caption={Config.lang("CRM_Thanh_tien_sau_DC")}/>
                                    <Column
                                        dataField={'CAmount'}
                                        width={200}
                                        format={'#,##0'}
                                        visible={this.state.isVisible}
                                        caption={Config.lang("CRM_Thanh_tien_sau_DC_QD")}/>
                                    <Column
                                        dataField={'RateReduce'}
                                        caption={Config.lang("CRM_Ty_le_CK")}
                                        width={120}
                                    />
                                    <Column
                                        dataField={'OriginalReduce'}
                                        caption={Config.lang("CRM_Thanh_tien_sau_CK")}
                                        format={'#,##0'}
                                        width={250}/>
                                    <Column
                                        dataField={'CReduce'}
                                        caption={Config.lang("CRM_Thanh_tien_QD_sau_CK")}
                                        format={'#,##0'}
                                        visible={this.state.isVisible}
                                        width={250}/>
                                    <Column
                                        dataField={'TotalAdjustedAmount'}
                                        width={200}
                                        format={'#,##0'}
                                        caption={Config.lang("CRM_Tong_tien_sau_DC")}/>
                                    <Column
                                        dataField={'TotalAdjustedCAmount'}
                                        width={200}
                                        format={'#,##0'}
                                        visible={this.state.isVisible}
                                        caption={Config.lang("CRM_Tong_tien_sau_DC_QD")}/>
                                    <Column
                                        dataField={'AdjustedUnitPrice'}
                                        width={200}
                                        format={'#,##0'}
                                        caption={Config.lang("CRM_Don_gia_sau_DC")}/>
                                    <Column
                                        dataField={'VATRate'}
                                        width={150}
                                        caption={Config.lang("CRM_Thue_suat")}/>
                                    <Column
                                        dataField={'OVAT'}
                                        width={200}
                                        format={'#,##0'}
                                        caption={Config.lang("CRM_Thue_GTGT")}/>
                                    <Column
                                        dataField={'CVAT'}
                                        caption={Config.lang("CRM_Thue_GTGT_sau_QD")}
                                        format={'#,##0'}
                                        visible={this.state.isVisible}
                                        width={250}/>
                                    <Column
                                        dataField={'IsSearch'}
                                        caption={Config.lang("Search")}
                                        visible={false}
                                        width={250}/>
                                    <Column
                                        dataField={'DeliveryDate'}
                                        alignment={'center'}
                                        width={200}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                        caption={Config.lang("CRM_Ngay_giao_hang")}
                                    />
                                    <Column
                                        allowEditing={false}
                                        dataField={'QuantityInvoice'}
                                        width={130}
                                        format={'#,##0'}
                                        caption={Config.lang("SL_hoa_don")} />


                                    <Summary>
                                        {caption.length > 0 && caption.map((value) => {
                                            return (
                                                <TotalItem
                                                    key={value.ColID}
                                                    displayFormat={"{0}"}
                                                    column={value.ColID}
                                                    valueFormat={'#,##0.##'}

                                                    summaryType={'sum'}
                                                />
                                            )
                                        })}
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'Quantity'}
                                            valueFormat={'#,##0.##'}

                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'CQuantity'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />

                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'OAmountTmp'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'CAmountTmp'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'OVAT'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'OriginalReduce'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'CReduce'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'TotalAdjustedAmount'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'TotalAdjustedCAmount'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'Amount'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />
                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'CAmount'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />


                                        <TotalItem
                                            displayFormat={"{0}"}
                                            column={'CVAT'}
                                            valueFormat={'#,##0.##'}
                                            summaryType={'sum'}
                                        />


                                    </Summary>
                                </GridContainer>
                            }
                        </Col>
                    </FormGroup>
                </Form>

            </div>

        )
    }
}

export default connect(state => ({
        masterW19: state.w19f1020.masterW19,
        dataGridW19: state.w19f1020.dataGridW19,
        loadBaseCurrency: state.w19f1020.loadBaseCurrency,
        statusList: state.w19f1020.statusList,
    }),
    (dispatch) => ({
        w19f1020Actions: bindActionCreators(w19f1020Actions, dispatch),
        w19F1001Actions: bindActionCreators(w19F1001Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F1021Page);
