import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import { Button, Checkbox, DatePicker, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Input } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import exportFlexReport from "../../actions/export-flex-report";
import passParamsFlexExportReport from "../../actions/pass-params-flex-export-report";
import * as W94F1000Actions from "../../actions/w94/W94F1000/W94F1000-actions";
import Config from "../../config/index";
import ButtonCustom from "../common/button/button-custom";
import Combo from "../common/dropdown/combo-flex-report/combo-report-list";
import { Loading } from "../common/loading/loading";

const { RangePicker } = DatePicker;

const styles = theme => {
    return {
        btnPrint: {
            [theme.breakpoints.down(768)]: {
                '& span>.mgl5': {
                    display: 'none'
                }
            },
        },
        formInfo: {
            '& .mgt-15': {
                marginTop: 15
            }
        },
        reportTitle: {
            display: 'flex',
            fontSize: '18px',
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        btnExportReport: {
            marginTop: -2
        }
    }
};

class W94F1002Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailChart: null,
            dataForm: [],
            reportUrl: "",
            downloadURL: "",
            downloadName: "",
            downloadURLExport: "",
            downloadNameExport: "",
            showPreview: false,
            heightPreview: 0,
            loading: {},
            exportLoading: false,
            dateLoading: false,
            loadingStatus: false,
            dataFilter: [
                { "id": "UserID", "type": "VarChar", "value": Config.profile.UserID },
                { "id": "DivisionID", "type": "VarChar", "value": Config.profile.DivisionID || "" }
            ]
        };
        this.formMaster = null;
        this.minHeightView = 550;
        this.loading = {};
    }

    getInfo = (flag) => {
        const { location } = this.props;
        const { detailChart } = this.state;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("MReportID")) {
            return {
                MReportID: url.get("MReportID"),
                MReportName: detailChart.MReportName || "",
                ModuleID: detailChart.ModuleID || "",
                URL: detailChart.URL || "",
                strSQL: detailChart.strSQL || "",
                Sheet: detailChart.Sheet || "",
                FileName: detailChart.FileName || ""
            };
        } else if (location && location.state) {
            return {
                ModuleID: location.state.ModuleID,
                MReportID: location.state.MReportID,
                MReportName: location.state.MReportName,
                URL: location.state.URL,
                strSQL: location.state.strSQL,
                Sheet: location.state.Sheet,
                FileName: location.state.FileName,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W94F1000");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        if (!this.getInfo(true)) return null;
        await this.getReportDetail();
        this.getControlMasterAndDetail();
    };

    getControlMasterAndDetail = () => {
        const { MReportID, ModuleID } = this.getInfo();
        const { dataForm } = this.state;
        this.setState({ loadingStatus: true })
        this.props.W94F1000Actions.getControlMaster({ MReportID }, (err, data) => {
            if (err) {
                this.setState({ loadingStatus: false })
                let message = (err && err.message) || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const dataTemp = data.sort(function (a, b) {
                    // Compare the 2 dates
                    if (a.DisplayOrder < b.DisplayOrder) return -1;
                    if (a.DisplayOrder > b.DisplayOrder) return 1;
                    return 0;
                });
                const params = {
                    ModuleID,
                    MReportID,
                    DivisionID: Config.profile.DivisionID || ""
                }
                this.props.W94F1000Actions.getControlDetailReport(params, (errStruct, dataStruct) => {
                    this.setState({ loadingStatus: false })
                    if (errStruct) {
                        let message = errStruct.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    if (dataStruct && dataForm) {
                        //gán dataCombo, valueExpr, displayExpr cho combo
                        if (dataStruct) {
                            dataStruct.forEach(struct => {
                                dataTemp.forEach((control, controlIdx) => {
                                    if (control.ControlID === struct.ControlID && (control.ControlType === "C" || control.ControlType === "CC" || control.ControlType === "OPT")) {
                                        if (!dataTemp[controlIdx].dataCombo) dataTemp[controlIdx].dataCombo = struct.rows;
                                        if (!dataTemp[controlIdx].fields) dataTemp[controlIdx].fields = struct.fields;
                                        struct.fields.forEach(type => {
                                            if (type.Style.includes("V")) dataTemp[controlIdx].valueExpr = type.FieldName;//displayExpr
                                            if (type.Style.includes("D")) dataTemp[controlIdx].displayExpr = type.FieldName;//displayExpr
                                        });
                                    }
                                });
                            });
                        }
                        dataTemp.forEach(temp => {
                            // check dataFilter with default
                            if (temp.ControlType === "C" || temp.ControlType === "CC") {
                                const percent = temp.ControlType === "CC" ? ["%"] : "%";
                                if (temp.DefaultValue === "%") this.handleData(temp.ControlID, "VarChar", percent);
                                else {
                                    const item = !_.isEmpty(temp.dataCombo) ? temp.dataCombo.find(i => i[temp.valueExpr] === temp.DefaultValue) : null;
                                    this.handleData(temp.ControlID, "VarChar", ((temp.DefaultValue || temp.DefaultValue === 0) && item) ? ((_.isString(item[temp.valueExpr]) && temp.ControlType === "CC") ? [item[temp.valueExpr]] : item[temp.valueExpr]) : null);

                                }
                            }
                            if (temp.ControlType === "T") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue);
                            if (temp.ControlType === "D") this.handleData(temp.ControlID, "VarChar", moment().format("YYYY-MM-DD"));
                            if (temp.ControlType === "CHK") this.handleData(temp.ControlID, "INT", parseInt(temp.DefaultValue) === 0 || parseInt(temp.DefaultValue) === 1 ? parseInt(temp.DefaultValue) : null);
                            if (temp.ControlType === "OPT") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue ? temp.DefaultValue : null);
                        });
                        this.setState({
                            dataForm: dataTemp
                        });
                    }
                });
            }
        });
    }

    getReportDetail = () => {
        const { MReportID } = this.getInfo();
        const { dataGroupList } = this.props;
        if (_.isEmpty(dataGroupList)) { // is null or empty
            const params = {
                MReportID
            };
            this.props.W94F1000Actions.getGroupList(params, (err, data) => {
                if (data && data.length > 0) {
                    const detailChart = data.find(i => i.MReportID === MReportID);
                    this.setState({ detailChart });
                }
                if (err) {
                    let message = err.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
            });
        }
        else if (dataGroupList && dataGroupList.Detail) {
            const detailChart = dataGroupList.find(i => i.MReportID === MReportID);
            if (detailChart) {
                this.setState({ detailChart })
            }
            else {
                Config.popup.show("INFO", Config.lang("Khong_co_du_lieu"))
            }
        }
    }

    getHeightOfViewer = () => {
        let height = 0;
        if (this.formMaster) {
            const headerHeight = 50;
            const hMaster = this.formMaster.offsetHeight;
            const hw = window.innerHeight;
            height = hw - (hMaster + headerHeight + 57);
        }
        return height && height < this.minHeightView ? this.minHeightView : height;
    };

    handleData = (key, type, value,) => {
        const dataFilterT = type === "DATETIME" ? this.state.dataFilter : [...this.state.dataFilter];
        //Rage Picker không xài clone state được 
        const item = {
            id: key,
            type,
            value
        };
        let isExit = false;
        dataFilterT.forEach((i, idx) => {
            if (i.id === key) {
                dataFilterT[idx] = item;
                isExit = true;
            }
        });
        if (!isExit) {
            dataFilterT.push(item);
        }
        this.setState({ dataFilter: dataFilterT })
    };

    _setLoading = (key, value, cb) => {
        if (!key) return false;
        if (typeof key === "object") {
            this.loading = {
                ...this.loading,
                ...key
            };
        } else {
            this.loading[key] = value;
        }
        this.setState({ loading: { ...this.loading } }, () => {
            if (cb) cb();
        });
    };

    changeValueNullToString = (array) => {
        const storeParams = array.map(obj => {
            const item = { ...obj };
            if (item && (_.isNull(item.value) || _.isUndefined(item.value))) {
                item.value = ""
            }
            else {
                if (_.isArray(item.value) && !_.isEmpty(item.value) && item.value.length > 1) {
                    const customValue = item.value.map((valueStr, index) => {
                        let parseStr = `''${valueStr}''`;// Default các Value ở giữa 
                        if (index === 0) parseStr = `${valueStr}''` // Value đầu mảng 
                        if (index === item.value.length - 1) parseStr = `''${valueStr}` // Value cuối mảng 
                        return parseStr
                    })
                    item.value = [customValue.toString()];
                }
            }
            return { ...item }
        })
        return storeParams;
    }

    exportExcelTemplate = () => {
        const { URL, Sheet, MReportName, strSQL } = this.getInfo();
        const { dataFilter } = this.state;
        const storeParams = this.changeValueNullToString(dataFilter);
        const params = {
            TemplateURL: URL,
            strSQL,
            isPDF: 0,
            Sheet: Sheet || 1, // default = 1
            ReportFileName: MReportName || "",
            StoreParams: JSON.stringify(storeParams)
        };
        this._setLoading("Export", true);
        exportFlexReport(params, (errors, data) => {
            this._setLoading("Export", false);
            if (errors) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (errors.code) {
                    case "EX008":
                        message = Config.lang("Mau_bao_cao_khong_duoc_tim_thay");
                        break;
                    case "MSQ005":
                        message = Config.lang("Du_lieu_xuat_excel_rong");
                        break;
                    case "MSQ006":
                        message = Config.lang("Truong_ngay_la_bat_buoc");
                        break;
                    case "EXP001":
                        message = Config.lang("Du_lieu_dau_vao_la_bat_buoc");
                        break;
                    default:
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    downloadURLExport: data.URL || "",
                    downloadNameExport: data.fileName || "",
                }, () => {
                    let el = document.getElementById("exportReportUrlLink");
                    el.click();
                });
            }
        });
    };

    checkParamPreviewTemplate = () => {
        const { URL, strSQL, Sheet, MReportName } = this.getInfo();
        const { dataFilter } = this.state;
        const storeParams = this.changeValueNullToString(dataFilter);
        const params = {
            URL,
            SQL: strSQL,
            IsPDF: 0,
            Sheet: Sheet || 1,
            FileName: MReportName || "",
            StoreParam: JSON.stringify(storeParams)
        };
        this._setLoading("Preview", true);
        passParamsFlexExportReport(params, (errors, data) => {
            this._setLoading("Preview", false);
            if (errors) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (errors.code) {
                    case "EX008":
                        message = Config.lang("Mau_bao_cao_khong_duoc_tim_thay");
                        break;
                    case "MSQ005":
                        message = Config.lang("Du_lieu_xuat_excel_rong");
                        break;
                    case "MSQ006":
                        message = Config.lang("Truong_ngay_la_bat_buoc");
                        break;
                    case "EXP001":
                        message = Config.lang("Du_lieu_dau_vao_la_bat_buoc");
                        break;
                    default:
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.id) {
                this.previewExcelTemplate(data.id);
            } else {
                Config.popup.show("INFO", Config.lang("Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    previewExcelTemplate = async (ID) => {
        if (!ID) return;

        let url = Config.env.api + "/export/preview-flex-excel-report?";

        const params = {
            token: Config.token.id,
            ID: ID
        };

        let param = Object.keys(params).map(function (k) {
            return k + "=" + params[k];
        }).join("&");

        url = url + encodeURIComponent(param);

        if (Config.env.system) {
            url = Config.env.system + "/export/forward-preview-excel-report?url=" + url;
            url = encodeURIComponent(url);
        }

        this.setState({
            showPreview: false,
            heightPreview: this.getHeightOfViewer(),
        }, () => {
            this.setState({
                downloadURL: url,
                showPreview: true
            }, () => {
                this._setLoading("Preview", true);
            });
        });

    };

    loadedOnPreview = () => {
        this._setLoading("Preview", false);
    };

    onClosePreview = () => {
        this.loading = {};
        this.setState({ showPreview: false, loading: this.loading });
    };

    checkControlStatus = (formInfo) => {
        let controlStatus = false;
        let checkBoxChanged = false;
        let defaultOriginalValue = false;
        let currentDCEvalue = formInfo.DependControlEnabled;
        let currentDCEFieldName = formInfo.DependControlEnabled;
        const { dataForm, dataFilter } = this.state;
        if (!_.isEmpty(formInfo.DependControlEnabled)) {
            const valueIndex = currentDCEvalue.split(",")[1];
            const fieldNameIndex = currentDCEFieldName.split(",")[0];
            let bracketNameIndex = fieldNameIndex.indexOf("(");
            let bracketValueIndex = valueIndex.indexOf(")");
            if (bracketNameIndex === 0) bracketNameIndex = bracketNameIndex + 1;
            if (bracketNameIndex !== -1) currentDCEFieldName = fieldNameIndex.slice(bracketNameIndex, fieldNameIndex.length);
            if (bracketValueIndex !== -1) currentDCEvalue = _.toNumber(valueIndex.slice(0, bracketValueIndex));
            const ownerDepentControl = dataForm.find(item => item.ControlID === currentDCEFieldName);
            const ownerDepentControlChanged = dataFilter.find(item => item.id === currentDCEFieldName); //CheckBox 1 and 0 only
            const ownerDepentControlDevaultValue = _.toNumber(ownerDepentControl.DefaultValue);
            checkBoxChanged = ownerDepentControlChanged && ownerDepentControlChanged.value !== currentDCEvalue; // Nếu Control hiện tại là 1 thì ẩn Control có cùng ControlID
            defaultOriginalValue = ownerDepentControlDevaultValue !== currentDCEvalue;
            controlStatus = ownerDepentControlChanged && ownerDepentControlChanged.value === 1 ? checkBoxChanged : defaultOriginalValue;
        }
        return { controlStatus };
    }

    renderInput = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        const value = dataFilter.find(i => i.id === formInfo.ControlID);
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Input disabled={disable}
                    style={{ width: '100%', height: 32, borderRadius: 2, boxShadow: 'none' }}
                    defaultValue={value ? value.value : ""}
                    placeholder={formInfo.CaptionName84}
                    onChange={vl => {
                        this.handleData(formInfo.ControlID, "VarChar", vl.target.value);
                    }} />
            </Col>
        );
    };

    renderDatePicker = (formInfo, idxForm) => {
        const { dataForm, dataFilter } = this.state;
        const from = "From";
        const to = "To";
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }


        let controlDateName = formInfo.ControlID;
        if (formInfo.ControlID.search(from) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - from.length);
        if (formInfo.ControlID.search(to) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - to.length);
        const controlDateFrom = controlDateName + "From";
        const controlDateTo = controlDateName + "To";
        // <=====================>Điều kiện Date cũ<=====================> 
        // if (dataForm.find(i => i.ControlID === "DateTo") && dataForm.find(i => i.ControlID === "DateFrom") && formInfo.ControlID === "DateFrom"){
        // } else if (((formInfo.ControlID !== "DateTo" && formInfo.ControlID !== "DateFrom") || ((formInfo.ControlID === "DateTo" || formInfo.ControlID === "DateFrom") && (!dataForm.find(i => i.ControlID === "DateTo") || !dataForm.find(i => i.ControlID === "DateFrom"))))) {
        if (dataForm.find(item => item.ControlID === controlDateFrom) && dataForm.find(item => item.ControlID === controlDateTo)) {
            if (formInfo.ControlID === controlDateFrom) {
                let fieldName = Config.lang("Thoi_gian");
                const languageModeName = Config.language === "01" ? "Caption01" : "CaptionName84";
                const findFromToName = dataForm.filter(o => (o.ControlID === controlDateTo || o.ControlID === controlDateFrom));
                if (findFromToName.length === 2) {
                    fieldName = `${(findFromToName[0])[languageModeName]} - ${(findFromToName[1])[languageModeName]}`;
                }
                return (
                    <Col key={idxForm}
                        className={"mgt-15"}
                        lg={this.getColSpan(formInfo.ColSpan, 6)}
                        md={this.getColSpan(formInfo.ColSpan, 6)}
                        sm={this.getColSpan(formInfo.ColSpan, 6)}
                        xs={12}
                    >
                        <label>{`${fieldName}`}</label>
                        <RangePicker style={{ width: '100%' }}
                            format={"DD/MM/YYYY"}
                            locale={locale}
                            disabled={disable}
                            onCalendarChange={(date) => {
                                this.handleData(controlDateFrom, "DATETIME", date[0] ? moment(date[0]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"));
                                this.handleData(controlDateTo, "DATETIME", date[1] ? moment(date[1]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"));

                            }}
                            defaultValue={[formInfo.DefaultValue === "@NowDate" ? moment() : null, formInfo.DefaultValue === "@NowDate" ? moment() : null]}
                        />
                    </Col>
                )
            }
        } else {
            if (!_.isEmpty()) {
                return (
                    <Col key={idxForm}
                        className={"mgt-15"}
                        lg={this.getColSpan(formInfo.ColSpan, 6)}
                        md={this.getColSpan(formInfo.ColSpan, 6)}
                        sm={this.getColSpan(formInfo.ColSpan, 6)}
                        xs={12}
                    >
                        <label>{formInfo.CaptionName84}</label>
                        <DatePicker style={{ width: '100%' }}
                            placeholder={formInfo.CaptionName84}
                            format={"DD/MM/YYYY"}
                            locale={locale}
                            disabled={disable}
                            onChange={(date) => {
                                this.handleData(formInfo.ControlID, "DATETIME", date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"));
                            }}
                            defaultValue={formInfo.DefaultValue === "@NowDate" ? moment() : null}
                        />
                    </Col>
                )
            } else {
                return null
            }
        }
    };

    renderCheckBox = (formInfo, idxForm) => {
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            //<=====================>Điều kiện disable cũ<=====================>
            // disable = [];
            // formInfo.DependControlEnabled.split(";").forEach((depend) => {
            //     let temp = depend;
            //     temp = temp.slice(1, temp.length - 1);
            //     temp = temp.split(",");
            //     dataFilter.forEach(i => {
            //         const value = i.value ? i.value.toString() : i.value;
            //         const tmp = temp[1] ? temp[1].toString() : temp[1];
            //         if (i.id === temp[0] && value === tmp) {
            //             disable.push(false)
            //         }
            //     });
            // });
            // disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
            const { controlStatus } = this.checkControlStatus(formInfo);
            disable = controlStatus;
        }
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
                style={{ minHeight: 57, display: 'flex', alignItems: 'center' }}
            >
                <Checkbox defaultChecked={parseInt(formInfo.DefaultValue) === 1}
                    disabled={disable}
                    onChange={(e) => {
                        this.handleData(formInfo.ControlID, "INT", e.target.checked ? 1 : 0);
                    }}>
                    {formInfo.CaptionName84}
                </Checkbox>
            </Col>
        );
    };

    renderRadio = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }

        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Radio.Group style={{
                    border: '1px solid #d9d9d9', borderRadius: 2, padding: '4px 10px', width: '100%'
                }} disabled={disable} onChange={e => this.handleData(formInfo.ControlID, "VarChar", e.target.value)}
                    name={"radiogroup"}
                    defaultValue={formInfo.DefaultValue ? formInfo.DefaultValue : null}>
                    {formInfo.dataCombo && formInfo.dataCombo.map((combo, idxCombo) => {
                        return (
                            <Radio key={idxCombo}
                                value={combo.Value}>
                                {combo.Lable}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </Col>
        )
    };

    getColSpan = (vl, def) => {
        return vl ? vl : def;
    };

    clearCombo = (comboChildren) => {
        this.refs[comboChildren.ControlID].setValue(null);
    };

    onBack = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W94F1000"
        })
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { dataForm, showPreview, heightPreview, loading, loadingStatus, dataFilter } = this.state;
        const { classes } = this.props;
        const { MReportName } = this.getInfo();

        return (
            <div id={"frmW94F1000"} className={"page-container"}>
                <div className={classes.reportTitle}>{MReportName}
                    <ButtonCustom className={`mgl5 ${classes.btnExportReport}`}
                        icon={"fas fa-arrow-alt-left"}
                        color={"text-orange"}
                        isAwesome={true}
                        name={Config.lang("CRM_Quay_lai")}
                        onClick={() => this.onBack()} /></div>
                <Row style={{ height: '100%', margin: 0 }} className={classes.formInfo}>
                    <div ref={ref => this.formMaster = ref} style={{ display: 'block', overflow: 'hidden' }}>
                        <Row>
                            {dataForm && dataForm.map((formInfo, idxForm) => {
                                if (formInfo.IsHide) return null;
                                switch (formInfo.ControlType) {
                                    case "T": return this.renderInput(formInfo, idxForm);
                                    case "C":
                                    case "CC":
                                        return <SelectCus key={idxForm}
                                            formInfo={formInfo}
                                            idxForm={idxForm}
                                            ref={formInfo.ControlID}
                                            dataFilter={dataFilter}
                                            dataForm={dataForm}
                                            getColSpan={this.getColSpan}
                                            clearCombo={this.clearCombo}
                                            handleData={this.handleData}
                                        />;
                                    case "D": return this.renderDatePicker(formInfo, idxForm);
                                    case "CHK": return this.renderCheckBox(formInfo, idxForm);
                                    case "OPT": return this.renderRadio(formInfo, idxForm);
                                    default: return null;
                                }
                            })}

                            {loadingStatus && <Loading />}
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <Button type={"primary"}
                                    shape={"round"}
                                    disabled={loadingStatus}
                                    onClick={() => this.exportExcelTemplate()}
                                    style={{ marginRight: 10, marginTop: 15 }}
                                    icon={<i className={"fa fa-download"} style={{ marginRight: 10 }} />}
                                >
                                    {Config.lang("Xuat_bao_cao")}
                                </Button>

                                <Button type={"primary"} shape={"round"}
                                    disabled={loadingStatus}
                                    onClick={this.checkParamPreviewTemplate}
                                    style={{ backgroundColor: 'green', borderColor: 'green', marginTop: 15 }}
                                    icon={<i className={"fa fa-eye"} style={{ marginRight: 10 }} />}
                                >
                                    {Config.lang("Xem_truoc")}
                                </Button>
                                <a id={"reportUrlLink"} className={"hide"} href={this.state.reportUrl}>View File</a>
                            </Col>
                        </Row>
                    </div>

                    {showPreview && <Col xs={12} sm={12} md={12} lg={12}>
                        <div className={"display_row align-center valign-bottom"}>
                            <IconButton aria-label={"close"} size={"small"} onClick={this.onClosePreview}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={"display_row align-center valign-middle"}>
                            {loading.Preview && <Loading className={classes.previewLoading} />}
                            <iframe src={this.state.downloadURL ? "https://view.officeapps.live.com/op/embed.aspx?src=" + this.state.downloadURL : ""}
                                title={this.state.downloadName}
                                onLoad={this.loadedOnPreview}
                                height={heightPreview}
                                // className={classes.view}
                                width={'100%'} />
                        </div>
                    </Col>}
                </Row>
                <a id={"exportReportUrlLink"} className={"hide"} download={this.state.downloadNameExport}
                    href={this.state.downloadURLExport}>File</a>
            </div>
        )
    }
}

class SelectCus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.formInfo.DefaultValue
        }
    }

    setValue = (value, controlType) => {
        controlType === "CC" ? this.setState({
            ...value,
            value
        }) : this.setState({ value })
    };

    render() {
        const { dataFilter, dataForm, formInfo, idxForm, handleData, clearCombo, getColSpan } = this.props;
        const { value } = this.state;

        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    if (i.id === temp[0] && i.value && temp[1] && i.value.toString() === temp[1].toString()) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }

        //check phụ thuộc combo
        let dataSelect = formInfo.dataCombo;
        if (formInfo.ControlDepend) {
            dataFilter.forEach(i => {
                if (i.id === formInfo.ControlDepend && i.value && i.value !== "%") {
                    const control = dataForm.find(k => k.ControlID === formInfo.ControlDepend);
                    dataSelect = _.isArray(i.value)
                        ? dataSelect.filter(obj => i.value.includes(obj[control.valueExpr]))
                        : dataSelect.filter(j => j[control.valueExpr] === i.value)
                }
            });
        }
        const controlChildren = dataForm.find(k => formInfo.ControlID === k.ControlDepend);
        const valueCombo = formInfo.ControlType === "CC" ? (!_.isEmpty(value) && _.isArray(value) && !_.isObject(value[0]) ? value.join(", ") : (value || null)) : value;
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={getColSpan(formInfo.ColSpan, 6)}
                md={getColSpan(formInfo.ColSpan, 6)}
                sm={getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Combo
                    dataSource={dataSelect}
                    showClearButton={true}
                    placeholder={formInfo.CaptionName84}
                    controlType={formInfo.ControlType}
                    valueExpr={formInfo["valueExpr"]}
                    value={valueCombo}
                    disabled={disable}
                    showHeader={true}
                    displayExpr={formInfo["displayExpr"]}
                    onActionWhenSelectChange={(value) => {
                        const valueT = formInfo.ControlType === "CC" ? value : (value ? value[formInfo["valueExpr"]] : null);
                        this.setValue(valueT, formInfo.ControlType);
                        if (handleData) handleData(formInfo.ControlID, "VarChar", valueT);
                        if (controlChildren && clearCombo) {
                            clearCombo(controlChildren);
                            handleData(controlChildren.ControlID, "VarChar", null);
                        }
                    }}
                >
                    {formInfo.fields && formInfo.fields.map((field, idxField) => {
                        this.value = value;
                        return (
                            <Column key={idxField}
                                dataField={field.FieldName}
                                caption={field.Caption84}
                                visible={field.IsHide === 0} />
                        )
                    })}
                </Combo>
            </Col>
        );
    }
}

export default compose(connect((state) => ({
    dataGroupList: state.w94F1000.dataGroupList,
}), (dispatch) => ({
    W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch),
})), withStyles(styles, { withTheme: true }))(W94F1002Page);
