/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/20 11:00
 * @update 2019/09/20 11:00
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config/index';
import * as w20f1000Actions from "../../actions/w20/w20f1000-actions";
import * as userActions from "../../actions/user-actions";
import EventTracking from "../common/tracking/event";
import {Form, InputGroup, Tab, Tabs} from "react-bootstrap";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import GridActionBar from "../common/action-bar/grid-action-bar";
import ButtonAdd from "../common/button/button-add";
import InputGroupAddon from "react-bootstrap/es/InputGroupAddon";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import {DateBox, Validator, CheckBox} from "devextreme-react";
import Combo from "../common/dropdown/combo";
import moment from "moment";
import {browserHistory} from "react-router";
import notify from "devextreme/ui/notify";
import * as w19F1031Action from "../../actions/w19/w19F1031/w19f1031-actions";
import {CustomRule} from "devextreme-react/validator";
import Contact from './W20F1000_Contact'
import ButtonExport from "../common/button/button-export";
import ButtonLock from "../common/button/button-lock";
import {Loading} from "../common/loading/loading";
import {Row, Col} from 'diginet-core-ui/components';
import {useTheme} from "diginet-core-ui/theme";

class W20F1000Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPerD17F1922: 0,
            iPerD17F1923: 0,
            iPermission: 0,
            iPermissionLock: 0,
            listCustomerID: [],
            statusID: '',
            key: 1,
            search: '',
            loading: false,
            ddTypeTime: '',
            disabledGrid: false,// disabled 2 lưới không disabled xuất excel trên lưới,
            IsShowDisabled:false,
            foundingDateType: '',
            FoundingDateFrom: null,
            FoundingDateTo: null,
            birthDateType: '',
            BirthDateFrom: null,
            BirthDateTo: null,
            Gender: null,
            dataCboGender: [],
        };
        this.isReload = !!props.location.state;
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 15,
        };
        this.filterDetail = {
            skip: 0,
            limit: 15,
            search: ''
        };
        this.dataMaster = [];
        this.totalMaster = 0;
        this.timer = null;
        this.rowData = null;
        this.refDateFrom = null;
        this.refDateTo = null;
        this.refFoundingDateFrom = null;
        this.refFoundingDateTo = null;
        this.refBirthDateFrom = null;
        this.refBirthDateTo = null;
        this.dataCboTypeTime = [];
        this.contactRef = null;
        this.isSetDefaultBD = false;
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);

        if (url && url.get('listCustomerID')) {
            return {listCustomerID: url.get('listCustomerID') ? JSON.parse(url.get('listCustomerID')) : []};
        } else if (location && location.state) {
            return {
                listCustomerID: location.state.listCustomerID
            }
        } else {
            return false;
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadCbStatus();
        this.loadFilters();
        this.getCboGender();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D17F1920", "D17F1921","D17F1922", "D17F1923"], (arrPer) => {
            if (arrPer) {
                const iPerD17F1920 = arrPer.filter(data => data.ScreenID === 'D17F1920');
                const iPerD17F1921 = arrPer.filter(data => data.ScreenID === 'D17F1921');
                const iPerD17F1922 = arrPer.filter(data => data.ScreenID === 'D17F1922');
                const iPerD17F1923 = arrPer.filter(data => data.ScreenID === 'D17F1923');
                this.setState({
                    iPermission: iPerD17F1920 && iPerD17F1920.length > 0 && iPerD17F1920[0] ? iPerD17F1920[0].Permission : 0,
                    iPermissionLock: iPerD17F1921 && iPerD17F1921.length > 0 && iPerD17F1921[0] ? iPerD17F1921[0].Permission : 0,
                    iPerD17F1922: iPerD17F1922 && iPerD17F1922.length > 0 && iPerD17F1922[0] ? iPerD17F1922[0].Permission : 0,
                    iPerD17F1923: iPerD17F1923 && iPerD17F1923.length > 0 && iPerD17F1923[0] ? iPerD17F1923[0].Permission : 0,
                });
            }
        });
    }

    getCboGender = () => {
        const params = {
            Language: Config.language || '',
            FormID: "W20F1000",
            DataType: "ComboGender",
        }
        this.props.w20f1000Actions.getCboGender(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data) {
                const defaultGender = data.find(item => item?.IsDefault === 1);
                this.setState({ dataCboGender: data, Gender: defaultGender?.GenderID ?? null });
            }
        });
    };

    onToggleLock = (e) => {
        this.setState({
            loading: true
        });
        const param = {
            IsClose: e ? 0 : 1,
            ListCustomerID: this.rowData ? this.rowData.ListCustomerID : null
        };
        w20f1000Actions.updateStatus(param, async () => {
            document.body.click();
            this.setState({
                loading: false
            });
            const title = e ? 'CRM_Mo_chuong_trinh' : 'CRM_Dong_chuong_trinh';
            const paramHistory = {
                codeID: this.rowData && this.rowData.ListCustomerID,
                formID: 'W20F1001',
                linkedTrans: '',
                oldValue: '',
                newValue: '',
                description84: `${Config.lang(title, 'vi').toLowerCase()} ${this.rowData.ListCustomerName.toLowerCase()}`,
                description01: `${Config.lang(title, 'en').toLowerCase()} ${this.rowData.ListCustomerName.toLowerCase()}`,
                action: 1,
            };
            const historyStatus = await this.saveHistory(paramHistory);
            if (historyStatus !== 200) {
                Config.popup.show('INFO', Config.lang('CRM_Co_loi_khi_luu_lich_su'));
                return false;
            }
            notify(`${Config.lang(title)} ${this.rowData.ListCustomerName.toLowerCase()} ${Config.lang('CRM_thanh_cong')}`, "success", 1000);
            this.loadGridMaster(true);
        });
        // this.setState({
        //     disabledGrid: !e
        // });
    };

    loadCbStatus() {
        const uLanguage = Config.language || '';

        this.props.w20f1000Actions.loadCBStatus({}, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === "F1022E002") {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                } else {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.length > 0) {
                let dataF = [];
                data.map((value) => {
                    const data84 = {
                        ID: value.ID,
                        IsDefault: value.IsDefault,
                        Name: uLanguage === '84' ? value.Name84 : value.Name01,
                    };
                    dataF.push(data84);
                    return data84;
                });
                this.setState({
                    dataStatus: dataF,
                });
            } else {
                this.setState({
                    dataStatus: []
                })
            }
        });
    }

    loadGridMaster = (keepSelectedRow) => {
        const {dateFrom, dateTo, statusID ,IsShowDisabled} = this.state;
        const refF = this.refDateFrom?.instance.option('isValid') || true;
        const refT = this.refDateTo?.instance.option('isValid') || true;
        const param = {
            "search": this.state.search,
            "limit": this.filter.limit,
            "skip": this.filter.skip,
            "where": JSON.stringify({
                dateFrom: dateFrom,
                dateTo: dateTo,
                status: statusID,
                IsShowDisabled: IsShowDisabled ? 1 : 0
            }),
        };

        if (refF !== true || refT !== true) return false;
        this.props.w20f1000Actions.loadMaster(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === "F1022E002") {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                } else {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.rows && data.rows.length > 0) {
                this.dataMaster = data.rows;
                this.totalMaster = data.total;
                const state = {};
                if (!keepSelectedRow) {
                    state.listCustomerID = [data.rows[0].ListCustomerID];
                    this.rowData = data.rows[0];
                } else {
                    const rowData = data.rows && data.rows.filter((value) => (value.ListCustomerID === this.state.listCustomerID[0]));
                    this.rowData = rowData.length ? rowData[0] : null;
                }
                state.disabledGrid = this.rowData && this.rowData.IsClose === 1;
                this.setState(state, () => {
                    if (!keepSelectedRow) this.loadGridDetail();
                });
            } else {
                this.dataMaster = [];
                this.totalMaster = 0;
                this.setState({
                    listCustomerID: [],
                    gridDetail: [],
                    totalDetail: 0,
                });
            }
        });
    }

    paramGridDetail = (isContract = false) => {
        const {key, listCustomerID = "", FoundingDateFrom = "", FoundingDateTo = "", BirthDateFrom = "", BirthDateTo = "", Gender = null} = this.state;
        let objParams = {
            FormID: 'W20F1000',
            ListCustomerID: listCustomerID[0] || '',
            DataType: "listcustomer",
            Gender,
            FoundingDateFrom,
            FoundingDateTo,
            BirthDateFrom,
            BirthDateTo
        };
        if (key === 2 || isContract) {
            objParams = {
                ...objParams,
                DataType: "listcontact",
                filter: this.contactRef?.filter || {}
            };
        }
        return objParams;
    };

    loadGridDetail() {
        const {key} = this.state;
        const refFdf = this.refFoundingDateFrom?.instance.option('isValid') || true;
        const refFdt = this.refFoundingDateTo?.instance.option('isValid') || true;
        const refBDf = this.refBirthDateFrom?.instance.option('isValid') || true;
        const refBDt = this.refBirthDateTo?.instance.option('isValid') || true;
        if (key === 1 ? (!refFdf || !refFdt) : key === 2 ? (!refBDf || !refBDt) : false ) return false;
        const param = {
            ...this.paramGridDetail(),
            search: this.filterDetail.search,
            limit: this.filterDetail.limit,
            skip: this.filterDetail.skip,
        };
        this.props.w20f1000Actions.loadDetail(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === "F1022E002") {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                } else {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.rows && data.rows.length > 0) {
                if (this.state.totalDetail !== data.total && this.filterDetail.skip !== 0) {
                    this.filterDetail.skip = 0;
                    return this.setState({
                        totalDetail: data.total
                    }, () => this.loadGridDetail());
                }
                this.setState({
                    gridDetail: data.rows,
                    totalDetail: data.total
                })
            } else {
                this.filterDetail.skip = 0;
                this.setState({
                    gridDetail: [],
                    totalDetail: 0
                });
            }
        });
    }

    onRowChanged(row) {
        this.rowData = row.selectedRowsData[0];
        const state = {
            listCustomerID: row.selectedRowKeys
        };
        browserHistory.push({
            pathname: Config.getRootPath() + 'W20F1000',
            state: {listCustomerID: row.selectedRowKeys}
        });
        state.disabledGrid = this.rowData && this.rowData.IsClose === 1;
        this.setState(state, () => {
            this.loadGridDetail();
        });
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGridMaster();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGridMaster();
    }

    onSearch = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.loadGridMaster();
        }, 700);
    };

    onChangePageDetail(page) {
        this.filterDetail.skip = page * this.filterDetail.limit;
        this.loadGridDetail();
    }

    onChangePerPageDetail(per) {
        this.filterDetail.skip = 0;
        this.filterDetail.limit = per;
        this.loadGridDetail();
    }

    onSearchDetail(text) {
        this.filterDetail.search = text;
        this.loadGridDetail();
    }

    saveHistory = async (param) => {
        let status = 200;
        let el = param ? param : {};
        if (!param) {
            el.codeID = this.rowData && this.rowData.ListCustomerID;
            el.formID = 'W20F1000';
            el.linkedTrans = '';
            el.oldValue = '';
            el.newValue = this.rowData && this.rowData.ListCustomerName;
            el.description84 = Config.lang("CRM_Danh_sach_khach_hang", 'vi');
            el.description01 = Config.lang("CRM_Danh_sach_khach_hang", 'en');
            el.action = 2;
        }
        if (Object.keys(el).length) {
            const param = {
                attributes: JSON.stringify([el])
            };

            await this.props.w19F1031Action.addHistory(param, (error) => {
                if (error) status = 400;
            });
        }
        return status
    };

    deleteRow = (e) => {
        if (!e) return false;
        const data = e.row.data;
        if (!e.row || !data.ListCustomerID) return false;
        const param = {
            listCustomerID: data.ListCustomerID
        };

        Config.popup.show("YES_NO", Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            this.props.w20f1000Actions.deleteRow(param, async (error) => {
                if (error) {
                    if (error.code === 'F1000E006') {
                        Config.popup.show("INFO", Config.lang('CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa'));
                        return false;
                    } else {
                        Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_xoa'));
                        return false;
                    }
                }
                const historyStatus = await this.saveHistory();
                if (historyStatus !== 200) {
                    Config.popup.show('INFO', Config.lang('CRM_Co_loi_khi_luu_lich_su'));
                    return false;
                }
                this.loadGridDetail();
                this.loadGridMaster();
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 300);

            })
        })
    };

    renderAction(e) {
        if (!e) return null;
        const {data} = e;
        const {Gender = null, FoundingDateFrom = "", FoundingDateTo = "", BirthDateFrom = "", BirthDateTo = ""} = this.state;
        const param = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || ''},
            {type: "NVarchar", id: "ListCustomerID", value: data.ListCustomerID}
        ];
        const paramSlave1 = [
            ...param,
            {type: "Varchar", id: "ReportTypeID", value: 'W20F1001'},
            {type: "NVarchar", id: "search", value: this.filterDetail.search},
            {type: "TINYINT", id: "Gender", value: null},
            {type: "DATETIME", id: "FoundingDateFrom", value: FoundingDateFrom},
            {type: "DATETIME", id: "FoundingDateTo", value: FoundingDateTo},
            {type: "DATETIME", id: "BirthDateFrom", value: ""},
            {type: "DATETIME", id: "BirthDateTo", value: ""}
        ];
        const paramSlave2 = [
            ...param,
            {type: "Varchar", id: "ReportTypeID", value: 'W20F1002'},
            {type: "NVarchar", id: "search", value: this.contactRef?.filter?.search || ""},
            {type: "TINYINT", id: "Gender", value: Gender},
            {type: "DATETIME", id: "FoundingDateFrom", value: ""},
            {type: "DATETIME", id: "FoundingDateTo", value: ""},
            {type: "DATETIME", id: "BirthDateFrom", value: BirthDateFrom},
            {type: "DATETIME", id: "BirthDateTo", value: BirthDateTo},
        ];
        const {iPermission} = this.state;
        const isPer = data.IsClose === 1 ? 0 : iPermission;

        let btnHamburger = [
            {
                name: Config.lang("Xem"),
                icon: 'fas fa-eye text-blue',
                onClick: () => iPermission >= 1 && this.redirect(e, 'view', 'W20F1001')
            },
            {
                type: 'buttonExport',
                component: <ButtonExport className={'btn-export'}
                                         reportTypeID={'W20F1001'}
                                         name={Config.lang('CRM_Xuat_danh_sach_khach_hang')}
                                         btnExcel={{
                                             name: Config.lang('CRM_Xuat_danh_sach_khach_hang'),
                                         }}
                                         id={'slave1'}
                                         param={paramSlave1}
                                         hideButton={'pdf'}/>
            },
            {
                type: 'buttonExport',
                component: <ButtonExport className={'btn-export'}
                                         reportTypeID={'W20F1002'}
                                         name={Config.lang('CRM_Xuat_danh_sach_lien_he')}
                                         param={paramSlave2}
                                         id={'slave2'}
                                         btnExcel={{
                                             name: Config.lang('CRM_Xuat_danh_sach_lien_he'),
                                         }}
                                         hideButton={'pdf'}/>

            },
        ];
        if (this.state.iPermissionLock) {
            btnHamburger = [...btnHamburger, {
                component: <ButtonLock defaultValue={data.IsClose === 0}
                                       onValueChange={this.onToggleLock}/>
            }];
        }
        btnHamburger.push({
                name: Config.lang('CRM_Sao_chep_chuong_trinh'),
                icon: 'fas fa-copy text-yellow',
                disabled: this.state.iPermission < 2,
                className: this.state.iPermission < 2 ? 'isDisabled' : '',
                iPer: this.state.iPermission < 0,
                onClick: () => {
                    this.onCopy(data)
                }
        });

        return (
            <GridActionBar
                isPer={isPer}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.redirect(e, 'edit', 'W20F1001')
                }}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => this.deleteRow(e)}
                btnHamburger={btnHamburger}
            />
        );
    }

    redirect(e, mode, form) {
        let state = {};
        let data;
        state.mode = mode;
        const stateCache = Object.assign({}, this.state);
        delete stateCache.iPermission;
        let storeData = {
            ...stateCache,
            filter: this.filter,
        };
        if(mode === 'view'){
            storeData.dataMaster = this.dataMaster;
            storeData.totalMaster =  this.totalMaster;
        }

        Config.setStoreState(this, storeData);
        if (e) data = e.row.data;
        if (form === 'W20F1001' && mode !== 'add') {
            state.id = data.ListCustomerID;
        } else if (form === 'W18F1012') {
            state.type = 'Customers';
            state.CompanyID = data.CompanyID;
            state.Permission = data.Permission;

        } else {
            state.id = this.rowData && this.rowData.ListCustomerID
        }
        browserHistory.push({
            pathname: Config.getRootPath() + form,
            state: state
        });
    };

    renderRegionNameU(data, form) {
        // if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.redirect(data, 'view', form)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    handleSelect(key) {
        this.setState({key: key})
    }

    onChangeComboStatus(data) {
        this.setState({
            statusID: data && data.ID ? data.ID : ''
        }, () => {
            this.loadGridMaster()
        });
    }

    loadGridContract() {
        if (this.contactRef?.loadGrid) this.contactRef.loadGrid();
    };

    onChangeComboGender(data) {
        this.setState({
            Gender: data?.GenderID ?? null
        }, () => {
            this.loadGridContract();
        });
    }

    onChangeTimeStart(data, mode) {
        if (!data) return false;
        const param = {
            ddTypeTime: data.ID,
            dateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            dateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        };
        const {dateFrom, dateTo} = this.state;

        if (mode === 'init' && dateFrom && dateTo) return;
        this.setState(param, () => {
            this.loadGridMaster()
        });
    }

    onChangeFoundingDate(data, mode) {
        if (!data) return false;
        const {FoundingDateFrom, FoundingDateTo} = this.state;
        if (mode === 'init' && FoundingDateFrom && FoundingDateTo) return;
        this.setState({
            foundingDateType: data.ID,
            FoundingDateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            FoundingDateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        }, () => {
            this.loadGridDetail();
        });
    };

    onChangeBirthDate(data, mode) {
        if (!data) return false;
        const {BirthDateFrom, BirthDateTo} = this.state;
        if (mode === 'init' && BirthDateFrom && BirthDateTo) return;
        this.setState({
            birthDateType: data.ID,
            BirthDateFrom: data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null,
            BirthDateTo: data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null
        }, () => {
            this.loadGridContract();
        });
    };

    renderActionSlave = (rowData) => {
        if (!rowData || !rowData.data) return null;
        const {disabledGrid, iPerD17F1922} = this.state;
        const isPer = disabledGrid ? 0 : iPerD17F1922;
        return (
            <GridActionBar
                isPer={isPer}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    const {data} = rowData;
                    this.deleteRowCustomer(data)
                }}
            />
        )
    };

    deleteRowCustomer = (data) => {
        const {listCustomerID} = this.state;
        if (!data || !data.CompanyID || !listCustomerID) return false;
        Config.popup.show('YES_NO', Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            const param = {
                ListCustomerID: listCustomerID[0],
                CompanyID: data.CompanyID
            };
            this.props.w20f1000Actions.deleteRowCustomer(param, async (error) => {
                if (error) {
                    Config.popup.show('INFO', Config.lang('CRM_Co_loi_khi'));
                    return false;
                }
                else{
                    const paramHistory = {
                        codeID: this.rowData && this.rowData.ListCustomerID,
                        formID: 'W20F1001',
                        linkedTrans: '',
                        oldValue: '',
                        newValue: '',
                        hasURL:false,
                        description84: `${Config.lang('CRM_Khach_hang', 'vi')} ${data.CompanyNameU}`,
                        description01: `${Config.lang('CRM_Khach_hang', 'en')} ${data.CompanyNameU}`,
                        action: 2,
                    };
                    const historyStatus = await this.saveHistory(paramHistory);
                    if (historyStatus !== 200) {
                        Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                        return false;
                    }
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGridDetail();
                }
            })
        })
    };

    loadFilters(){
        const {listCustomerID} = this.getInfo();
        const filter = Config.getStoreState(this, ()=>{
            if(listCustomerID) {
                this.loadGridMaster(true);
            }
            else this.loadGridMaster();
        });
        if (filter && filter.totalMaster && filter.dataMaster) {
            this.totalMaster = filter.totalMaster;
            this.dataMaster = filter.dataMaster;
        }

}

    setSearch = e => {
        this.setState({
            search: e.target.value
        });
    };

    validateData = (e, compareValue, mode) => {
        e.rule.message = '';
        if (!e.value) {
            e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
            return false;
        }
        if (mode === 1) {
            e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
            return e.value <= compareValue;
        } else {
            e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
            return e.value >= compareValue;
        }
    };

    onCopy = (dataCopy) => {
        w20f1000Actions.copyData({ListCustomerID: dataCopy.ListCustomerID}, (error, data) => {
            if(data){
                this.loadGridDetail();
                this.loadGridMaster();
            } else{
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO',message);
                return false;
            }
        });
    };

    render() {
        const {iPermission,iPerD17F1922, iPerD17F1923, search, ddTypeTime, listCustomerID, dateFrom, dateTo, statusID, loading, disabledGrid,
        foundingDateType, FoundingDateFrom, FoundingDateTo, Gender, birthDateType, BirthDateFrom, BirthDateTo, dataCboGender} = this.state;
        const {spacing} = useTheme();
        const printParam = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || ''},
            {type: "Varchar", id: "StatusListCustomer", value: statusID},
            {type: "DATETIME", id: "DateFrom", value: dateFrom},
            {type: "DATETIME", id: "DateTo", value: dateTo},
        ];
        const listType = "W20F1000_TypeTime";

        return (
            <div id={'frmW20F1000'} className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W20F1000"} label={Config.profile.UserID}/>}
                <div className="grid-title">{Config.lang("CRM_Danh_sach_khach_hang")}</div>
                {loading && <Loading/>}
                <Form horizontal={true}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className={"display_row"}>
                                <ButtonAdd disabled={iPermission < 2}
                                    className="mgr5"
                                    name={Config.lang("CRM_Them_moi1")}
                                    onClick={() => this.redirect(null, 'add', 'W20F1001')} />
                                <InputGroup style={{ width: '100%' }}>
                                    <input id={'search-user-profile'} autoComplete={'off'}
                                        value={search}
                                        placeholder={Config.lang("CRM_Nhap_noi_dung_tim_kiem")}
                                        onChange={this.setSearch}
                                        onKeyUp={this.onSearch}
                                        className="form-control" />
                                    <InputGroupAddon id={'search-button-user-profile'}>
                                        <i className="fas fa-search" />
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} lg={2} md={3}>
                            <DropDownTypeTime
                                listType={listType}
                                value={ddTypeTime}
                                handleDataSource={(e) => this.dataCboTypeTime = e}
                                dataSource={this.dataCboTypeTime}
                                placeholder={Config.lang("Chon")}
                                onChange={(data, mode) => this.onChangeTimeStart(data, mode)}
                            />
                        </Col>
                        <Col xs={12} sm={12} lg={2} md={3}>
                            <DateBox
                                width={"100%"}
                                ref={e => this.refDateFrom = e}
                                showClearButton={true}
                                dateSerializationFormat={'yyyy-MM-dd'}
                                placeholder={Config.lang("CRM_Tu_ngay")}
                                id={'CreateDateFrom'}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                value={this.state.dateFrom}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {
                                    this.setState({
                                        dateFrom: e.value
                                    }, () => {
                                        this.loadGridMaster()
                                    })
                                }}
                            >
                                <Validator>
                                    <CustomRule
                                        validationCallback={(e) => this.validateData(e, this.state.dateTo, 1)}/>
                                </Validator>
                            </DateBox>
                        </Col>
                        <Col xs={12} sm={12} lg={2} md={3}>
                            <DateBox
                                width={"100%"}
                                ref={e => this.refDateTo = e}
                                showClearButton={true}
                                dateSerializationFormat={'yyyy-MM-dd'}
                                placeholder={Config.lang("CRM_Den_ngay")}
                                id={'CreateDateTo'}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                value={this.state.dateTo}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {
                                    this.setState({
                                        dateTo: e.value
                                    }, () => {
                                        this.loadGridMaster()
                                    })
                                }}
                            >
                                <Validator>
                                    <CustomRule
                                        validationCallback={(e) => this.validateData(e, this.state.dateFrom, 2)}/>
                                </Validator>
                            </DateBox>
                        </Col>
                        <Col xs={12} sm={12} lg={2} md={3}>
                            <Combo
                                id={'ID'}
                                showHeader={false}
                                showClearButton={true}
                                dataSource={this.state.dataStatus}
                                placeholder={Config.lang("CRM_Trang_thai")}
                                value={this.state.statusID}
                                valueExpr="ID"
                                displayExpr="Name"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboStatus(data)
                                }}
                            >
                                <Column dataField={'ID'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'Name'} caption="" dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col md={6}>
                            <CheckBox
                                value={this.state.IsShowDisabled}
                                onValueChanged={(e) => {
                                    this.setState({ IsShowDisabled: e.value }, () => {
                                        this.loadGridMaster();
                                    });
                                }}
                                text={Config.lang('Hien_thi_danh_sach_khong_su_dung')} />
                        </Col>
                        <Col md={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonExport name={Config.lang('CRM_Xuat_du_lieu')}
                                    reportTypeID={'W20F1000'}
                                    param={printParam}
                                    hideButton={"pdf"} />
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <GridContainer
                                title={""}
                                dataSource={this.dataMaster}
                                keyExpr={'ListCustomerID'}
                                selectedRowKey={this.state.listCustomerID}
                                showBorders={false}
                                showColumnLines={true}
                                // selection={{ mode: 'single' }}
                                allowColumnReordering={true}
                                rowAlternationEnabled={true}
                                totalItems={this.totalMaster}
                                itemPerPage={this.filter.limit}
                                typeShort={true}
                                listPerPage={[15, 20, 30, 50]}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}
                                onSelectionChanged={(row) => {
                                    this.onRowChanged(row)
                                }}
                            >
                                <Column
                                    cellRender={(data) => this.renderAction(data)}
                                    dataField={'Action'}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={120}
                                    fixed={true}
                                />
                                <Column dataField={'ListCustomerName'}
                                        caption={Config.lang("CRM_Ten_danh_sach")}
                                        // cellRender={(data) => this.renderRegionNameU(data, 'W20F1001')}
                                />
                                <Column dataField={'Status'}
                                        caption={Config.lang("CRM_Trang_thai")}
                                />
                                <Column dataField={'DateFrom'}
                                        caption={Config.lang("CRM_Ngay_bat_dau")}
                                        width={150}
                                        alignment={'center'}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'DateTo'}
                                        caption={Config.lang("CRM_Ngay_ket_thuc")}
                                        alignment={'center'}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'Content'}
                                        caption={Config.lang("CRM_Noi_dung")}
                                />
                                <Column dataField={'CreateDate'}
                                        caption={Config.lang("CRM_Ngay_tao")}
                                        alignment={'center'}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'CreateUserName'}
                                        caption={Config.lang("CRM_Nguoi_tao")}
                                />
                                <Column dataField={'LastModifyDate'}
                                        caption={Config.lang("CRM_Ngay_cap_nhat")}
                                        alignment={'center'}
                                        width={150}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'LastModifyUserName'}
                                        alignment={'left'}
                                        caption={Config.lang("CRM_Nguoi_cap_nhat")}
                                />
                                <Column dataField={'ListCustomerID'}
                                        caption={Config.lang("CRM_Ma_danh_sach")}
                                />
                            </GridContainer>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Tabs
                                id="tabMain"
                                activeKey={this.state.key}
                                animation={true}
                                unmountOnExit={true}
                                mountOnEnter={true}
                                onSelect={(key) => this.handleSelect(key)}
                            >
                                {/*{key > 0 &&*/}
                                <Tab eventKey={1}
                                     title={Config.lang("CRM_Khach_hang")}>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <div className={"display_row align-center"}>
                                                <label style={{ margin: spacing([0, 4, 0, 0]), whiteSpace: 'nowrap' }}>{Config.lang("Ngay_thanh_lap")}</label>
                                                <div style={{ width: '100%' }}>
                                                    <DropDownTypeTime
                                                        listType={listType}
                                                        handleDataSource={(e) => this.dataCboTypeTime = e}
                                                        dataSource={this.dataCboTypeTime}
                                                        onLoad={(data) => {
                                                            if (data && !this.isSetDefaultBD) { //Set default cho DD Sinh nhật (Tab liên hệ)
                                                                this.isSetDefaultBD = true;
                                                                this.onChangeBirthDate(data); 
                                                            }
                                                        }}
                                                        value={foundingDateType}
                                                        placeholder={Config.lang("Chon")}
                                                        onChange={(data, mode) => this.onChangeFoundingDate(data, mode)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} lg={3}>
                                            <DateBox
                                                width={"100%"}
                                                ref={e => this.refFoundingDateFrom = e}
                                                showClearButton={true}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                placeholder={Config.lang("Tu_ngay")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                value={FoundingDateFrom}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        FoundingDateFrom: e.value
                                                    }, () => {
                                                        this.loadGridDetail()
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CustomRule
                                                        validationCallback={(e) => this.validateData(e, FoundingDateTo, 1)} />
                                                </Validator>
                                            </DateBox>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} lg={3}>
                                            <DateBox
                                                width={"100%"}
                                                ref={e => this.refFoundingDateTo = e}
                                                showClearButton={true}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                placeholder={Config.lang("Den_ngay")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                value={FoundingDateTo}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        FoundingDateTo: e.value
                                                    }, () => {
                                                        this.loadGridDetail()
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CustomRule
                                                        validationCallback={(e) => this.validateData(e, FoundingDateFrom, 2)} />
                                                </Validator>
                                            </DateBox>
                                        </Col>
                                        <Col xs={12}>
                                    <GridContainer
                                        allowAdding={!disabledGrid && iPerD17F1922 > 1 ? 5 : 0}
                                        title={""}
                                        onAddNew={this.rowData && iPerD17F1922 > 1 ? () => this.redirect(null, 'add', 'W20F1002') : null}
                                        isPer={this.state.iPermission}
                                        dataSource={this.state.gridDetail}
                                        keyExpr={'CompanyID'}
                                        showBorders={false}
                                        showColumnLines={true}
                                        allowColumnReordering={true}
                                        totalItems={this.state.totalDetail}
                                        itemPerPage={this.filter.limit}
                                        valueSearch={this.filterDetail.search}
                                        listPerPage={[15, 20, 30, 50]}
                                        onSearch={(text) => {
                                            this.onSearchDetail(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageDetail(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageDetail(per)
                                        }}
                                    >
                                        <Column
                                            cellRender={this.renderActionSlave}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            fixed={true}
                                            width={90}
                                        />
                                        <Column dataField={'CompanyNameU'}
                                                cellRender={(data) => this.renderRegionNameU(data, 'W18F1012')}
                                                caption={Config.lang("CRM_Ten_khach_hang")}
                                        />
                                        <Column dataField={'AddressLineU'}
                                                caption={Config.lang("CRM_Dia_chi")}
                                        />
                                        <Column dataField={'TelePhone'}
                                                caption={Config.lang("CRM_Dien_thoai")}
                                        />
                                        <Column dataField={'Email'}
                                                caption={'Email'}
                                        />
                                        <Column dataField={'Website'}
                                                caption={'Website'}
                                        />
                                        <Column dataField={'SalesPersonNameU'}
                                                caption={Config.lang("CRM_Nhan_vien")}
                                        />
                                        <Column dataField={'CompanyKindName'}
                                                caption={Config.lang("CRM_Phan_loai_khach_hang")}
                                        />
                                        <Column dataField={'CompanyStatusName'}
                                                caption={Config.lang("CRM_Trang_thai")}
                                        />
                                        <Column dataField={'IndustryGroupName'}
                                                caption={Config.lang("CRM_Nhom_nganh_nghe")}
                                        />
                                        <Column dataField={'CompanySizeName'}
                                                caption={Config.lang("CRM_Quy_mo_cong_ty")}
                                        />
                                        <Column dataField={'CompanyGroupName'}
                                                caption={Config.lang("CRM_Nhom_khach_hang")}
                                        />
                                        <Column dataField={'CompanyCreateDate'}
                                                caption={Config.lang("CRM_Ngay_tao")}
                                                width={150}
                                                alignment={'center'}
                                                dataType={'date'}
                                                format={'dd-MM-yyyy'}
                                        />
                                        <Column dataField={'DateOfBirth'}
                                                caption={Config.lang("CRM_Ngay_thanh_lap")}
                                                width={150}
                                                alignment={'center'}
                                                dataType={'date'}
                                                format={'dd-MM-yyyy'}
                                        />
                                        <Column dataField={'TaxNo'}
                                                caption={Config.lang("CRM_Ma_so_thue")}
                                        />
                                        <Column dataField={'DistrictName'}
                                                caption={Config.lang("CRM_Quan_Huyen")}
                                        />
                                        <Column dataField={'ProvinceName'}
                                                caption={Config.lang("CRM_Tinh_Thanh_pho")}
                                        />
                                        <Column dataField={'CreateDate'}
                                                caption={Config.lang("CRM_Ngay_them")}
                                                width={150}
                                                alignment={'center'}
                                                dataType={'date'}
                                                format={'dd-MM-yyyy'}
                                        />
                                        <Column dataField={'CreateUserName'}
                                                caption={Config.lang("CRM_Nguoi_them")}
                                        />
                                        <Column dataField={'CompanyID'}
                                                caption={Config.lang("CRM_Ma_khach_hang")}
                                        />
                                    </GridContainer>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey={2} title={Config.lang("CRM_Lien_he")}>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <div className={"display_row align-center"}>
                                                <label style={{ margin: spacing([0, 4, 0, 0]), whiteSpace: 'nowrap' }}>{Config.lang("Gioi_tinh")}</label>
                                                <Combo
                                                    virtual
                                                    showHeader={false}
                                                    showClearButton={true}
                                                    dataSource={dataCboGender}
                                                    value={Gender}
                                                    valueExpr={"GenderID"}
                                                    displayExpr={"GenderName"}
                                                    placeholder={Config.lang("Chon")}
                                                    onActionWhenSelectChange={(data) => {
                                                        this.onChangeComboGender(data)
                                                    }}
                                                >
                                                    <Column dataField={'GenderID'} caption="" width={'100px'} visible={false}
                                                        dataType={'number'} />
                                                    <Column dataField={'GenderName'} caption="" dataType={'string'} />
                                                </Combo>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <div className={"display_row align-center"}>
                                                <label style={{ margin: spacing([0, 4, 0, 0]), whiteSpace: 'nowrap' }}>{Config.lang("Sinh_nhat")}</label>
                                                <div style={{ width: '100%' }}>
                                                    <DropDownTypeTime
                                                        listType={listType}
                                                        handleDataSource={(e) => this.dataCboTypeTime = e}
                                                        dataSource={this.dataCboTypeTime}
                                                        value={birthDateType}
                                                        valueFromTo={{BirthDateFrom, BirthDateTo}}
                                                        placeholder={Config.lang("Chon")}
                                                        onChange={(data, mode) => this.onChangeBirthDate(data, mode)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={2} lg={2}>
                                            <DateBox
                                                width={"100%"}
                                                ref={e => this.refBirthDateFrom = e}
                                                showClearButton={true}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                placeholder={Config.lang("Tu_ngay")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                value={BirthDateFrom}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        BirthDateFrom: e.value
                                                    }, () => {
                                                        this.loadGridContract()
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CustomRule
                                                        validationCallback={(e) => this.validateData(e, BirthDateTo, 1)} />
                                                </Validator>
                                            </DateBox>
                                        </Col>
                                        <Col xs={12} sm={12} md={2} lg={2}>
                                            <DateBox
                                                width={"100%"}
                                                ref={e => this.refBirthDateTo = e}
                                                showClearButton={true}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                placeholder={Config.lang("Den_ngay")}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                value={BirthDateTo}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        BirthDateTo: e.value
                                                    }, () => {
                                                        this.loadGridContract()
                                                    })
                                                }}
                                            >
                                                <Validator>
                                                    <CustomRule
                                                        validationCallback={(e) => this.validateData(e, BirthDateFrom, 2)} />
                                                </Validator>
                                            </DateBox>
                                        </Col>
                                        <Col>
                                    <Contact
                                        onRef={e => this.contactRef = e}
                                        onAddNew={listCustomerID.length && iPerD17F1923 > 1 ? () => this.redirect(null, 'add', 'W20F1003') : null}
                                        listCustomerID={listCustomerID[0]}
                                        paramGridDetail={this.paramGridDetail(true)}
                                        permission={disabledGrid ? 0 :iPerD17F1923}/>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}


export default connect(state => ({
        loadCBStatus: state.w20f1000.loadCBStatus,
        loadMaster: state.w20f1000.loadMaster,
        loadTotal: state.w20f1000.loadTotal,
        loadDetail: state.w20f1000.loadDetail,
    }),
    (dispatch) => ({
        w20f1000Actions: bindActionCreators(w20f1000Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        w19F1031Action: bindActionCreators(w19F1031Action, dispatch),

    })
)(W20F1000Page);
