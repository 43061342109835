/**
 * @copyright 2020 @ DigiNet
 * @author TRANGHOANG
 * @create 2020/04/16 10:37
 */
import {Loading} from "../common/loading/loading";
import React from 'react';
import {Col, Collapse, Form, FormGroup, Row,} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import {Column, Lookup, Paging, Summary, TotalItem} from "devextreme-react/data-grid";
import {DateBox} from 'devextreme-react';
import GridActionBar from "../common/action-bar/grid-action-bar";
import InputGrid from "../common/inputGrid/inputGrid";
import ButtonSave from "../common/button/button-save";
import ButtonCustom from "../common/button/button-custom";
import PopUp from "./W19F2001_Popup";
import notify from "devextreme/ui/notify";
import W18F1015PopUp from "../W18/W18F1015";
import Combo from "../common/dropdown/combo";
import CustomStore from "devextreme/data/custom_store";
import getCurrency from "../../actions/w19/w19F2001/cbocurrency-async";
import getPriceList from "../../actions/w19/w19F2001/cbopriceList-async";
import getPaymentTerm from "../../actions/w19/w19F2001/cbopaymentTerm-async";
import getInventory from "../../actions/w19/w19F2001/cboinventory-async";
import getPaymentMethod from "../../actions/w19/w19F2001/cbopaymentMethod-async";
import getBasePrice from "../../actions/async/w19f2001/get-base-price-async";
import getCbounit from "../../actions/w19/w19F2001/cbounit-async";
import W18F1019Pop from "../W18/W18F1019"
import W19F2001Close from "./W19F2001_Close"
import * as w19F2001Actions from "../../actions/w19/w19F2001/w19F2001-actions";
import * as userActions from "../../actions/user-actions";
import {browserHistory} from "react-router";
import * as w18F1015Actions from "../../actions/w18F1015-actions";
import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

const currentTime = {
    hour: moment().get('hour'),
    minute: moment().get('minute'),
    second: moment().get('second'),
    millisecond: moment().get('millisecond')
};

const currencyData = {
    store: new CustomStore({
        key: "CurrencyID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "select": 'CurrencyID, CurrencyNameU,ExchangeRate,OriginalDecimal',
                "sort": 'CurrencyID',
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify(
                    {
                        fieldName: 'CurrencyID',
                        operator: 'LIKE',
                        value: loadOptions.filter.filterValue
                    },
                );
            }
            const data = await getCurrency(param);
            return {
                'data': data.rows,
                'totalCount': data.total
            };
        }
        ,
        byKey: async (key) => {
            const param = {
                where: JSON.stringify(
                    {
                        fieldName: 'CurrencyID',
                        operator: 'LIKE',
                        value: key
                    },
                )
            };
            const data = await getCurrency(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        },
    })
};
const priceListData = {
    store: new CustomStore({
        key: "PriceListID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify({
                    fieldName: 'PriceListNameU',
                    operator: 'LIKE',
                    value: loadOptions.filter.filterValue,
                })
            }
            const data = await getPriceList(param);
            return {
                'data': data.rows,
                'totalCount': data.total
            };
        },
        byKey: async (key) => {
            const param = {
                where: JSON.stringify({
                    fieldName: 'D14T1110.PriceListID',
                    operator: '=',
                    value: key,
                })
            };
            const data = await getPriceList(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};
const paymentTermData = {
    store: new CustomStore({
        key: "PaymentTermID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify({
                    fieldName: "PaymentTermNameU",
                    operator: "LIKE",
                    value: loadOptions.filter.filterValue
                })
            }
            const data = await getPaymentTerm(param);
            return {
                'data': data.rows,
                'totalCount': data.total
            };
        },
        byKey: async (key) => {
            const param = {
                where: JSON.stringify({
                    fieldName: "PaymentTermID",
                    operator: "=",
                    value: key
                })
            };
            const data = await getPaymentTerm(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};

const paymentMethodData = {
    store: new CustomStore({
        key: "PaymentMethodID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify({
                    fieldName: "PaymentMethodNameU",
                    operator: "LIKE",
                    value: loadOptions.filter.filterValue
                })
            }
            const data = await getPaymentMethod(param);
            return {
                'data': data.rows,
                'totalCount': data.total
            };
        },
        byKey: async (key) => {
            const param = {
                where: JSON.stringify({
                    fieldName: "PaymentMethodID",
                    operator: "=",
                    value: key
                })
            };
            const data = await getPaymentMethod(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};

class W19F2001Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        let {mode} = this.getInfo();
        this.division = '';
        this.quotationID = '';
        this.exchangeRate = '';// chứa tỷ giá gốc chưa format
        this.fromDate = null;
        this.toggleSummary = false;
        this.state = {
            approveUserName: mode === 'add' ? Config.profile.SalesPersonNameU : '',
            loading: false,
            showPopup: false,
            showPopupPrint: false,
            showPopupClose: false,
            collapse: true,
            showDoc: false,
            dataGrid: [],// grid tam chi chua nhung dong duoc add vao
            showW18F1015: false,
            customerID: '',
            customerName: '',
            address: '',
            currency: '',
            total: 50,
            priceList: '',
            paymentTerm: '',
            shipAdressData: '',
            shipAdressID: '',
            shipAddressName: '',
            inventory: '',
            unit: '',
            caption: [],
            quantity: '',
            paymentMethod: '',
            toggleColumn: '',
            baseCurrencyID: '',
            showColumn: false,
            cboContact: [],
            validTo: null,
            //validFrom không hiển thị giờ nhưng lưu giờ hiện tại
            validFrom: mode === 'add' ? moment().format() : null,
            showW18F1019: false,
            status: false,
            showShare: mode === 'edit',// hien chia se khach hang
            showPass: false,//  ẩn hiện duyệt
            iPerD17F1611: 0,
            perApprove: 0,
            approveNote: '',
            aStatus: 0,
            approveDate: '',
            approveUser: '',
            telephone: null,
            contactPerson: '',
            saveSuccess: false, // lưu thành công disabled Form
            objectID: '',
            isFocus: false,
            dataInventory: [],
            collapse1: false, // ẩn hiện master input động,
            checkDref5: '',
            cboDref5: null,
            dateQuote: '',
            nameQuote: '',
            disabledQuote: true,
        };
        this.timer = null;
        this.totalInventory = 0;
        this.changeDecimal = false;
        this.dataFilterInventory = {
            limit: 50,
            skip: 0,
        };
        this.cbo_Company = '';
        this.init = false;
        this.dataCellChanged = {};
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D17F1621", "D17F1612", "D17F5625"], (arrPer) => {
            if (arrPer !== 0) {
                const iPerD17F1611 = arrPer.filter(data => data.ScreenID === 'D17F1621');
                const iPerD17F5625 = arrPer.filter(data => data.ScreenID === 'D17F5625');
                this.setState({
                    iPerD17F1611: iPerD17F1611 && iPerD17F1611[0] ? iPerD17F1611[0].Permission : 0,
                    iPerD17F5625: iPerD17F5625 && iPerD17F5625[0] ? iPerD17F5625[0].Permission : 0,
                });
            }
        });
    }

    getCbodref5() {
        this.props.w19F2001Actions.getCboDref5((error, data) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('Co_loi_trong_qu_trinh_xu_ly_du_lieu'));
                return false;
            }
            let arr = [];
            if (data && data.data) {
                data.data.forEach((value) => {
                    arr.push(value.ScreenName);
                });
                this.setState({
                    cboDref5: arr
                })
            }
        })
    }

    componentWillMount() {
        //Life cycle unsanfe
        if (!this.getInfo()) return;
        this.checkDref5();
        this.loadCaption();
        this.getExtraInfo();
        this.getBaseCurrencyID();
        this.getDataStatus();
    }

    checkDref5() {
        // Check DRef5U is dropdown or textbox
        this.props.w19F2001Actions.checkDRef5U((error, data) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('Co_loi_trong_qu_trinh_xu_ly_du_lieu'));
                return false;
            }
            if (data && data.data) {
                if (data.data.type === 'dropdown')
                    this.getCbodref5();
                this.setState({
                    checkDref5: data.data.type
                })
            }
        })

    }

    componentWillUpdate(nextProps, nextState) {
        if ((JSON.stringify(nextProps.dataShare) !== JSON.stringify(this.props.dataShare)) && this.props.dataShare) {
            let objDiv = document.getElementById("shareContainerW19F2001");
            if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        await this.loadPermission();
        await this.loadToggleColumn();
        this.getNumValue();

        const {mode, CompanyID} = this.getInfo();
        if (mode !== 'add') {
            this.loadDataMaster();
            this.getPerApprove();
        } else if (CompanyID) {
            this.getCustomer();
        }
    };

    getExtraInfo() {
        this.props.w19F2001Actions.getExtraInfo((error) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('Co_loi_trong_qu_trinh_xu_ly_du_lieu'));
                return false;
            }
        })
    }

    getDataStatus() {
        this.props.w19F2001Actions.loadDataStatus((error) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
        })
    }

    getBaseCurrencyID() {
        this.props.w19F2001Actions.getBaseCurrency((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length === 0) {
            } else if (data && data.length > 0 && data[0].BaseCurrencyID) {
                this.setState({
                    baseCurrencyID: data[0].BaseCurrencyID
                })
            }
        })
    }

    async loadToggleColumn() {
        await this.props.w19F2001Actions.loadToggleColumn((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                toggleColumn: data,
            })

        })
    }

    loadCaption(cb) {
        // Do nguon ten va datafield cot dong
        this.props.w19F2001Actions.loadCaption((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                caption: data
            });
            this.toggleSummary = true;
            if (cb) {
                cb();
            }
        })
    }

    getDefaultVRef = async (inventoryID) => {
        const param = {
            InventoryID: inventoryID
        };
        let data = '';
        await this.props.w19F2001Actions.getDefaultVRef5(param, (error, res) => {
            if (res.code === 200) {
                data = res.data.Str05U
            }
            data = res
        });
        return data;
    };

    addGrid = async (data, cb) => {
        const uName = Config.profile.UserID || '';
        const inventoryID = data[0].InventoryID;
        const inventoryName = data[0].InventoryName;
        const unitID = data[0].UnitID;
        const divisionID = this.division;
        const param = {
            UserID: uName,
            DivisionID: divisionID,
            InventoryID: inventoryID,
            Quantity: 1,
            UnitID: unitID,
            CurrencyID: this.state.currency,
            PaymentMethodID: this.state.paymentMethod,
            PriceListID: this.state.priceList,
        };

        this.props.w19F2001Actions.getBasePrice(param, async (error, res) => {
            cb && cb();
            if (error) {
                cb && cb(true, false);
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            cb && cb(false, true);
            const {numValue} = this.props;
            const newRow = {
                InventoryID: inventoryID,
                ItemNameU: inventoryName,
                UnitID: data[0].UnitID,
                UnitName: data[0].UnitName,
                ConversionFactor: data[0].ConversionFactor,
                UnitPriceOnD14: res ? res.BasePrice : 0,
                OUnitPrice: res ? res.BasePrice : 0,
                Quantity: numValue && numValue.NumValue === 1 ? 0 : 1,
                CQuantity: numValue && numValue.NumValue === 1 ? 0 : 1,
                ACol01: 0,
                ACol02: 0,
                ACol03: 0,
                ACol04: 0,
                ACol05: 0,
                AdjustedUnitPrice: 0,
                Amount: 0,
                CAmount: 0,
                CAmountTmp: 0,
                CReduce: 0,
                CVAT: 0,
                C_ACol01: 0,
                C_ACol02: 0,
                C_ACol03: 0,
                C_ACol04: 0,
                C_ACol05: 0,
                OAmountTmp: 510000,
                OVAT: 0,
                OriginalReduce: 0,
                RateReduce: 0,
                TotalAdjustedAmount: 0,
                TotalAdjustedCAmount: 0,
                VATRate: 0,
            };
            const defaultVREF = await this.getDefaultVRef(inventoryID);

            if (defaultVREF.code === 200) {
                newRow.VRef5 = defaultVREF.data.Str05U
            }
            let grid = [
                ...this.state.dataGrid,
                newRow
            ];

            this.setState({
                dataGrid: grid
            }, () => {
                this.calculate(newRow);
            });

        });

    };

    getBasePrice = async (data) => {
        const uName = Config.profile.UserID || '';
        const param = {
            UserID: uName,
            DivisionID: this.division,
            InventoryID: data.InventoryID,
            Quantity: data.Quantity,
            UnitID: data.UnitID,
            CurrencyID: this.state.currency,
            PaymentMethodID: this.state.paymentMethod,
            PriceListID: this.state.priceList,
        };

        const result = await getBasePrice(param);

        if (result && result[0] && result[0].InventoryID) {
            return result[0].BasePrice;
        }

        return data.UnitPriceOnD14;
    };

    deleteRow(e) {
        Config.popup.show("YES_NO", Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            let grid = [...this.state.dataGrid];
            grid.splice(e.rowIndex, 1);
            this.setState({
                dataGrid: grid
            });
        })
    }

    loadCboShipAdress(customerID) {
        const param = {
            ObjectID: customerID
        };

        this.props.w19F2001Actions.loadshipAddress(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                shipAdressData: data
            });
        })
    }

    hidePopupClose(e) {
        this.setState({
            showPopupClose: false
        });
        if (e) {
            this.loadDataMaster();
            this.setState({
                disabledQuote: true
            })
        }
    }

    hideW18F1015 = async (data) => {
        if (!data) {
            this.setState({
                showW18F1015: false,
            });
            return false;
        }
        if (this.cbo_Company !== data.ObjectID) {
            this.cbo_Company = data.ObjectID;
            this.dataFilterInventory.ObjectID = data.ObjectID;
            const Inventory = await getInventory(this.dataFilterInventory);
            this.setState({
                dataInventory: Inventory && Inventory.rows ? Inventory.rows : []
            });
            this.totalInventory = Inventory.total;
        }
        this.division = data.DivisionID;
        this.setState({
            showW18F1015: false,
            customerID: data.ObjectID,
            customerName: data.CompanyNameU,
            address: data.AddressLineU,
            telephone: data.Telephone ? data.Telephone : null,
            paymentTerm: data.PaymentTermID ? data.PaymentTermID : null,
        }, () => {
            this.loadCboShipAdress(data.CompanyID);
            this.loadCboContact(data.CompanyID);
        })
    };

    loadCboContact(customerID) {
        const param = {
            CompanyID: customerID
        };

        this.props.w19F2001Actions.loadContact(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                cboContact: data
            });
        })
    }

    async calculate(rowData) {
        // console.log('==== calculate ====');
        // console.log('==== calculate => rowData: ', rowData);
        // const { dataGrid } = this.props;
        // console.log('==== calculate => this.changeColumn: ', this.dataCellChanged);

        const getFormat = (colName) => {
            // nhập đơn giá 23.232 và DC1 323.9 sau khi tính toán đơn giá sau DC  sẽ là 347.1319999995
            // Nên format ngay đây theo trước khi assign lại rowdata
            return this.gridContainer.instance.columnOption(colName, 'format')
        };
        if (rowData.RateReduce > 100) {
            Config.popup.show('INFO', `${Config.lang("CRM_Chiet_khau_khong_duoc_lon_hon")} 100%`);
            rowData.RateReduce = '';
            return false;
        }
        if (rowData.VATRate > 100) {
            Config.popup.show('INFO', `${Config.lang("CRM_Thue_suat_khong_duoc_lon_hon")} 100%`);
            rowData.VATRate = '';
            return false;
        }
        const {numValue} = this.props;
        const exchangeR = this.exchangeRate ? parseInt(this.exchangeRate) : 1;
        let aCol01 = rowData.ACol01 ? rowData.ACol01 : 0;
        let aCol02 = rowData.ACol02 ? rowData.ACol02 : 0;
        let aCol03 = rowData.ACol03 ? rowData.ACol03 : 0;
        let aCol04 = rowData.ACol04 ? rowData.ACol04 : 0;
        let aCol05 = rowData.ACol05 ? rowData.ACol05 : 0;
        let c_ACol01 = aCol01 * exchangeR;
        let c_ACol02 = aCol02 * exchangeR;
        let c_ACol03 = aCol03 * exchangeR;
        let c_ACol04 = aCol04 * exchangeR;
        let c_ACol05 = aCol05 * exchangeR;

        let quantity;
        if (!numValue || numValue.NumValue === 0) {
            quantity = 1;
            rowData.Quantity = 1;
            rowData.CQuantity = 1;
        } else {
            quantity = rowData.Quantity ? rowData.Quantity : 0;
            rowData.CQuantity = this.convertDecimal(quantity * rowData.ConversionFactor, getFormat('CQuantity'))
        }

        // If change quantity must be get new base price
        if (this.dataCellChanged.field === 'Quantity' && this.dataCellChanged.value !== rowData.Quantity) {
            const basePrice = await this.getBasePrice(rowData);
            // console.log('===== basePrice: ', basePrice);

            rowData.UnitPriceOnD14 = basePrice;
            rowData.OUnitPrice = basePrice;
        }

        rowData.C_ACol01 = this.convertDecimal(exchangeR > 0 ? c_ACol01 : 1, getFormat('C_ACol01'));
        rowData.C_ACol02 = this.convertDecimal(exchangeR > 0 ? c_ACol02 : 1, getFormat('C_ACol02'));
        rowData.C_ACol03 = this.convertDecimal(exchangeR > 0 ? c_ACol03 : 1, getFormat('C_ACol03'));
        rowData.C_ACol04 = this.convertDecimal(exchangeR > 0 ? c_ACol04 : 1, getFormat('C_ACol04'));
        rowData.C_ACol05 = this.convertDecimal(exchangeR > 0 ? c_ACol05 : 1, getFormat('C_ACol05'));
        rowData.OAmountTmp = this.convertDecimal(quantity * rowData.OUnitPrice, getFormat('OAmountTmp'));
        rowData.CAmountTmp = this.convertDecimal(rowData.OAmountTmp * exchangeR, getFormat('OAmountTmp'));
        rowData.OriginalReduce = this.convertDecimal(rowData.OAmountTmp * rowData.RateReduce / 100, getFormat('OriginalReduce'));
        rowData.CReduce = this.convertDecimal(rowData.CAmountTmp * rowData.RateReduce / 100, getFormat('CReduce'));
        rowData.OVAT = this.convertDecimal(rowData.OAmountTmp * rowData.VATRate / 100, getFormat('OVAT'));
        rowData.CVAT = this.convertDecimal(rowData.CAmountTmp * rowData.VATRate / 100,getFormat('CVAT'));
        rowData.Amount = this.convertDecimal(rowData.OAmountTmp - rowData.OriginalReduce + (aCol01 + aCol02 + aCol03 + aCol04 + aCol05), getFormat('Amount'));
        rowData.CAmount = this.convertDecimal(rowData.CAmountTmp - rowData.CReduce + (c_ACol01 + c_ACol02 + c_ACol03 + c_ACol04 + c_ACol05), getFormat('CAmount'));
        rowData.TotalAdjustedAmount = this.convertDecimal((rowData.OAmountTmp - rowData.OriginalReduce + rowData.OVAT) + (aCol01 + aCol02 + aCol03 + aCol04 + aCol05), getFormat('TotalAdjustedAmount'));
        rowData.TotalAdjustedCAmount = this.convertDecimal((rowData.CAmountTmp - rowData.CReduce + rowData.CVAT) + (c_ACol01 + c_ACol02 + c_ACol03 + c_ACol04 + c_ACol05), getFormat('TotalAdjustedAmount'));
        rowData.AdjustedUnitPrice = this.convertDecimal(((rowData.OAmountTmp - rowData.OriginalReduce) + (aCol01 + aCol02 + aCol03 + aCol04 + aCol05)) / quantity, getFormat('AdjustedUnitPrice'))
    }

    renderAction(e) {
        if (!this.getInfo()) return;
        const {mode} = this.getInfo();
        const {showPass, saveSuccess} = this.state;
        let customPer = 0;
        if (e && !showPass && mode !== 'view' && !(mode === 'add' && saveSuccess)) customPer = this.state.iPerD17F1611;
        return (
            <GridActionBar
                isPer={customPer}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.deleteRow(e)
                }}/>
        );
    }

    onChangeCurrency(key, e) {
        const grid = [...this.state.dataGrid];

        if (key === this.state.baseCurrencyID) {
            this.setState({
                showColumn: false
            })
        } else {
            this.setState({
                showColumn: true
            });
        }
        const exchangeRateData = e.selectedRowsData[0].ExchangeRate;
        this.getFormatDecimal(key);
        this.setState({
            currency: key,
        });
        if (exchangeRateData) {
            this.exchangeRate = e.selectedRowsData[0].ExchangeRate;
            document.getElementById('exchangeRateW19F2001').innerHTML = e.selectedRowsData[0].ExchangeRate.toLocaleString('en-US', {currency: 'USD'});
        }
        if (grid.length) {
            grid.forEach((value) => {
                this.calculate(value);
            });
        }
    }

    onChangePriceList(pricelistID) {
        this.setState({priceList: pricelistID});
    }

    checkInventory(inventoryID) {
        const {dataGrid} = this.state;
        return !dataGrid.find(x => x.InventoryID === inventoryID);
    }

    saveData() {
        const {dataGrid, validTo, validFrom, priceList, shipAddressName, address, contactPerson, telephone, currency, paymentTerm, paymentMethod, shipAdressID, customerID, customerName} = this.state;
        const {mode, id} = this.getInfo();
        const {extraInfo} = this.props;
        const contactPersonU = document.getElementById('contactPersonW19F2001').options[document.getElementById('contactPersonW19F2001').selectedIndex].text;
        const statusVoucher = '';
        const employeeID = Config.profile.UserID;
        const exchangeRate = this.exchangeRate;
        const DescriptionU = document.getElementById('descriptionW19F2001').value;
        const division = this.division;
        const transactionTypeID = this.state.toggleColumn ? this.state.toggleColumn.TranTypeID : '';
        const voucherTypeID = this.state.toggleColumn ? this.state.toggleColumn.VoucherTypeID : '';

        if (!customerID) {
            Config.popup.show("INFO", Config.lang("CRM_Khach_hang_khong_duoc_de_trong"));
            document.getElementById('customerW19F2001').focus();
            return false;
        } else if (!this.state.currency) {
            Config.popup.show("INFO", Config.lang("CRM_Tien_te_khong_duoc_de_trong"));
            document.getElementById('currencyW19F2001').getElementsByClassName('dx-texteditor-input')[0].focus();
            return false;
        } else if (!dataGrid || !(dataGrid.length > 0)) {
            Config.popup.show("INFO", Config.lang("CRM_Ban_chua_chon_hang_hoa"), null, null, () => {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    document.getElementById('cboInventoryW19F2001').focus();
                }, 300)
            });
            return false;
        } else if (!this.fromDate.instance.option('value')) {
            Config.popup.show('INFO', Config.lang("CRM_Ngay_hieu_luc_khong_duoc_de_trong"));
            document.getElementById('validFromW19F2001').getElementsByClassName('dx-texteditor-input')[0].focus();
            return false;
        } else if ((validFrom > validTo) && validTo) {
            Config.popup.show('INFO', Config.lang("CRM_Ngay_het_hieu_luc_phai_lon_hon_ngay_hieu_luc"));
            this.setState({
                validTo: null

            });
            document.getElementById('validToW19F2001').getElementsByClassName('dx-texteditor-input')[0].focus();
            return false;
        }
        const param = {
            DivisionID: division,
            Language: Config.language || '84',
            Master: {
                "CustomerID": customerID,
                "CustomerNameU": customerName,
                "ContactPerson": contactPerson,
                "ContactPersonU": contactPersonU,
                "Telephone": telephone,
                "EmployeeID": employeeID,
                "ValidTo": validTo ? moment(validTo).set(currentTime).format("YYYY-MM-DD HH:mm:ss.SSS") : validTo,
                "ValidFrom": validFrom ? moment(validFrom).set(currentTime).format("YYYY-MM-DD HH:mm:ss.SSS") : validFrom,
                "CurrencyID": currency,
                "ExchangeRate": exchangeRate,
                "PriceListID": priceList,
                "QuotationHeaderU": DescriptionU,
                "PaymentTermID": paymentTerm,
                "PaymentMethodID": paymentMethod,
                "AddressLineU": address,
                "ShipAddressID": shipAdressID,
                "ShipAddressU": shipAddressName,
                "StatusVoucher": statusVoucher,
                "TranTypeID": transactionTypeID,
                "VoucherTypeID": voucherTypeID,
            },
            Detail: JSON.stringify(dataGrid)
        };

        extraInfo.forEach((value) => {
            //id lay tu api get-extra-info la ref01 luu data thi la ref1U
            if (value.Disabled === 1) return;
            let infoID, ref, num, infoIDF;
            infoID = value.InfoID;
            ref = infoID.substring(0, 3);
            num = infoID.substring(3, infoID.length).replace(/^0+/, ''); //Xóa số 0 trước số nếu có
            infoIDF = `${ref + num}U`;
            param.Master[infoIDF] = document.getElementById(infoID).value;
        });
        param.Master = JSON.stringify(param.Master);
        this.setState({
            loading: true
        });
        if (mode !== 'edit') {
            this.props.w19F2001Actions.addNew(param, (error, data) => {
                this.setState({
                    loading: false
                });
                if (error) {
                    let errorCode = error.code || null;
                    let message;
                    switch (errorCode) {
                        case "US004":
                            message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                            break;
                        default:
                            message = error.message || "Lỗi chưa xác định";
                            break;
                    }
                    Config.popup.show("INFO", Config.lang("Loi"));
                    Config.popup.show('INFO', message);
                    return false;
                }
                document.getElementById('voucherNumW19F2001').value = data.VoucherNum;
                this.setState({
                    saveSuccess: true,
                    showShare: true,
                });
                notify(Config.lang('CRM_Luu_thanh_cong'), "success", 400);
                this.getPerApprove(data.QuotationID);
                this.quotationID = data.QuotationID;
            })
        } else {
            param.QuotationID = id;
            this.props.w19F2001Actions.edit(param, (error) => {
                this.setState({
                    loading: false
                });
                if (error) {
                    let errorCode = error.code || null;
                    let message;
                    switch (errorCode) {
                        case "US004":
                            message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                            break;
                        default:
                            message = error.message || "Lỗi chưa xác định";
                            break;
                    }
                    Config.popup.show('INFO', message);
                    return false;
                }
                this.setState({
                    saveSuccess: true,
                    showShare: true,
                });
                notify(Config.lang('CRM_Luu_thanh_cong'), "success", 400);
            })
        }
    }

    getNumValue() {
        const param = {
            tranTypeID: 'NotQuantitySalesOrder'
        };

        this.props.w19F2001Actions.getNumValue(param, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
        })
    }

    getPerApprove(quotationID) {
        // Dose not use with demo version

        // if (!this.getInfo()) return;
        // const {id} = this.getInfo();
        // let idF = quotationID ? quotationID : id;
        //
        // const param = {
        //     QuotationID: idF
        // };
        //
        // this.props.w19F2001Actions.loadPerApprove(param, (error, data) => {
        //     if (error) {
        //         let errorCode = error.code || null;
        //         let message;
        //         switch (errorCode) {
        //             case "US004":
        //                 message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
        //                 break;
        //             default:
        //                 message = error.message || "Lỗi chưa xác định";
        //                 break;
        //         }
        //         Config.popup.show('INFO', message);
        //         return false;
        //     }
        //     this.setState({
        //         perApprove: data.Enable
        //     });
        // })
    }

    loadDataShare(voucherNum) {
        const param = {
            VoucherNum: voucherNum
        };

        this.props.w19F2001Actions.loadDataShare(param, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
        })
    }

    setDefaultCurrency() {
        this.props.w19F2001Actions.getDefaultCurrency((error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data.length > 0) {
                this.init = true;
                const defaultCurrency = data[0].BaseCurrencyID;
                const defaultExchangeRate = data[0].ExchangeRate;
                document.getElementById('exchangeRateW19F2001').innerHTML = defaultExchangeRate.toLocaleString('en-US', {currency: 'USD'});
                this.exchangeRate = defaultExchangeRate;
                this.getFormatDecimal(defaultCurrency);
                if (defaultCurrency === this.state.baseCurrencyID) {
                    this.setState({
                        showColumn: false
                    })
                } else {
                    this.setState({
                        showColumn: true
                    });
                }
                this.setState({
                    currency: defaultCurrency,
                });
            }
        })
    }

    getCustomer() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {CompanyID} = this.getInfo();
        const paramGrid = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": 'W18F1010',
            "SearchID": '',
            "StrSearch": CompanyID,
            "CompanyID": '',
            "skip": 0,
            "limit": 1,
            "CompanyStatus": '',
            "ReportID": '',
        };
        this.props.w18F1015Actions.loadGridW18F1015(paramGrid, (error, data) => {
            if (data.length) this.hideW18F1015(data[0]);
        })
    }

    getFormatDecimal = (currencyID) => {
        //Hàm format cột và sum cột
        const param = {
            CurrencyID: currencyID
        };
        w19F2001Actions.getFormatDecimal(param, (error, result) => {
            if (error) return;
            const {data} = result;
            const {caption} = this.state;
            const patternOriginal = this.genFormatPattern(data.OriginalDecimal);
            const patternPriceDecimal = this.genFormatPattern(data.UnitPriceDecimals);
            if (this.gridContainer) {
                const summary = this.gridContainer.instance.option('summary');
                let i = 0, count = 0;
                const condition = ['OUnitPrice', 'AdjustedUnitPrice', 'OAmountTmp', 'OriginalReduce', 'OVAT', 'TotalAdjustedAmount'];
                //set lại format và summary cho cột
                for (i; i < summary.totalItems.length; i++) {
                    if (count === 6) break;
                    if (condition.indexOf(summary.totalItems[i].column) > -1) {
                        summary.totalItems[i].valueFormat = patternPriceDecimal;
                        this.gridContainer.instance.columnOption(summary.totalItems[i].column, 'format', patternPriceDecimal);
                        count++;
                    }
                }
                caption && caption.forEach((value) => {
                    if (value.ColID.includes('ACol')) this.gridContainer.instance.columnOption(value.ColID, 'format', patternOriginal);
                });
                const dataSource = [...this.state.dataGrid];
                dataSource.forEach((value) => {
                    //format lại số thập phân dữ liệu
                    value.OUnitPrice = this.convertDecimal(value.OUnitPrice, this.gridContainer.instance.columnOption('OUnitPrice', 'format'));
                    Object.keys(value).forEach((keys) => {
                        if (keys.includes('ACol')) value[keys] = this.convertDecimal(value[keys], this.gridContainer.instance.columnOption(keys, 'format'));
                    });
                });
                this.setState({
                    dataGrid: dataSource
                });
            }
        })
    };

    loadDataMaster = () => {
        if (!this.getInfo()) return;
        const {id, mode} = this.getInfo();
        let idF = mode !== 'add' ? id : this.quotationID;
        const {extraInfo} = this.props;
        const uName = Config.profile.UserID || '';
        const paramStore = {
            "UserID": uName,
            "QuotationID": idF,
        };

        this.props.w19F2001Actions.apiStore(paramStore, async (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.dataFilterInventory.ObjectID = data[0].ObjectID;
                if (document.getElementById('descriptionW19F2001'))
                    document.getElementById('descriptionW19F2001').value = data[0].QuotationHeaderU;
                if (document.getElementById('exchangeRateW19F2001'))
                    document.getElementById('exchangeRateW19F2001').innerHTML = data[0].ExchangeRate.toLocaleString('en-US', {currency: 'USD'});
                this.exchangeRate = data[0].ExchangeRate;
                if (document.getElementById('voucherNumW19F2001') && mode !== 'copy')
                    document.getElementById('voucherNumW19F2001').value = data[0].VoucherNum;
                if (extraInfo) {
                    extraInfo.filter((value) => {
                        return value.Disabled !== 1;
                    }).forEach((value) => {
                        const item = Object.keys(data[0]).find((key) => {
                            let a = key.split('Ref');
                            a = 'Ref0' + a[1];
                            let b = value.InfoID + 'U';
                            return a.toUpperCase() === b.toUpperCase();
                        });
                        if (item && value.InfoID && document.getElementById(value.InfoID)) {
                            document.getElementById(value.InfoID).value = data[0][item];
                        }
                    });
                }
                this.division = data[0].DivisionID;
                if (data[0].CustomerID) {
                    this.cbo_Company = data[0].ObjectID;
                    this.dataFilterInventory.ObjectID = data[0].ObjectID;
                    const Inventory = await getInventory(this.dataFilterInventory);
                    this.setState({
                        dataInventory: Inventory && Inventory.rows ? Inventory.rows : []
                    });
                    this.totalInventory = Inventory.total;
                    this.refreshCombo = true;
                }
                if (data[0].CurrencyID === this.state.baseCurrencyID) {
                    this.setState({
                        showColumn: false
                    })
                } else {
                    this.setState({
                        showColumn: true
                    });
                }
                this.getFormatDecimal(data[0].CurrencyID);
                this.loadDataGrid();
                this.loadCboShipAdress(data[0].CompanyID);
                this.loadCboContact(data[0].CompanyID);
                this.loadDataShare(data[0].VoucherNum);
                this.setState({
                    approveUserName: data[0].ApproveUserName,
                    contactPerson: data[0].ContactPerson,
                    customerID: data[0].ObjectID,
                    address: data[0].AddressLineU,
                    currency: data[0].CurrencyID,
                    priceList: data[0].PriceListID,
                    paymentTerm: data[0].PaymentTermID,
                    shipAdressID: data[0].ShipAddressID,
                    shipAddressName: data[0].ShipAddressU,
                    approveUser: data[0].ApproveUser,
                    approveDate: data[0].ApproveDate,
                    telephone: data[0].Telephone ? data[0].Telephone : null,
                    imageUser: data[0].Image,
                    showPass: mode !== 'copy' ? data[0].AStatus === 1 : false,
                    aStatus: data[0].AStatus,
                    paymentMethod: data[0].PaymentMethodID,
                    validTo: data[0].ValidTo ? moment(data[0].ValidTo).format() : null,
                    validFrom: data[0].ValidFrom ? moment(data[0].ValidFrom).format() : null,
                    approveNote: data[0].ApproveNotesU,
                    dateQuote: mode !== 'copy' && data[0].ClosingDate ? moment(data[0].ClosingDate).format() : null,
                    nameQuote: mode !== 'copy' ? data[0].UserNameU : null,
                    customerName: data[0].CustomerNameU
                });
                if (!(data[0].ClosingDate && data[0].UserNameU) && data[0].AStatus === 1) {
                    this.setState({
                        disabledQuote: false
                    })
                }
            }
        })
    };

    unicodeConvert = (str) => {
        str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a");
        str = str.replace(/[èéẹẻẽêềếệểễ]/g, "e");
        str = str.replace(/[ìíịỉĩ]/g, "i");
        str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o");
        str = str.replace(/[ùúụủũưừứựửữ]/g, "u");
        str = str.replace(/[ỳýỵỷỹ]/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A");
        str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E");
        str = str.replace(/[ÌÍỊỈĨ]/g, "I");
        str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O");
        str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U");
        str = str.replace(/[ỲÝỴỶỸ]/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    };

    onSearch = (text) => {
        let grid = [...this.state.dataGrid], i = 0;
        for (i; i < grid.length; i++) {
            let stringSearch = "";
            if (grid[i].InventoryID || grid[i].ItemNameU) {
                stringSearch = grid[i].InventoryID + grid[i].ItemNameU;
            }
            grid[i].hide = !!(stringSearch && this.unicodeConvert(stringSearch).search(new RegExp(this.unicodeConvert(text), "i")) < 0);
        }
        this.setState({
            dataGrid: grid
        })
    };

    loadDataGrid() {
        if (!this.getInfo()) return;
        const {id} = this.getInfo();
        const param = {
            // where: `A.QuotationID ='${id}'`,
            where: JSON.stringify({
                fieldName: 'A.QuotationID',
                operator: '=',
                value: id
            }),
            sort: 'A.OrderNum',
        };

        this.props.w19F2001Actions.loadDataGrid(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.rows && data.rows.length > 0) {
                // console.log('======= loadDataGrid => dataGrid: ', data.rows);

                let dataGrid = [];
                const rows = data.rows || [];
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];

                    if (!row.hide || typeof row.hide === 'undefined') {
                        dataGrid.push(row);
                    }
                }

                this.setState({
                    dataGrid: dataGrid
                })
            }
        })
    }

    genFormatPattern = (number) => {
        let pattern = '#,##0.'
        let i = 0;
        for (i; i < number && i < 4; i++) {
            pattern += '#'
        }
        return pattern;
    };

    renderDynamicDropdown(e) {
        const {row} = e;
        let id, name;

        id = e.dataField;
        name = `${e.dataField}Name}`;
        e.editorOptions.onValueChanged = (arg) => {
            row.data[id] = arg.value;
            const dataSource = arg.component.option("dataSource");
            const nameFilter = dataSource.filter((value) => {
                return value[id] === arg.value;
            });
            if (nameFilter.length > 0) {
                row.data[name] = nameFilter[0][name];
            }
        };
        e.editorOptions.onInitialized = async function (arg) {
            let loadDataSource;
            if (id === 'UnitID') {
                const param = {
                    "InventoryID": row.data.InventoryID
                };
                loadDataSource = await getCbounit(param);
            }
            if (loadDataSource && loadDataSource.code === 200) {
                let data = loadDataSource.data;
                arg.component.option("dataSource", data);
            }
        }
    }

    convertDecimal = (value, pattern) => {
        let numb, decimal;
        const patternF = pattern ? pattern.split('.')[1].length : 0;
        numb = value ? value.toString().split('.')[0] : 0;
        decimal = value ? value.toString().split('.')[1] : 0;
        decimal = decimal ? decimal.substring(0, patternF) : decimal;
        return parseFloat(`${numb ? numb : 0}${decimal ? `.${decimal}` : ''}`)
    };

    renderCboUnit(e) {
        const {row} = e;
        let isDynamicCombo;

        if (e.dataField === 'UnitID') isDynamicCombo = true;
        if (isDynamicCombo) {
            this.renderDynamicDropdown(e)
        } else {
            e.editorOptions.onFocusIn = args => {
                const input = args.element.querySelector('.dx-texteditor-input');
                input.select();
            };
            e.editorOptions.onKeyDown = args => {
                if (e.format && e.dataType === 'number') {
                    const value = args.event.target.value;
                    const patternF = e.format ? e.format.split('.')[1].length : 0;
                    if (!patternF && (args.event.key === '.' || args.event.key === ',')) args.event.preventDefault();
                    const decimalPart = value.includes('.') ? value.split('.')[1] : value.split(',') ? value.split(',')[1] : false;
                    if (decimalPart && decimalPart.length === patternF && parseInt(args.event.key) && !window.getSelection().toString()) {
                        //Nếu phần thập phân leng = 3 và ký tụ tiếp theo là số, và không bôi đen input thì cho nhập
                        args.event.preventDefault();
                    }
                }
            };
            e.editorOptions.onValueChanged = async (args) => {
                row.data[e.dataField] = args.value;
                // console.log('===== onValueChanged =====', e);
                this.dataCellChanged = {
                    field: e.dataField,
                    value: e.value,
                };
                await this.calculate(row.data);
                e.component.refresh();
            }
        }
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);

        if (url && url.get('id') && url.get('mode')) {
            return {id: url.get('id'), mode: url.get('mode'), CompanyID: url.get('CompanyID'), formCall: url.get('formCall')};
        } else if (location && location.state) {
            return {
                id: location.state.id,
                mode: location.state.mode,
                CompanyID: location.state.CompanyID,
                formCall: location.state.formCall
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W19F2000');
                return null;
            }
            return false;
        }
    };

    loadMore = async (cb) => {
        let dataTemp = [...this.state.dataInventory];

        if (dataTemp.length >= this.totalInventory) {
            cb && cb();
            return;
        }
        this.dataFilterInventory.skip += 50;
        const Inventory = await getInventory(this.dataFilterInventory);
        cb && cb();
        if (Inventory && Inventory.rows) {
            dataTemp = dataTemp.concat(Inventory.rows);
            this.setState({
                dataInventory: dataTemp
            },)
        }
        this.totalInventory = Inventory.total;
    };

    onSearchClient = async (cb, txt) => {
        this.dataFilterInventory = {
            limit: 50,
            skip: 0,
            ObjectID: this.dataFilterInventory.ObjectID,
            where: JSON.stringify([
                {
                    fieldName: 'T.InventoryID',
                    operator: 'LIKE',
                    value: txt,
                },
                {
                    fieldName: 'InvNameObjectU',
                    operator: 'LIKE',
                    value: txt,
                },
                {
                    fieldName: 'InventoryNameU',
                    operator: 'LIKE',
                    value: txt,
                }
            ])
        };
        const Inventory = await getInventory(this.dataFilterInventory);
        cb && cb();
        if (Inventory && Inventory.rows) {
            this.setState({
                dataInventory: Inventory.rows
            },)
        }
        this.totalInventory = Inventory.total;
    };

    onRowClick = (e, cb) => {
        const rowData = e.data;
        //Check hàng hoá đã tồn tại
        // if (!this.checkInventory(rowData.InventoryID)) {
        //     Config.popup.show('INFO', Config.lang('CRM_Hang_hoa_nay_da_ton_tai'));
        //     cb && cb(true, false);
        //     return false
        // }
        // const {inventory} = this.state;
        // if (inventory === rowData.InventoryID) return false;
        this.setState({
            inventory: rowData.InventoryID
        });
        this.addGrid([rowData], (err, suc) => {
            cb && cb(err, suc);
        });
    };

    renderColumn = (caption, toggleColumn) => {
        let column = [];
        let totalItem = [];
        const totalItem1 = ['TotalAdjustedAmount', 'OAmountTmp', 'OVAT', 'OriginalReduce'];
        const totalItem2 = ['Amount', 'CAmount', 'TotalAdjustedCAmount', 'CQuantity',
            'CAmountTmp', 'Quantity', 'CVAT', 'CReduce'];
        const {checkDref5, cboDref5} = this.state;
        [...totalItem1, ...totalItem2].forEach((value, index) => {
            if (value.includes(totalItem1)) {
                totalItem.push(
                    <TotalItem
                        key={index}
                        displayFormat={"{0}"}
                        column={value}
                        summaryType={'sum'}
                    />
                )
            } else {
                totalItem.push(
                    <TotalItem
                        key={index}
                        displayFormat={"{0}"}
                        column={value}
                        valueFormat={'#,##0.##'}
                        summaryType={'sum'}
                    />
                )
            }
        });
        caption && caption.forEach((value, index) => {
            const isQD = value.ColShortU.includes('QĐ');
            const isVref = value.ColID.includes('VRef');
            const hasACol = value.ColID.includes('ACol');
            const property = isQD ? `Display${value.ColID.replace('C_', '')}` : `Display${value.ColID}`;
            const visible = isQD ? (toggleColumn ? toggleColumn[property] === 1 : true) && this.state.showColumn : toggleColumn ? toggleColumn[property] === 1 : true;
            if (!value.ColID.includes('VRef')) {
                totalItem.push(<TotalItem
                    key={`${value.ColID}Sum`}
                    displayFormat={"{0}"}
                    column={value.ColID}
                    valueFormat={!value.ColID.includes('ACol') && `#,##0.##`}
                    summaryType={'sum'}/>)
            }
            column.push(<Column key={index}
                                dataField={value.ColID}
                                dataType={isVref ? 'string' : 'number'}/// Vref type = string  || number
                                format={!hasACol && `#,##0.##`}
                                visible={visible}
                                allowEditing={!isQD}
                                caption={value.ColShortU}>
                {value.ColID === 'VRef5' && checkDref5 === 'dropdown' &&
                <Lookup dataSource={cboDref5}/>}}
            </Column>)
        });

        return {
            column: column,
            totalItem: this.toggleSummary ? <Summary>{totalItem}</Summary> : null
            //Fix totalItem Summary khi nhận totalItem đầu tiên sẽ giữ nguyên giá trị dù sau khi caption api chạy  và update lại totalItem mới
        };
    };

    changeValidFrom = (e) => {
        this.setState({
            validFrom: e.value ? moment(e.value).format() : null
        })
    };

    changeValidTo = (e) => {
        this.setState({
            validTo: e.value ? moment(e.value).format() : null

        })
    };

    renderExtraInfo = (data) => {
        if (!this.getInfo()) return;
        const {showPass, saveSuccess} = this.state;
        const {mode} = this.getInfo();
        let arr = [];
        let dataF;
        dataF = data.filter((value) => {
            return value.Disabled !== 1;
        });
        for (let i = 0; i < dataF.length; i += 2) {
            arr.push(
                <FormGroup key={i}>
                    <Col lg={3} md={3}>
                        <label className={'control-label'}>{dataF[i].DescriptionU}</label>
                    </Col>
                    <Col lg={3} md={3}>
                        <input id={dataF[i].InfoID} defaultValue={dataF[i].ValueDefaultU}
                               disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                               className={'form-control dynamicInputW19F2001'}/>
                    </Col>
                    {
                        (i + 1) < dataF.length ? <React.Fragment>
                            <Col lg={3} md={3}>
                                <label className={'control-label'}>{dataF[i + 1].DescriptionU}</label>
                            </Col>
                            <Col lg={3} md={3}>
                                <input id={dataF[i + 1].InfoID} defaultValue={dataF[i + 1].ValueDefaultU}
                                       disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                       className={'form-control dynamicInputW19F2001'}/>
                            </Col>
                        </React.Fragment> : ''
                    }

                </FormGroup>
            )
        }
        return arr
    };

    goBack = () => {
        const {formCall} = this.getInfo();
        if (formCall === 'W19F2000') {
            browserHistory.goBack();
        } else {
            browserHistory.push(Config.getRootPath() + 'W19F2000');
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        let {id, mode} = this.getInfo();
        let quotationID;
        if (mode !== 'add') {
            quotationID = id;
        } else {
            quotationID = this.quotationID;
        }
        const btnCRM = [
            {
                buttonName: Config.lang("CRM_Hang_hoa_da_chon"),
                buttonType: 'label',
                className: 'mgr5',
                icon: 'fas fa-bars text-blue',
                align: 'left',
            },
        ];
        const {dataShare, numValue, extraInfo} = this.props;
        const {
            shipAdressData, caption, toggleColumn, cboContact, showShare, showPass,
            dataGrid, approveNote, approveUser, aStatus, imageUser, saveSuccess,
            showPopupClose, dateQuote, nameQuote, customerName
        } = this.state;

        const {column, totalItem} = this.renderColumn(caption, toggleColumn);
        const nNumValue = numValue && numValue[0] ? numValue[0] : numValue;

        return (
            <div className="page-container" style={{margin: 0}}>
                <div style={{marginLeft: '10px'}} className="grid-title">{Config.lang("CRM_Lap_don_hang")}</div>

                <EventTracking action={"FORM"} label={"W19F2001"}/>

                {this.state.showW18F1019 && <W18F1019Pop companyID={document.getElementById('voucherNumW19F2001').value}
                                                         companyName={customerName}
                                                         transType={'QUO'} onHide={() => {
                    this.setState({showW18F1019: false}, () => {
                        this.loadDataShare(document.getElementById('voucherNumW19F2001').value);
                    })
                }}/>}
                {this.state.showPopup &&
                <PopUp quotationID={quotationID} approveUser={approveUser} approveNote={approveNote}
                       aStatus={aStatus === 1} onHide={(e) => {
                    const {aStatus, approveNote, approveUser} = e;
                    this.setState({
                        showPopup: false,
                    }, () => {
                        if (e) {
                            if (aStatus === 1) {
                                this.setState({
                                    approveUserName: Config.profile.SalesPersonNameU,
                                })
                            }
                            this.setState({
                                showPass: aStatus,
                                approveUser: approveUser,
                                approveNote: approveNote,
                                aStatus: aStatus ? 1 : 0,
                            })
                        }
                    })
                }}/>}

                {this.state.showW18F1015 && <W18F1015PopUp mode={1} onHide={(data) => {
                    this.hideW18F1015(data);
                }} companyID={''}
                />}
                <Form horizontal={true} id={'W19F2001'} style={{margin: '0 15px'}}>
                    <Collapse in={this.state.collapse}>
                        <div>
                            <FormGroup className={'mgt5'}>
                                <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                    <ButtonCustom color={'text-orange'} className={'mgr5'}
                                                  icon={'fas fa-arrow-alt-left'}
                                                  isAwesome={true} name={Config.lang("CRM_Quay_lai")}
                                                  onClick={this.goBack}
                                    />
                                    <ButtonSave name={Config.lang('CRM_Luu')}
                                                disabled={this.state.iPerD17F1611 < 2 || this.state.showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                className={'mgr5'} onClick={() => {
                                        this.saveData();
                                    }}/>

                                    {(dateQuote || nameQuote) && <span id={'closeQuoteW19F2001'}
                                                                       className={'pull-right text-muted control-label'}>{Config.lang('CRM_Bao_gia_duoc_dong')}
                                        <span
                                            id={'dateQuoteW19F2001'}>{dateQuote && ` ${Config.lang('CRM_Vao_ngay').toLowerCase()} ${moment(dateQuote).format('DD-MM-YYYY')}`}</span>
                                        <span
                                            id={'nameQuoteW19F2001'}>{nameQuote && ` ${Config.lang("CRM_Boi").toLowerCase()} ${nameQuote}`}</span>
                                    </span>
                                    }
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    <Row>
                                        <Col md={12} lg={4}>
                                            <label className={'control-label'}>{Config.lang("CRM_Khach_hang")} <span
                                                className={'text-red'}>*</span></label>
                                        </Col>
                                        <Col md={12} lg={8} style={{position: 'relative'}}>
                                            <input
                                                disabled={mode === 'edit' || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                id={'customerW19F2001'}
                                                value={customerName}
                                                onClick={() => {
                                                    this.setState({showW18F1015: true})
                                                }} className={'form-control'}/>
                                            <i className={'fa fa-sort-down'} style={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 26,
                                                pointerEvents: 'none'
                                            }}/>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={8} lg={8}>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label className={'control-label'}>{Config.lang("CRM_So_don_hang")}
                                                        <span
                                                            className={'text-red'}>*</span></label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <input id={'voucherNumW19F2001'} className={'form-control'}
                                                           readOnly={true}/>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Dieu_khoan_TM")} </label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <Combo
                                                        wordWrapEnabled={true}
                                                        showHeader={false}
                                                        virtual={true}
                                                        disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                        dataSource={paymentTermData}
                                                        value={this.state.paymentTerm}
                                                        placeholder={''}
                                                        valueExpr="PaymentTermID"
                                                        displayExpr="PaymentTermNameU"
                                                        onActionWhenSelectChange={(key) => {
                                                            this.setState({
                                                                paymentTerm: key
                                                            })
                                                        }}
                                                    >
                                                        <Column dataField={'PaymentTermNameU'}
                                                                width={'auto'}
                                                                cellRender={(e) => (e.row.data.PaymentTermID + ' - ' + e.row.data.PaymentTermNameU)}
                                                                dataType={'string'}/>
                                                    </Combo>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    <Row>
                                        <Col md={12} lg={4}>
                                            <label className={'control-label'}>{Config.lang("CRM_Dia_chi_DKKD")}</label>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <div className={'control-label'}
                                                 id={'addressW19F2001'}>{this.state.address}</div>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={8} lg={8}>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Ngay_hieu_luc")}
                                                        <span
                                                            className={'text-red'}>*</span></label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <DateBox
                                                        width={"100%"}
                                                        dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                                        placeholder={Config.lang("CRM_Ngay_hieu_luc")}
                                                        disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                        ref={(ref) => this.fromDate = ref}
                                                        id={'validFromW19F2001'}
                                                        openOnFieldClick={true}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        type={'date'}
                                                        value={this.state.validFrom}
                                                        pickerType={"calendar"}
                                                        showAnalogClock={false}
                                                        onValueChanged={this.changeValidFrom}
                                                        displayFormat={'dd/MM/y'}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Phuong_thuc_TT")} </label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <Combo
                                                        showHeader={false}
                                                        required={true}
                                                        virtual={true}
                                                        disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                        dataSource={paymentMethodData}
                                                        value={this.state.paymentMethod}
                                                        placeholder={''}
                                                        valueExpr="PaymentMethodID"
                                                        displayExpr="PaymentMethodNameU"
                                                        onActionWhenSelectChange={(key) => {
                                                            this.setState({
                                                                paymentMethod: key
                                                            })
                                                        }}
                                                    >
                                                        <Column dataField={'PaymentMethodID'} caption=""
                                                                dataType={'string'} visible={false}/>

                                                        <Column dataField={'PaymentMethodNameU'} caption=""
                                                                dataType={'string'}/>
                                                    </Combo>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    <Row>
                                        <Col md={12} lg={4}>
                                            <label
                                                className={'control-label'}>{Config.lang("CRM_Nguoi_lien_he")}</label>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <select id={'contactPersonW19F2001'} value={this.state.contactPerson}
                                                    className={'form-control'}
                                                    disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            contactPerson: e.target.value
                                                        })
                                                    }}>
                                                <option value={''}/>
                                                {
                                                    cboContact.length > 0 && cboContact.map((value, index) => {
                                                        return (
                                                            <option key={index}
                                                                    value={value.ContactID}>{value.FullNameU}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8} lg={8}>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Ngay_het_hieu_luc")} </label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <DateBox
                                                        width={"100%"}
                                                        dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                                        disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                        id={'validToW19F2001'}
                                                        placeholder={Config.lang("CRM_Ngay_het_hieu_luc")}
                                                        openOnFieldClick={true}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        value={this.state.validTo}
                                                        type={'date'}
                                                        pickerType={"calendar"}
                                                        showAnalogClock={false}
                                                        displayFormat={'dd/MM/y'}
                                                        onValueChanged={this.changeValidTo}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Dia_chi_giao_hang")}
                                                    </label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <Row>
                                                        <Col md={4} lg={4}>
                                                            <select
                                                                disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                                value={this.state.shipAdressID}
                                                                className={'form-control'}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        shipAddressName: e.target.selectedOptions[0].getAttribute("name"),
                                                                        shipAdressID: e.target.value
                                                                    })
                                                                }

                                                                }>
                                                                <option value={''}/>
                                                                {shipAdressData && shipAdressData.map((value, index) => {
                                                                    return <option key={index} name={value.ShipAddress}
                                                                                   value={value.ShipAddressID}>{value.ShipAddressID}</option>
                                                                })}
                                                            </select>
                                                        </Col>
                                                        <Col md={8} lg={8}>
                                                            <input
                                                                disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                                className={'form-control'}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        shipAddressName: e.target.value
                                                                    })
                                                                }}
                                                                value={this.state.shipAddressName}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    <Row>
                                        <Col md={12} lg={4}>
                                            <label className={'control-label'}>{Config.lang("CRM_Dien_thoai")}</label>
                                        </Col>
                                        <Col md={12} lg={8}>
                                            <div id={'telephoneW19F2001'}
                                                 className={'control-label'}>{this.state.telephone}</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8} lg={8}>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label className={'control-label'}>{Config.lang("CRM_Tien_te")}
                                                        <span
                                                            className={'text-red'}>*</span></label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <Row>
                                                        <Col md={6} lg={6} sm={6} xs={6}>
                                                            <Combo
                                                                disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                                id={'currencyW19F2001'}
                                                                virtual={true}
                                                                dataSource={currencyData}
                                                                value={this.state.currency}
                                                                placeholder={''}
                                                                valueExpr="CurrencyID"
                                                                displayExpr="CurrencyID"
                                                                totalItems={62}
                                                                onContentReady={(e) => {
                                                                    if (mode === 'add' && !this.init) this.setDefaultCurrency(e);
                                                                }}
                                                                onActionWhenSelectChange={(key, e) => {
                                                                    this.onChangeCurrency(key, e)
                                                                }}
                                                            >
                                                                <Column dataField={'CurrencyID'}
                                                                        caption=""
                                                                        dataType={'string'}/>
                                                            </Combo>
                                                        </Col>
                                                        <Col md={6} lg={6} sm={6} xs={6}>
                                                            <div id={'exchangeRateW19F2001'}
                                                                 style={{marginTop: '7px'}}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    {showShare && <Row>
                                        <Col md={4} lg={4}>
                                            {/* eslint-disable */
                                                <a href={null} onClick={() => {
                                                    this.setState({
                                                        showW18F1019: true
                                                    })
                                                }} className={'control-label'}>{Config.lang("CRM_Chia_se")}</a>
                                                /* eslint-enable */
                                            }
                                        </Col>
                                        <Col md={8} lg={8}>

                                            <div id={'shareContainerW19F2001'}>
                                                {
                                                    dataShare && dataShare.map((value, index) => {
                                                        return (
                                                            <span key={index} className={'mgr5 mgb5 shareListW19F2001'}>
                                                        <img alt={''}
                                                             src={value.URL ? value.URL : require('./../../images/icon-user-default.png')}/>
                                                        <span> {value.SalesPersonNameU}</span>
                                                    </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    }
                                </Col>
                                <Col md={8} lg={8}>
                                    <Row>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={12} lg={4}>
                                                    <label
                                                        className={'control-label'}>{Config.lang("CRM_Bang_gia")}</label>
                                                </Col>
                                                <Col md={12} lg={8}>
                                                    <Combo
                                                        disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                        id={'priceListW19f2001'}
                                                        showHeader={false}
                                                        required={true}
                                                        virtual={true}
                                                        dataSource={priceListData}
                                                        value={this.state.priceList}
                                                        placeholder={''}
                                                        valueExpr="PriceListID"
                                                        displayExpr="DescriptionU"
                                                        totalItems={62}
                                                        onActionWhenSelectChange={(data) => {
                                                            this.onChangePriceList(data)
                                                        }}
                                                    >
                                                        <Column dataField={'PriceListID'} caption="" visible={false}
                                                                dataType={'string'}/>
                                                        <Column dataField={'DescriptionU'} caption=""
                                                                dataType={'string'}/>
                                                    </Combo>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={4} lg={4}>
                                    {
                                        showPass && <FormGroup>
                                            <Col md={12} lg={4}>
                                                <div>
                                                    <img id={'passStampW19F2001'} alt={''}
                                                         src={require('./../../images/approve-stamp.png')}/>
                                                    <span id={'passInfoContainerW19F2001'}>
                                       <div id={'passInfoW19F2001'}>
                                           <div>
                                                   <img alt={''}
                                                        src={imageUser ? imageUser : require('./../../images/icon-user-default.png')}/>
                                           </div>
                                           <div>
                                               <span id={'approvePersonW19F2001'}>{this.state.approveUserName}</span>
                                               <div className={'text-muted'}>{moment().format("DD/MM/YYYY")}</div>
                                           </div>
                                       </div>
                                   </span>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={8}>
                                                <div id={'approveNoteW19F2001'}>
                                                    {approveNote}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    }
                                </Col>
                                <Col md={8} lg={8}>
                                    <FormGroup>
                                        <Col md={12} lg={2}>
                                            <label className={'control-label'}>{Config.lang("CRM_Ghi_chu")}</label>
                                        </Col>
                                        <Col md={12} lg={10}>
                                            <textarea
                                                disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                                id={'descriptionW19F2001'}
                                                rows={2} className={'form-control'}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col lg={12}>
                                    <span onClick={() => {
                                        this.setState({
                                            collapse1: !this.state.collapse1
                                        })
                                    }}>
                                        <i className={this.state.collapse1 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '}/>
                                        <label
                                            className={'text-blue'}>&nbsp;&nbsp;{Config.lang(!this.state.collapse1 ? 'CRM_Xem_nhieu_hon' : 'CRM_Xem_it_hon')}</label>
                                    </span>

                                </Col>
                            </Row>
                            <Collapse in={this.state.collapse1}>
                                <div>
                                    {extraInfo && this.renderExtraInfo(extraInfo)}
                                </div>
                            </Collapse>
                        </div>
                    </Collapse>
                    <FormGroup>
                        <Col md={10} lg={5}>
                            <div style={{position: 'relative'}}>
                                <InputGrid data={this.state.dataInventory}
                                           loadMore={this.loadMore}
                                           onSearch={this.onSearchClient}
                                           placeholder={Config.lang("CRM_Chon_hang_hoa")}
                                           keyExpr="InventoryID"
                                           valueExpr="InventoryID"
                                           displayExpr="InventoryName"
                                           disabled={showPass || mode === 'view' || (mode === 'add' && saveSuccess)}
                                           onRowClick={this.onRowClick}
                                           id={'cboInventoryW19F2001'}
                                >
                                    <Paging defaultPageSize={100} enabled={false}/>
                                    <Column width={40}
                                            alignment={'center'}
                                            cellRender={(e) => {
                                                return e.rowIndex + 1
                                            }}
                                    />
                                    <Column dataField={'InventoryID'} alignment={'center'}
                                            caption={Config.lang("CRM_Ma_hang_hoa")}
                                            width={150}
                                            dataType={'string'}/>
                                    <Column dataField={'InventoryName'}
                                            width={300}
                                            caption={Config.lang("CRM_Ten_hang_hoa")}

                                            dataType={'string'}/>
                                    <Column dataField={'InventoryTypeNameU'} caption={Config.lang("CRM_Loai_hang_hoa")}
                                            width={150}
                                            allowFiltering={false}
                                            dataType={'string'}/>
                                    <Column dataField={'UnitName'} caption={Config.lang("CRM_DVT")}
                                            width={60}
                                            allowFiltering={false}
                                            dataType={'string'}/>
                                </InputGrid>
                            </div>
                        </Col>
                        <Col md={2} lg={4} lgOffset={3}>
                            <FormGroup>
                                <Col md={2} lg={2}>
                                </Col>
                                <Col md={10} lg={10}>
                                    <label onClick={() => {
                                        this.setState({
                                            collapse: !this.state.collapse
                                        })
                                    }} className={'control-label pull-right'}><i style={{color: 'cornflowerblue'}}
                                                                                 className={`far ${this.state.collapse ? "fa-caret-circle-down" : "fa-caret-circle-up"} fa-lg`}/></label>
                                </Col>
                            </FormGroup>
                        </Col>

                    </FormGroup>
                    <FormGroup className={'grid-full-width'}>
                        <Col md={this.state.showDoc ? 4 : 12} sm={this.state.showDoc ? 4 : 12}
                             xl={this.state.showDoc ? 4 : 12} xs={this.state.showDoc ? 4 : 12}
                             lg={this.state.showDoc ? 4 : 12}>
                            <GridContainer
                                reference={(e) => this.gridContainer = e}
                                widthFull={true}
                                onEditorPreparing={(e) => {
                                    this.renderCboUnit(e);
                                }}
                                editing={{
                                    mode: 'cell',
                                    allowUpdating: mode !== 'view' && !showPass && !(mode === 'add' && saveSuccess)
                                }}
                                dataSource={dataGrid}
                                onSearch={this.onSearch}
                                buttonCRM={btnCRM}
                            >
                                <Column
                                    width={50}
                                    alignment={'center'}
                                    cellRender={(data) => data.rowIndex + 1}
                                />
                                <Column
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    cellRender={(data) => this.renderAction(data)}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={120}
                                />
                                <Column
                                    dataField={'InventoryID'}
                                    caption={Config.lang("CRM_Ma_hang_hoa")}
                                    width={180}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'ItemNameU'}
                                    caption={Config.lang("CRM_Ten_hang_hoa")}
                                />
                                <Column
                                    dataField={'UnitID'}
                                    caption={Config.lang("CRM_DVT")}
                                    width={100}
                                    cellRender={(e) => {
                                        const {data} = e;
                                        return data.UnitName
                                    }}
                                >
                                    <Lookup displayExpr={'UnitName'} valueExpr={'UnitID'}/>
                                </Column>
                                <Column
                                    dataField={'Quantity'}
                                    caption={Config.lang("CRM_So_luong")}
                                    dataType={'number'}
                                    visible={true}
                                    width={120}/>
                                <Column
                                    dataField={'CQuantity'}
                                    caption={Config.lang("CRM_So_luong_QD")}
                                    dataType={'number'}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    width={120}/>
                                <Column
                                    dataField={'UnitPriceOnD14'}
                                    width={150}
                                    dataType={'number'}
                                    allowEditing={false}
                                    format={'#,##0.##'}
                                    caption={Config.lang("CRM_Gia_goc")}/>
                                <Column
                                    dataField={'OUnitPrice'}
                                    width={150}
                                    dataType={'number'}
                                    allowEditing={true}
                                    format={'#,##0.##'}
                                    caption={Config.lang("CRM_Don_gia")}/>
                                <Column
                                    dataField={'OAmountTmp'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    dataType={'number'}
                                    caption={Config.lang("CRM_Thanh_tien")}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'CAmountTmp'}
                                    caption={Config.lang("CRM_Thanh_tien_QD")}
                                    dataType={'number'}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    allowEditing={false}
                                    format={'#,##0.##'}
                                    width={120}/>
                                <Column
                                    dataField={'VATRate'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    caption={`${Config.lang("CRM_Thue_suat")} %`}
                                    format={'#,##0.##'}
                                    dataType={'number'}
                                    width={120}/>
                                <Column
                                    dataField={'OVAT'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    caption={Config.lang("CRM_Tien_thue")}
                                    dataType={'number'}
                                    allowEditing={false}
                                    width={120}/>
                                <Column
                                    dataField={'CVAT'}
                                    caption={Config.lang("CRM_Tien_thue_QD")}
                                    dataType={'number'}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    allowEditing={false}
                                    format={'#,##0.##'}
                                    width={120}/>
                                <Column
                                    dataField={'RateReduce'}
                                    dataType={'number'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    caption={`${Config.lang("CRM_Chiet_khau")} %`}
                                    width={120}/>
                                <Column
                                    dataField={'OriginalReduce'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    caption={Config.lang("CRM_Tien_CK")}
                                    dataType={'number'}
                                    allowEditing={false}
                                    width={300}/>
                                <Column
                                    dataField={'CReduce'}
                                    caption={Config.lang("CRM_Tien_CK_QD")}
                                    dataType={'number'}
                                    format={'#,##0.##'}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    allowEditing={false}
                                />

                                {column.filter((value) => {
                                    return value.props.dataField.includes('ACol')
                                })}
                                <Column
                                    dataField={'AdjustedUnitPrice'}
                                    caption={Config.lang("CRM_Don_gia_sau_DC")}
                                    dataType={'number'}
                                    allowSorting={true}
                                    width={180}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'Amount'}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    caption={Config.lang("CRM_Thanh_tien_truoc_thue")}
                                    dataType={'number'}
                                    allowSorting={true}
                                    width={180}
                                    format={'#,##0.##'}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'CAmount'}
                                    caption={Config.lang("CRM_Thanh_tien_truoc_thue_QD")}
                                    allowSorting={true}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    width={180}
                                    dataType={'number'}
                                    format={'#,##0.##'}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'TotalAdjustedAmount'}
                                    caption={Config.lang("CRM_Thanh_tien_sau_thue")}
                                    dataType={'number'}
                                    allowSorting={true}
                                    visible={nNumValue ? nNumValue.NumValue === 1 : false}
                                    width={180}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField={'TotalAdjustedCAmount'}
                                    caption={Config.lang("CRM_Thanh_tien_sau_thue_QD")}
                                    dataType={'number'}
                                    allowSorting={true}
                                    width={180}
                                    format={'#,##0.##'}
                                    visible={this.state.showColumn && nNumValue ? nNumValue.NumValue === 1 : false}
                                    allowEditing={false}
                                />
                                {column.filter((value) => {
                                    return value.props.dataField.includes('VRef')
                                })}
                                <Column
                                    dataField={'NotesU'}
                                    dataType={'string'}
                                    width={300}
                                    caption={Config.lang("CRM_Ghi_chu")}
                                />
                                {totalItem}
                            </GridContainer>
                        </Col>
                        {this.state.showDoc &&
                        <Col md={8} sm={8} xl={8} xs={8} lg={8}>
                            {/*Cho nay de gan tab document*/}
                        </Col>
                        }
                    </FormGroup>
                </Form>
                {this.state.loading && <Loading/>}
                {showPopupClose &&
                <W19F2001Close quotationID={quotationID}
                               validFrom={this.state.validFrom}
                               onHide={(e) => this.hidePopupClose(e)}
                />
                }
            </div>
        )
    }
}

export default connect(state => ({
        cboStatus: state.w19f2001.cboStatus,
        dataShare: state.w19f2001.dataShare,
        dataMaster: state.w19f2001.dataMaster,
        perApprove: state.w19f2001.perApprove,
        dataGrid: state.w18f1015.dataGrid,
        numValue: state.w19f2001.numValue,
        extraInfo: state.w19f2001.extraInfo,
    }),
    (dispatch) => ({
        w19F2001Actions: bindActionCreators(w19F2001Actions, dispatch),
        w18F1015Actions: bindActionCreators(w18F1015Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F2001Page);
