/**
 * @copyright 2020 @ DigiNet
 * @author TRANGHOANG
 * @create 2020/04/16 10:37
 */

import React from 'react';
import {
    FormGroup,
    Form,
    Col,
} from 'react-bootstrap';
import {connect} from "react-redux";
import ButtonSave from "../common/button/button-save";
import PopupView from "../popup/popup-view";

import EventTracking from "../common/tracking/event";
import Config from "../../config";
import * as w19F2001Actions from "../../actions/w19/w19F2001/w19F2001-actions";
import {bindActionCreators} from "redux";
import notify from "devextreme/ui/notify";
import {DateBox} from 'devextreme-react';
import moment from 'moment';

const currentTime = {
    hour: moment().get('hour'),
    minute: moment().get('minute'),
    second: moment().get('second'),
    millisecond: moment().get('millisecond')
};

class W19F2001_Close extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //closeDate không cho chọn giờ nhưng lưu giờ hiện tại - Thắc mắc hỏi BA
            closeDate: moment().format(),
            closeChecked: false,
            isSaved: false
        };
    }

    onHide() {
        const {onHide} = this.props;
        const {isSaved} = this.state;
        if (onHide) {
            onHide(isSaved);
        }
    }

    componentDidMount() {
        const {aStatus, approveNote} = this.props;
        this.setState({
            aStatus: aStatus === 1 ? true : !!aStatus,
            approveNote: approveNote
        });

    }

    saveDataPop = () => {
        const {validFrom, quotationID} = this.props;
        const {closeDate} = this.state;
        if (closeDate < validFrom) {
            Config.popup.show('INFO', Config.lang('CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc'));
            this.setState({
                closeDate: null
            });
            document.querySelector('#closeDateW19F2001 .dx-texteditor-input').focus();
            return false;
        }
        const param = {
            QuotationID: quotationID,
            ClosingDate: closeDate ? moment(closeDate).set(currentTime).format('YYYY-MM-DD HH:mm:ss.SSS'): closeDate,
            ClosingReason: this.refs['closeReasonW19F2001'].value
        };
        this.props.w19F2001Actions.closeQuotation(param, (error) => {
            if (error) {
                Config.popup.show('INFO', "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu");
                return false
            }
            this.setState({
                isSaved:true
            });
            notify(Config.lang('CRM_Dong_bao_gia_thanh_cong'), "success", 400);
        })
    };

    changeCloseDate = (e) => {
        this.setState({
            closeDate: e.value ? moment(e.value).format() : null
        })
    };

    render() {
        const {closeDate,isSaved} = this.state;
        return (
            <PopupView
                title={Config.lang('CRM_Dong_bao_gia')}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>
                <EventTracking action={"FORM"} label={"W19F2001_Popup"}/>

                <div className="page-container">
                    <Form horizontal={true} id={'W19F2001_Popup'}>
                        <FormGroup className={'mgt5'}>
                            <Col lg={2}>
                                <label className={'control-label'}>{Config.lang('CRM_Ngay_dong')}</label>
                            </Col>
                            <Col lg={4}>
                                <DateBox
                                    disabled={isSaved}
                                    width={"100%"}
                                    dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                    id={'closeDateW19F2001'}
                                    ref={'closeDateW19F2001'}
                                    openOnFieldClick={true}
                                    useMaskBehavior={true}
                                    value={closeDate}
                                    onValueChanged={this.changeCloseDate}
                                    showClearButton={true}
                                    type={'date'}
                                    pickerType={"calendar"}
                                    showAnalogClock={false}
                                    displayFormat={'dd/MM/y'}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={2}>
                                <label className={'control-label'}>{Config.lang("CRM_Ly_do_dong")}</label>
                            </Col>
                            <Col lg={10}>
                                <textarea disabled={isSaved} ref={'closeReasonW19F2001'} className={'form-control'}/>
                            </Col>
                        </FormGroup>
                        <FormGroup className={'pull-right'}>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <ButtonSave disabled={isSaved} name={'Lưu'}
                                            onClick={() => this.saveDataPop()}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </PopupView>
        )
    }
}

export default connect(() => ({}),
    (dispatch) => ({
        w19F2001Actions: bindActionCreators(w19F2001Actions, dispatch)


    })
)(W19F2001_Close);
