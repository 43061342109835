/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */
import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import PageContainer from '../common/page-container/page-container'
import {Form} from "react-bootstrap";
import ButtonBack from "../common/button/button-back";
import ButtonSave from "../common/button/button-save";
import {DateBox} from "devextreme-react";
import {CustomRule, Validator} from 'devextreme-react/validator';
import Select from 'react-select';
import GridContainer from "../common/grid-container/grid-container";
import {Column, Selection} from "devextreme-react/data-grid";
import Config from "../../config";
import notify from "devextreme/ui/notify";
import {browserHistory} from "react-router";
import moment from 'moment';
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import * as action from '../../actions/w20/w20F1002/w20f1002-actions';
import * as userActions from "../../actions/user-actions";
import ButtonSearch from "../common/button/button-search";
import {Loading} from "../common/loading/loading";
import {Row, Col, FormGroup} from 'diginet-core-ui/components';

export class W20F1002 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            companyID: '',
            companyName: '',
            salesPersonID: '',
            address: '',
            email: '',
            companyStatusData: [],
            groupSalesID: '',
            createDateFrom: null,
            createDateTo: null,
            birthdate: '',
            birthdateFrom: null,
            birthdateTo: null,
            customerStatus: [],
            customerLevelData: [],
            dataGrid: [],
            customerLevel: '',
            loading: false,
            iPermission:0,
            contactBirthdayFrom: null,
            contactBirthdayTo: null
        };

        this.filter = {
            limit: 15,
            skip: 0,
        };
        this.selectedData = [];
        this.timer = null;
    }

    getCompanyStatus = () => {
        this.props.action.getCompanyStatus((error, data) => {
            if (error) return false;
            const dataSource = data && data.map((value) => {
                return {
                    value: value.CompanyStatusID,
                    label: value.CompanyStatusName
                }
            });
            this.setState({
                companyStatusData: dataSource
            })
        })
    };

    getCustomerLevel = () => {
        this.props.action.getCustomerLevel((error, data) => {
            if (error) return false;
            const dataSource = data.map((value) => {
                return {
                    value: value.CustomerLevelID,
                    label: value.CustomerLevelName
                }
            });

            this.setState({
                customerLevelData: dataSource
            })
        })
    };

    loadGrid = () => {
        const {id} = this.getInfo();
        const {
            companyID, email, groupSalesID, customerLevel, createDateFrom, createDateTo, customerStatus,
            birthdateFrom, birthdateTo, salesPersonID, contactBirthdayFrom, contactBirthdayTo
        } = this.state;

        if (createDateFrom > createDateTo) {
            Config.popup.show('INFO', Config.lang('CRM_Ngay_den_phai_lon_hon_ngay_tu'));
            return false;
        }
        const {skip, limit} = this.filter;
        const param = {
            ListCustomerID: id,
            CompanyID: companyID,
            CompanyName: this.getDomValue('companyNameW20F1002'),
            SalesPersonID: salesPersonID,
            Telephone: this.getDomValue('phoneW20F1002'),
            Address: this.getDomValue('addressW20F1002'),
            Email: email,
            GroupSalesID: groupSalesID,
            CompanyKindID: customerLevel && customerLevel.value,
            CompanyStatus: customerStatus && customerStatus.value,
            CreateDateFrom: createDateFrom,
            CreateDateTo: createDateTo,
            BirthDateFrom: birthdateFrom,
            BirthDateTo: birthdateTo,
            ContactBirthdayFrom: contactBirthdayFrom,
            ContactBirthdayTo: contactBirthdayTo,
            skip: skip,
            limit: limit
        };

        this.props.action.getGrid(param, (error, data) => {
            if (error) return false;
            if (data) this.setState({
                dataGrid: {
                    data: data.rows,
                    total: data.total
                },
            })

        })
    };

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.getCompanyStatus();
        this.getCustomerLevel();
        this.loadGrid();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1922", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    changeCreateDate = e => {
        this.setState({
            createDateFrom: e.DateFrom ? moment(e.DateFrom).format('YYYY-MM-DD') : null,
            createDateTo: e.DateTo ? moment(e.DateTo).format('YYYY-MM-DD') : null
        })
    };

    changeContactBirthday = e => {
        this.setState({
            contactBirthdayFrom: e.DateFrom ? moment(e.DateFrom).format('YYYY-MM-DD') : null,
            contactBirthdayTo: e.DateTo ? moment(e.DateTo).format('YYYY-MM-DD') : null
        })
    };

    changeBirthDate = e => {
        this.setState({
            birthdateFrom: e.DateFrom ? moment(e.DateFrom).format('YYYY-MM-DD') : null,
            birthdateTo: e.DateTo ? moment(e.DateTo).format('YYYY-MM-DD') : null
        })
    };

    changeGroupsales = e => {
        this.setState({
            groupSalesID: e
        })
    };

    changeSalesperson = e => {
        this.setState({
            salesPersonID: e ? e.SalesPersonID : null
        })
    };

    changeCompanyID = e => {
        this.setState({
            companyID: Config.replaceSymbol(e.target.value)
        })
    };

    getDomValue = (id) => {
        return document.getElementById(id).value;
    };

    saveData = () => {
        const {id} = this.getInfo();

        if (this.selectedData.length === 0) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_chua_chon_khach_hang"));
            return false;
        }
        const param = {
            ListCustomerID: id,
            ListCompanyID: JSON.stringify(this.selectedData),
        };

        this.setState({
            loading: true
        });
        this.props.action.saveData(param, (error) => {
            this.setState({
                loading: false
            });
            if (error) {
                Config.popup.show('INFO', Config.lang('CRM_Co_loi_khi_luu'));
                return false;
            }
            this.filter.skip = 0;
            this.loadGrid();
            this.gridContainer.clearSelectRow();
            this.selectedData=[];
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
        })
    };

    goBack = () => {
        browserHistory.goBack();
    };

    changeCustomerLevel = e => {
        this.setState({
            customerLevel: e ? {
                value: e.value,
                label: e.label
            } : null
        })
    };

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('id')) {
            return {id: url.get('id')};
        } else if (location && location.state) {
            return {id: location.state.id, mode: location.state.mode}
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W20F1000');
                return null;
            }
            return false;
        }
    };

    changeCustomerStatus = e => {
        this.setState({
            customerStatus: e ? {
                value: e.value,
                label: e.label
            } : null
        })
    };

    changeEmail = e => {
        this.setState({
            email: e.target.value
        })
    };

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSelectionChanged = (e, selectedData) => {
        this.selectedData = selectedData && selectedData.map((value) => {
            return value.CompanyID
        });
    };

    redirect(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        let state = {};
        state.CompanyID = data.CompanyID;
        state.Permission = data.Permission;

        if (data.IsCompetitor === 1) {
            state.type = 'Competitor';
        } else if (data.IsCustomer === 1) {
            state.type = 'Customers';

        } else if (data.IsPartner === 1) {
            state.type = 'Partner';
        }
        const redirect = () => {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: state
            });
        };

        /* eslint-disable */
        return (
            <div><a onClick={redirect}>{name}</a></div>
        );
        /* eslint-enable */
    }

    changeChildDate = (e) => {
        const name = e.component.option('name');
        const stateName = name.replace('W20F1002', '');

        this.setState({
            [stateName]: e.value
        })
    };

    searchGrid = () => {
        const birthRef = this.refs['refBirthDFW20F1002'].instance.option('isValid');
        const createRef = this.refs['refCreateDFW20F1002'].instance.option('isValid');
        const contactBirthdayRef = this.refs['refContactBirthdayDFW20F1002'].instance.option('isValid');

        if (!birthRef || !createRef || !contactBirthdayRef) {
            Config.popup.show('INFO', Config.lang("CRM_Loi_dieu_kien_loc"));
            return false;
        }
        this.filter.skip = 0;
        this.loadGrid()
    };

    validateData = (e, compareValue, mode) => {
        e.rule.message = '';
        if (!e.value && !compareValue) {
            return true
        } else {
            if (!e.value) {
                e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
                return false;
            }
            if (mode === 1) {
                e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
                return e.value <= compareValue;
            } else {
                e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
                return e.value >= compareValue;
            }
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const {
            companyStatusData, customerLevelData, createDateFrom, createDateTo, birthdateFrom, birthdateTo,
            salesPersonID, groupSalesID, companyID, customerLevel, customerStatus, email, dataGrid, loading,
            iPermission, contactBirthdayFrom, contactBirthdayTo
        } = this.state;

        return (
            <PageContainer
                title={Config.lang('CRM_Tim_kiem_khach_hang')}
                tracking={'W20F1002'}
            >
                {loading && <Loading/>}
                <Form horizontal={true} id={'frmW20F1002'}>
                    <FormGroup>
                        <Col lg={12} md={12}>
                            <ButtonBack onClick={this.goBack} name={Config.lang('CRM_Quay_lai')}/>
                            <ButtonSave onClick={this.saveData} disabled={iPermission < 1} name={Config.lang('CRM_Luu')}/>
                        </Col>
                    </FormGroup>
                    <Row>
                        <Col lg={3} md={3}>
                            <Row>
                                <Col lg={12} md={12}>
                                    <input className={'form-control'}
                                           value={companyID}
                                           onPaste={this.changeCompanyID}
                                           onChange={this.changeCompanyID}
                                           placeholder={Config.lang("CRM_Ma_khach_hang")}/>
                                </Col>
                                <Col lg={12} md={12}>
                                    <input id={'companyNameW20F1002'} className={'form-control'}
                                           placeholder={Config.lang("CRM_Ten_khach_hang")}/>
                                </Col>
                                <Col lg={12} md={12}>
                                    <DropDownSaleGroup showClearButton={true}
                                                       placeholder={Config.lang("CRM_Nhom_kinh_doanh")}
                                                       value={groupSalesID}
                                                       onChange={this.changeGroupsales}
                                    />
                                </Col>
                                <Col lg={12} md={12}>
                                    <DropDownSalePerson
                                        showClearButton={true}
                                        groupSalesID={groupSalesID}
                                        placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                        onChange={this.changeSalesperson}
                                        value={salesPersonID}
                                    />
                                </Col>
                                <Col lg={12} md={12}>
                                    <input id={'addressW20F1002'}
                                           className={'form-control'}
                                           placeholder={Config.lang("CRM_Dia_chi")}/>
                                </Col>
                                <Col lg={12} md={12}>
                                    <input id={'phoneW20F1002'}
                                           className={'form-control'}
                                           placeholder={Config.lang("CRM_Dien_thoai")}/>
                                </Col>
                                <Col lg={12} md={12}>
                                    <input value={email}
                                           onChange={this.changeEmail}
                                           id={'emailW20F1002'}
                                           className={'form-control'}
                                           placeholder={"Email"}/>
                                </Col>
                                <Col lg={12} md={12}>
                                    <Select
                                        isClearable={true}
                                        options={customerLevelData}
                                        placeholder={Config.lang('CRM_Phan_loai_khach_hang')}
                                        value={customerLevel}
                                        onChange={this.changeCustomerLevel}
                                        noOptionsMessage={() => {
                                            return Config.lang('CRM_Nhom_kinh_doanh');
                                        }}
                                    />
                                </Col>
                                <Col lg={12} md={12}>
                                    <Select
                                        isClearable={true}
                                        placeholder={Config.lang('CRM_Trang_thai')}
                                        value={customerStatus}
                                        onChange={this.changeCustomerStatus}
                                        options={companyStatusData}
                                        noOptionsMessage={() => {
                                            return Config.lang('CRM_Nhom_kinh_doanh');
                                        }}
                                    />
                                </Col>
                                <Col lg={12} md={12}>
                                    <label>{Config.lang("Ngay_tao")}</label>
                                    <DropDownTypeTime
                                        ref={'refCreateW20F1002'}
                                        disabled={false}
                                        listType={'W20F1002_TypeTime'}
                                        placeholder={Config.lang('Chon')}
                                        onChange={this.changeCreateDate}
                                    />
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        ref={'refCreateDFW20F1002'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'createDateFromW20F1002'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={'100%'}
                                        onValueChanged={this.changeChildDate}
                                        value={createDateFrom}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, createDateTo, 1)}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'createDateToW20F1002'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={'100%'}
                                        onValueChanged={this.changeChildDate}
                                        value={createDateTo}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, createDateFrom, 2)}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                                <Col lg={12}>
                                    <label>{Config.lang("Ngay_thanh_lap")}</label>
                                    <DropDownTypeTime
                                        disabled={false}
                                        listType={'W20F1002_TypeTime'}
                                        placeholder={Config.lang('Chon')}
                                        onChange={this.changeBirthDate}
                                    />
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        ref={'refBirthDFW20F1002'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'birthdateFromW20F1002'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        onValueChanged={this.changeChildDate}
                                        value={birthdateFrom}
                                        width={'100%'}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, birthdateTo, 1)}/>
                                        </Validator>

                                    </DateBox>
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'birthdateToW20F1002'}
                                        onValueChanged={this.changeChildDate}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        value={birthdateTo}
                                        width={'100%'}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, birthdateFrom, 2)}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                                <Col lg={12} md={12}>
                                    <label>{Config.lang("Ngay_sinh")}</label>
                                    <DropDownTypeTime
                                        disabled={false}
                                        listType={'W20F1002_TypeTime'}
                                        placeholder={Config.lang('Chon')}
                                        onChange={this.changeContactBirthday}
                                    />
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        ref={'refContactBirthdayDFW20F1002'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'contactBirthdayFromW20F1002'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={'100%'}
                                        onValueChanged={this.changeChildDate}
                                        value={contactBirthdayFrom}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, contactBirthdayTo, 1)}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                                <Col className={'responsiveColumnW20F1002'} lg={6}>
                                    <DateBox
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        displayFormat={'dd/MM/y'}
                                        name={'contactBirthdayToW20F1002'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        width={'100%'}
                                        onValueChanged={this.changeChildDate}
                                        value={contactBirthdayTo}>
                                        <Validator>
                                            <CustomRule
                                                validationCallback={(e) => this.validateData(e, contactBirthdayFrom, 2)}/>
                                        </Validator>
                                    </DateBox>
                                </Col>
                                <Col lg={12} md={12}>
                                    <ButtonSearch
                                        onClick={this.searchGrid}
                                        className={'pull-right'}
                                        name={Config.lang('CRM_Tim_kiem')}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={9} md={9}>
                            <GridContainer
                                ref={e => this.gridContainer = e}
                                dataSource={dataGrid && dataGrid.data}
                                itemPerPage={this.filter.limit}
                                totalItems={dataGrid && dataGrid.total}
                                skipPerPage={this.filter.skip}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                listPerPage={[15, 20, 30, 50]}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                <Selection showCheckBoxesMode={'always'}
                                           mode={'multiple'}
                                           allowSelectAll={true}
                                />
                                <Column dataField={'CompanyNameU'}
                                        cellRender={this.redirect}
                                        minWidth={200}
                                        caption={Config.lang("CRM_Ten_khach_hang")}/>
                                <Column dataField={'AddressLineU'}
                                        width={400}
                                        caption={Config.lang("CRM_Dia_chi")}/>
                                <Column dataField={'Telephone'}
                                        caption={Config.lang("CRM_Dien_thoai")}/>
                                <Column dataField={'Email'}
                                        caption={'Email'}/>
                                <Column dataField={'Website'}
                                        caption={'Website'}/>
                                <Column dataField={'Email'}
                                        caption={'Email'}/>
                                <Column dataField={'SalesPersonNameU'}
                                        caption={Config.lang('CRM_Nhan_vien')}/>
                                <Column dataField={'CompanyKindName'}
                                        caption={Config.lang('CRM_Phan_loai_khach_hang')}/>
                                <Column dataField={'CompanyStatusName'}
                                        caption={Config.lang('CRM_Trang_thai')}/>
                                <Column dataField={'IndustryGroupName'}
                                        caption={Config.lang('CRM_Nhom_nganh_nghe')}/>
                                <Column dataField={'CompanySizeName'}
                                        caption={Config.lang('CRM_Quy_mo_cong_ty')}/>
                                <Column dataField={'CustomerGroupName'}
                                        caption={Config.lang('CRM_Nhom_khach_hang')}/>
                                <Column dataField={'CreateDate'}
                                        caption={Config.lang('CRM_Ngay_tao')}
                                        alignment={'center'}
                                        dataType={'date'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'DateOfBirth'}
                                        caption={Config.lang('CRM_Ngay_thanh_lap')}
                                        dataType={'date'}
                                        alignment={'center'}
                                        format={'dd-MM-yyyy'}
                                />
                                <Column dataField={'TaxNo'}
                                        caption={Config.lang('CRM_Ma_so_thue')}/>
                                <Column dataField={'DistrictName'}
                                        caption={`${Config.lang('CRM_Quan')}/${Config.lang('CRM_Huyen')}`}/>
                                <Column dataField={'ProvinceName'}
                                        caption={`${Config.lang('CRM_Tinh')}/${Config.lang('CRM_Thanh_pho')}`}/>
                                <Column dataField={'CompanyID'}
                                        caption={Config.lang("CRM_Ma_khach_hang")}/>
                            </GridContainer>
                        </Col>
                    </Row>
                </Form>
            </PageContainer>
        )
    }
}

export default connect(null,
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        action: action
    }))(W20F1002)