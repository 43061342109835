/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */
import React from 'react';
import {connect} from "react-redux";
import PageContainer from '../common/page-container/page-container'
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import ButtonBack from "../common/button/button-back";
import ButtonSave from "../common/button/button-save";
import { DateBox, CheckBox } from "devextreme-react";
import Select from 'react-select';
import ButtonCustom from "../common/button/button-custom";
import GridContainer from "../common/grid-container/grid-container";
import {Column, Lookup} from "devextreme-react/data-grid";
import Config from "../../config";
import {bindActionCreators} from 'redux'
import * as W20F1001Actions from '../../actions/w20/w20F1001/w20f1001-actions'
import GridActionBar from "../common/action-bar/grid-action-bar";
import ActivityComponent from "../common/activity/activity-component";
import notify from "devextreme/ui/notify";
import FileUploadAsync from "../../actions/file-upload-new-async";
import {browserHistory} from "react-router";
import FileDeleteAsync from "../../actions/file-delete-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import * as w19F1031Action from "../../actions/w19/w19F1031/w19f1031-actions";
import * as userActions from "../../actions/user-actions";
import {Loading} from "../common/loading/loading";
import ButtonSaveNext from "../common/button/button-savenext";
import moment from 'moment';

export class W20F1001 extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            cboStatus: [],
            cboSource: [],
            isList: 0,
            dateFrom: moment().format('YYYY-MM-DD'),
            dateTo: null,
            listCustomerID: '',
            listCustomerName: '',
            status: '',
            source: '',
            dataGrid: [],
            history: false,
            dataAttach: [],
            attached: [],
            content: '',
            iPermission: 0,
            loading: false,
            saveSuccess: false,
            disabled: 0, 
        };
        this.oldData = null;
        this.dataCboMedia = [];
        this.OldAttachData = [];//Du lieu da dinh kem cu bi xoa;

    }

    deleteOldAttach(attachment) {
        Config.popup.show("YES_NO", Config.lang('Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {

            this.OldAttachData.push(attachment);
            const attached = this.state.attached;
            let arr;
            arr = attached.filter(function (obj) {
                return obj.AttachmentID !== attachment.AttachmentID;
            });
            this.setState({
                attached: arr
            })
        });
    }

    getCboMedia() {
        const param = {
            Language: Config.language || '84'
        };
        this.props.W20F1001Actions.getCboMedia(param, (error, data) => {
            if (error) return false;
            this.dataCboMedia = [...data];
        })
    }

    getDefaultStatus = (dataSource) => {
        let dataF;

        dataF = dataSource && dataSource.filter((value) => {
            return value.IsDefault === 1
        });
        if (dataF.length > 0) {
            return dataF[0]
        }
        return dataF
    };

    getDefaultSource = (dataSource) => {
        let dataF;

        dataF = dataSource && dataSource.filter((value) => {
            return value.DisplayOrder === 0;
        });
        if (dataF.length > 0) {
            return dataF[0]
        }
        return dataF
    };

    getCboStatus() {
        const param = {
            Language: Config.language || '84'
        };

        this.props.W20F1001Actions.getCboStatus(param, (error, data) => {
            if (error) return false;
            let dataSource = [];
            const param = {};

            data && data.forEach((value) => {
                dataSource.push({
                    value: value.ID,
                    label: value.Name,
                    IsDefault: value.IsDefault
                });

            });
            param.status = this.getDefaultStatus(dataSource);
            param.cboStatus = dataSource;
            this.setState(param)
        })
    }

    getCboSource() {
        const { mode , id } = this.getInfo();
        const param = {
            ListCustomerID: mode === 'edit' ? id : ''
        };

        this.props.W20F1001Actions.getCboSource(param, (error, data) => {
            if (error) return false;
            let dataSource = [];
            const param = {};

            data && data.forEach((value) => {
                dataSource.push({
                    value: value.ListCustomerID,
                    label: value.ListCustomerName,
                    DisplayOrder: value.DisplayOrder
                });

            });
            param.source = this.getDefaultSource(dataSource);
            param.cboSource = dataSource;
            this.setState(param)
        })
    }

    changeDateFrom = (e) => {
        this.setState({
            dateFrom: e.value
        })
    };

    changeDateTo = (e) => {
        this.setState({
            dateTo: e.value,
        })
    };

    componentWillMount() {
        this.getCboMedia();
    }

    getAttach(id) {
        const param = {
            KeyID: id
        };

        this.props.W20F1001Actions.getAttach(param, (error, data) => {
            if (error) return false;
            if (data) {
                this.setState({
                    attached: data
                })
            }
        })
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        const {mode, id} = this.getInfo();
        this.getCboStatus();
        await this.getCboSource();
        if (mode !== 'add') {
            this.loadDataMaster();
            this.getAttach(id);
        }
    };

    changelistCustomerID = (e) => {
        this.setState({
            listCustomerID: e.target.value
        })
    };

    changelistCustomerName = (e) => {
        this.setState({
            listCustomerName: e.target.value
        })
    };

    loadDataMaster() {
        if (!this.getInfo()) return;
        const {id} = this.getInfo();
        const param = {
            ListCustomerID: id
        };

        this.props.W20F1001Actions.getDataMaster(param, (error, data) => {
            if (error) return false;

            // Get CustomerNameSource from CustomerIDSource
            const { cboSource } = this.state;
            data.CustomerNameSource = cboSource.filter((source, index) => {
                // Remove source if cboSource.ListCustomerID === data.listCustomerID
                if (source.value === data.ListCustomerID) {
                    cboSource.splice(index, 1);
                }
                return source.value === data.CustomerIDSource;
            })[0];

            data.DateFrom = data.DateFrom ? moment(data.DateFrom).format('YYYY-MM-DD') : null;
            data.DateTo = data.DateTo ? moment(data.DateTo).format('YYYY-MM-DD') : null;
            this.oldData = data;
            this.setState({
                dateFrom: data.DateFrom,
                dateTo: data.DateTo,
                listCustomerID: data.ListCustomerID,
                listCustomerName: data.ListCustomerName,
                status: {
                    value: data.Status,
                    label: data.StatusName
                },
                source: {
                    value: data.CustomerIDSource,
                    label: data.CustomerNameSource && data.CustomerNameSource.label
                },
                isList: data.IsList,
                disabled: data.Disabled,
                content: data.Content,
                dataGrid: data.media,
            })
        })
    }

    async saveAttach(keyID) {
        if (!this.getInfo()) return;
        const {dataAttach} = this.state;
        const OldAttachData = this.OldAttachData;
        const {mode} = this.getInfo();
        let status = 200;
        let attached = [...this.state.attached];

        if (dataAttach.length > 0) {
            for (let i = 0; i < dataAttach.length; i++) {
                const exp = dataAttach[i].file.name.split('.');
                const paramAttach = {
                    'KeyID': keyID,
                    'FileName': dataAttach[i].file.name,
                    'FileSize': dataAttach[i].file.size,
                    'FileExp': exp[exp.length - 1],
                    'TableName': 'D17T1920'
                };
                const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                if (upload && upload.code === 200) {
                    dataAttach[i].AttachmentID = upload.KeyID;
                    attached.push(dataAttach[i]);
                } else {
                    status = 400;
                    break;
                }
            }
            this.setState({
                dataAttach: [],
                attached: attached,
            });
        }
        if (mode === 'edit') {
            if (OldAttachData.length > 0) {
                let paramDeleteAttach;
                let paramRemoveAttach;
                for (let i = 0; i < OldAttachData.length; i++) {
                    paramDeleteAttach = {
                        'AttachmentID': OldAttachData[i].AttachmentID
                    };
                    paramRemoveAttach = {
                        'path': OldAttachData[i].URL
                    };
                    await Promise.all([
                        FileDeleteAsync(paramDeleteAttach),
                        FileRemoveAsync(paramRemoveAttach)
                    ]);
                }
                OldAttachData.length = 0;
            }
        }
        return status
    }

    saveEdit() {
        const {listCustomerName, status, source, isList, content, dateFrom, dateTo, listCustomerID, disabled} = this.state;
        let dataGrid = [...this.state.dataGrid];

        dataGrid.forEach((value) => {
            delete value.key
        });
        this.setState({
            loading: true,
        });
        const param = {
            ListCustomerID: listCustomerID,
            ListCustomerName: listCustomerName ? listCustomerName : '%',
            Status: status ? status.value : '',
            CustomerIDSource: source ? source.value : '',
            IsList: isList ? 1 : 0,
            Disabled: disabled ? 1 : 0,
            Content: content,
            DateFrom: dateFrom,
            DateTo: dateTo,
            dataMedia: JSON.stringify(dataGrid)
        };

        this.props.W20F1001Actions.saveData(param, 'edit', async (error) => {

            if (error) {
                Config.popup.show('INFO', Config.lang('Co_Loi_khi_luu'));
                this.setState({
                    loading: false
                });
                return false;
            }
            const [attachStatus, historyStatus] = await Promise.all([
                this.saveAttach(listCustomerID),
                this.saveHistory(param),
            ]);
            if (attachStatus === 200 && historyStatus === 200) {
                this.setState({
                    saveSuccess: true
                });

                this.loadDataMaster();
                if (this.refs['activityRef']) {
                    this.refs['activityRef'].getWrappedInstance().resetHistory();
                }

                notify(Config.lang("Luu_thanh_cong"), "success", 2000);
                this.setState({
                    loading: false
                })
            }
        })
    }

    saveAddNew() {
        const {listCustomerName, status, source, isList, content, dateFrom, dateTo, dataGrid, disabled} = this.state;
        const param = {
            ListCustomerName: listCustomerName,
            Status: status ? status.value : '',
            CustomerIDSource: source ? source.value : '',
            IsList: isList ? 1 : 0,
            Disabled: disabled ? 1 : 0,
            Content: content,
            DateFrom: dateFrom,
            DateTo: dateTo,
            dataMedia: JSON.stringify(dataGrid)
        };

        this.setState({
            loading: true,
        });
        this.props.W20F1001Actions.saveData(param, 'add', async (error, data) => {
            if (error) {
                Config.popup.show('INFO', Config.lang('Co_Loi_khi_luu'));
                this.setState({
                    loading: false
                });
                return false;
            }
            this.setState({
                listCustomerID: data.ListCustomerID
            });
            param.ListCustomerID = data.ListCustomerID;
            const [attachStatus, historyStatus] = await Promise.all([
                this.saveAttach(data.ListCustomerID),
                this.saveHistory(param),
            ]);

            if (attachStatus === 200 && historyStatus === 200) {
                this.setState({
                    saveSuccess: true,
                }, () => {
                    if (this.refs['activityRef']) {
                        this.refs['activityRef'].getWrappedInstance().resetHistory();
                    }
                });
                notify(Config.lang("Luu_thanh_cong"), "success", 2000);
                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
                Config.popup.show("INFO", Config.lang("Co_loi_khi_dinh_kem"));
                return false;
            }
        })
    }

    saveHistory = async (newData) => {
        if (!this.getInfo()) return false;
        const {mode} = this.getInfo();
        let status = 200;
        let data = [];//Chứa giá trị bị thay đổi

        if (mode === 'edit') {
            const key = Object.keys(newData);
            key.filter((value) => {
                return (value !== 'dataMedia' && value !== 'ListCustomerID')
            }).forEach((value) => {
                if (newData[value] !== this.oldData[value]) {
                    let description = "";
                    switch (value) {
                        case "Disabled":
                            description = "KSD";
                            break;
                        case "IsList":
                            description = "La_danh_sach";
                            break;
                        default:
                            description = document.getElementById(`${value.toLowerCase()}LbW20F1001`).getAttribute('lang');
                            break;
                    }
                    let el = {}; //truyền param -tham khảo api history add
                    el.codeID = newData.ListCustomerID;
                    el.type = value !== 'Status' ? 'text' : 'highlight';
                    el.formID = 'W20F1001';
                    el.linkedTrans = '';
                    el.oldValue = value !== 'Status' ? this.oldData[value] : this.oldData['StatusName'];
                    el.newValue = value !== 'Status' ? newData[value] : this.state.status ? this.state.status.label : '';
                    el.description84 = Config.lang(description, 'vi');
                    el.description01 = Config.lang(description, 'en');
                    el.action = 1;
                    data.push(el);
                }
                return false;

            });
        } else {
            let el = {}; //truyền param -tham khảo api history add

            el.codeID = newData.ListCustomerID;
            el.formID = 'W20F1001';
            el.linkedTrans = '';
            el.oldValue = '';
            el.newValue = newData.ListCustomerName;
            el.description84 = Config.lang("Danh_sach_khach_hang", 'vi');
            el.description01 = Config.lang("Danh_sach_khach_hang", 'en');
            el.action = 0;
            data.push(el);
        }
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data)
            };

            await this.props.w19F1031Action.addHistory(param, (error) => {
                if (error) status = 400;
            });
        }
        return status
    };

    clearData = () => {
        const {cboStatus, cboSource} = this.state;
        this.setState({
            dateFrom: moment().format('YYYY-MM-DD'),
            dateTo: null,
            listCustomerID: '',
            listCustomerName: '',
            status: this.getDefaultStatus(cboStatus),
            source: this.getDefaultSource(cboSource),
            dataGrid: [],
            dataAttach: [],
            attached: [],
            content: '',
            saveSuccess: false
        });
        this.oldData = null;
        this.OldAttachData = [];//Du lieu da dinh kem cu
    };

    checkValidGrid = () => {
        let gridValid = false;

        const {dataGrid} = this.state;
        if (dataGrid.length === 0) return gridValid = true;
        const checkValid = dataGrid && dataGrid.filter((value) => {
            return !value.MediaID
        });
        if (checkValid.length > 0) {
            for (let i = 0; i < dataGrid.length; i++) {
                if (!dataGrid[i].MediaID) {
                    Config.popup.show("INFO", Config.lang('Kenh_truyen_thong_khong_duoc_de_trong'), null, null, () => {
                        this.gridContainer.instance.cellValue(i, "MediaID", null);
                        this.gridContainer.instance.saveEditData();
                        this.gridContainer.instance.focus(this.gridContainer.instance.getCellElement(i, 'MediaID'));
                        this.gridContainer.instance.editCell(i, 'MediaID')
                    });
                    break;
                }
            }
        } else {
            gridValid = true;
        }
        return gridValid;
    };

    saveData = async () => {
        if (!this.getInfo()) return;
        const {mode} = this.getInfo();
        const {dateFrom, dateTo, listCustomerName} = this.state;
        const gridValid = await this.checkValidGrid();

        if (!gridValid) return false;
        if (dateFrom > dateTo && (dateTo || dateFrom)) {
            Config.popup.show('INFO', Config.lang('Ngay_bat_dau_khong_duoc_lon_hon_ngay_ket_thuc'));
            return false;
        }
        if (!listCustomerName) {
            if (this.refs['lisCustomerNameW20F1001Ref']) this.refs['lisCustomerNameW20F1001Ref'].focus();
            Config.popup.show('INFO', Config.lang('Ten_danh_sach_khong_duoc_de_trong'));
            return false;
        }
        if (mode === 'add') {
            this.saveAddNew();
        } else {
            this.saveEdit()
        }
    };

    addGrid = () => {
        const {dataGrid} = this.state;

        this.setState({
            dataGrid: [...dataGrid, {key: dataGrid.length, MediaID: null, MediaNameU: null, Quantity: 0}]
        })
    };

    renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];

        switch (ext) {
            case 'doc':
                return (<img alt="" src={require("../../images/file/icon-doc.png")}/>);
            case 'docx':
                return (<img alt="" src={require("../../images/file/icon-docx.png")}/>);

            case 'xls':
                return (<img alt="" src={require("../../images/file/icon-xls.png")}/>);

            case 'xlsx':
                return (<img alt="" src={require("../../images/file/icon-xlsx.png")}/>);

            case 'ppt':
                return (<img alt="" src={require("../../images/file/icon-ppt.png")}/>);
            case 'pptx':
                return (<img alt="" src={require("../../images/file/icon-pptx.png")}/>);

            case 'pdf':
                return (<img alt="" src={require("../../images/file/icon-pdf.png")}/>);

            case 'txt':
                return (<img alt="" src={require("../../images/file/icon-txt.png")}/>);

            default:
                return (<img alt="" src={url} className="img-background"/>);
        }
    };

    onSearch = (text) => {
        if (this.gridContainer)
            this.gridContainer.instance.filter(['MediaNameU', 'contains', text], 'or', ['Quantity', '=', text]);
    };

    changeStatus = (e) => {
        this.setState({
            status: {
                value: e.value,
                label: e.label
            }
        })
    };

    changeSource = (e) => {
        this.setState({
            source: {
                value: e.value,
                label: e.label
            }
        })
    };

    onDelete(data) {
        Config.popup.show("YES_NO", Config.lang('Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            let dataGrid = [...this.state.dataGrid];
            dataGrid.splice(data.rowIndex, 1);
            this.setState({
                dataGrid: dataGrid
            })
        });
    }

    renderAction = (data) => {
        const {iPermission} = this.state;

        return (
            <GridActionBar
                isPer={iPermission}
                tooltipDelete={Config.lang("Xoa")}
                onDeleteAction={() => {
                    this.onDelete(data)
                }}
            />
        );
    };
    updateCell = (e) => {
        const {row} = e;
        let isDynamicCombo;

        if (e.dataField === 'MediaID') isDynamicCombo = true;
        if (isDynamicCombo) {
            e.editorOptions.onValueChanged = (arg) => {
                let mediaName;
                this.dataCboMedia.forEach((value) => {
                    if (value.ID === arg.value) mediaName = value.Name;
                });
                row.data.MediaID = arg.value;
                row.data.MediaNameU = mediaName;
                e.setValue(arg.value);
                e.component.cellValue(e.row.rowIndex, 'MediaNameU', mediaName)
            };
        } else {
            e.editorOptions.onFocusIn = args => {
                const input = args.element.querySelector('.dx-texteditor-input');
                input.select();
            };
        }

    };

    showHistory() {
        this.setState({
            history: !this.state.history
        })
    }

    checkFileType(filetype) {
        const allowedExtensions = [
            'text/plain',
            'image/jpeg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
        ];

        if (filetype.length > 0) {
            return allowedExtensions.indexOf(filetype) !== -1;

        } else {
            return false;
        }

    }

    upLoadAttach = (e) => {
        if (!e) return;
        const files = e?.target?.files || [];
        const sizeLimit = 20;

        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("Dung_luong_File_khong_duoc_lon_hon")}` + sizeLimit + ' MB');
        } else {
            let dataAttach = [...this.state.dataAttach];

            for (let file of files) {
                file.uploading = true;
                dataAttach.push({file: file, FileName: file.name, URL: window.URL.createObjectURL(file)});
            }
            this.setState({
                dataAttach: dataAttach,
            });
        }
    }

    async deleteAttach(file) {
        Config.popup.show("YES_NO", Config.lang('Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            const {dataAttach} = this.state;
            let arr = dataAttach.filter((value) => {
                return value.FileName !== file.FileName;
            });

            this.setState({
                dataAttach: arr
            });
        });
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1920", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('id') && url.get('mode')) {
            return {id: url.get('id'), mode: url.get('mode'), CompanyID: url.get('CompanyID')};
        } else if (location && location.state) {
            return {id: location.state.id, mode: location.state.mode, CompanyID: location.state.CompanyID}
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W20F1000');
                return null;
            }
            return false;
        }
    };

    changeContent = (e) => {
        this.setState({
            content: e.target.value
        })
    };

    goBack = () => {
        browserHistory.goBack();
    };

    render() {
        if (!this.getInfo(true)) return null;
        const {id, mode} = this.getInfo();
        const { cboStatus, cboSource, isList, dateFrom, dateTo, listCustomerID, listCustomerName, status, source, dataGrid,
            history, dataAttach, attached, content, loading, saveSuccess, iPermission, disabled } = this.state;
        let codeID = mode !== 'add' ? id : listCustomerID;
        let allowAdding = 0;
        const editing = {
            mode: 'cell',
            allowUpdating: true
        };

        if (iPermission >= 2) {
            if ((mode === 'add' && saveSuccess) || mode === 'view') {
                allowAdding = 0;
            } else {
                allowAdding = 4;
            }
        }

        return (
            <PageContainer
                title={Config.lang('Cap_nhat_danh_sach_khach_hang')}
                tracking={'W20F1001'}
            >
                {loading && <Loading/>}
                <Form style={{position: 'relative'}} horizontal={true} id={'frmW20F1001'}>
                    <FormGroup>
                        <Col lg={12}>
                            <ButtonBack onClick={this.goBack} className={'mgr5'} name={Config.lang('Quay_lai')}/>
                            {mode !== 'view' &&
                            <ButtonCustom className={'mgr5'} icon={'fas fa-cloud-upload'} isAwesome={true}
                                          color={'text-green'} name={Config.lang("Dinh_kem")}
                                          onClick={() => {
                                              document.getElementById('attachmentW20F1001').click();
                                          }}
                                          disabled={mode === 'add' && saveSuccess}
                            />}
                            {
                                (mode === 'add' && saveSuccess) || mode === 'view' ? null :
                                    <ButtonSave name={Config.lang('Luu')} onClick={this.saveData}/>
                            }
                            {
                                /* eslint-disable */
                                <a id={'buttonHistoryW19F1031'} onClick={() => this.showHistory()}
                                   style={{float: 'right'}}
                                   className={'mgt5 text-muted'}>{Config.lang('Lich_su')}
                                    <i className={`mgl5 fas fa-chevron-${!history ? 'right' : 'left'}`}/></a>
                                /* eslint-enable */
                            }
                            {
                                mode === 'add' && saveSuccess &&
                                <ButtonSaveNext name={Config.lang("Nhap_tiep")} onClick={this.clearData}/>
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col id={'masterW20F1001'} lg={history ? 8 : 12} md={history ? 7 : 12} sm={history ? 7 : 12}>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label>{Config.lang('Ma_danh_sach')}<span
                                        className={'text-red'}>*</span></label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <Row>
                                        <Col lg={history ? 12 : 5}>
                                            <input disabled={true} value={listCustomerID}
                                                   onChange={this.changelistCustomerID}
                                                   className={'form-control'}/>
                                        </Col>
                                        <Col lg={history ? 12 : 2}>
                                            <label id={'listcustomernameLbW20F1001'} className={'control-label'}
                                                   lang={'Ten_danh_sach'}>{Config.lang('Ten_danh_sach')}<span
                                                className={'text-red'}>*</span></label>
                                        </Col>
                                        <Col lg={history ? 12 : 5}>
                                            <input ref={'lisCustomerNameW20F1001Ref'}
                                                   disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                   value={listCustomerName}
                                                   onChange={this.changelistCustomerName}
                                                   className={'form-control'}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label id={'datefromLbW20F1001'}
                                           lang={'Ngay_bat_dau'}>{Config.lang('Ngay_bat_dau')}</label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <Row>
                                        <Col lg={history ? 12 : 5}>
                                            <DateBox
                                                disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                displayFormat={'dd/MM/y'}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                value={dateFrom}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                onValueChanged={this.changeDateFrom}
                                                width={'50%'}/>
                                        </Col>
                                        <Col lg={2}>
                                            <label id={'datetoLbW20F1001'} lang={'Ngay_ket_thuc'}
                                                   className={'control-label'}>{Config.lang('Ngay_ket_thuc')}</label>
                                        </Col>
                                        <Col lg={history ? 12 : 5}>
                                            <DateBox
                                                disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                displayFormat={'dd/MM/y'}
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={dateTo}
                                                width={'50%'}
                                                onValueChanged={this.changeDateTo}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label id={'statusLbW20F1001'}
                                           lang={'Trang_thai'}>{Config.lang('Trang_thai')}</label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <Row>
                                        <Col lg={history ? 12 : 5}>
                                            <Select
                                                isDisabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                onChange={this.changeStatus}
                                                value={status}
                                                options={cboStatus}
                                                placeholder={''}
                                                noOptionsMessage={() => {
                                                    return Config.lang('Khong_tim_thay_du_lieu');
                                                }}
                                            />
                                        </Col>
                                        <Col lg={history ? 12 : 2}>
                                            <label className={'control-label'}>{Config.lang('Tai_lieu')}</label>
                                        </Col>
                                        <Col lg={history ? 12 : 5}>
                                            <div style={{resize: 'vertical', overflow: 'auto'}}
                                                 className={'mg0 display_row list-attach'}>
                                                {(dataAttach.length === 0 && attached.length === 0) && <span
                                                    className={'text-no-data'}>{Config.lang('Khong_co_du_lieu')}</span>}

                                                {attached && attached.map((value, index) => {
                                                    return (
                                                        <label className={'view-attachment mgr5 control-label small '}
                                                               key={'view-attachment' + index}>
                                                            <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                               download>
                                                                {this.renderIcon(value.FileName, value.URL)}
                                                            </a>
                                                            {value.FileName}
                                                            <i className={(mode === 'add' && saveSuccess) || mode === 'view' ? 'fas fa-trash-alt text-red deleteAttach hide' :
                                                                'fas fa-trash-alt text-red deleteAttach'}
                                                               onClick={() => {
                                                                   this.deleteOldAttach(value)
                                                               }}
                                                            />
                                                        </label>
                                                    )
                                                })}
                                                {dataAttach.map((value, index) => {
                                                    if (value.uploading) {
                                                        return (<div className={'view-loading'}>
                                                            <i key={index}
                                                               className="fas fa-spinner fa-spin"/>
                                                        </div>)
                                                    } else return (
                                                        <label className={'view-attachment mgr5 control-label small '}
                                                               key={'view-attachment1' + index}>
                                                            {this.renderIcon(value.FileName, value.URL)}
                                                            {value.FileName}
                                                            <i className={(mode === 'add' && saveSuccess) || (mode === 'view') ? 'fas fa-trash-alt text-red deleteAttach hide' :
                                                                'fas fa-trash-alt text-red deleteAttach'}
                                                               onClick={() => {
                                                                   this.deleteAttach(value)
                                                               }}/>
                                                        </label>
                                                    );
                                                })}
                                            </div>

                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label id={'contentLbW20F1001'}
                                           lang={'Noi_dung'}>{Config.lang('Noi_dung')}</label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <textarea disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                              onChange={this.changeContent}
                                              value={content} rows={5}
                                              className={'form-control'}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label id={'customeridsourceLbW20F1001'}
                                           lang={'Nguon_them_khach_hang_va_lien_he'}>{Config.lang('Nguon_them_khach_hang_va_lien_he')}</label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <Row>
                                        <Col lg={history ? 12 : 5}>
                                            <Select
                                                isDisabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                onChange={this.changeSource}
                                                value={source}
                                                options={cboSource}
                                                placeholder={''}
                                                noOptionsMessage={() => {
                                                    return Config.lang('Khong_tim_thay_du_lieu');
                                                }}
                                            />
                                        </Col>
                                        <Col lg={history ? 12 : 5}>
                                            <Row>
                                                <Col lg={6}>
                                                    <CheckBox
                                                        disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                        style={{ marginLeft: 20, marginTop: 8 }}
                                                        text={Config.lang("La_danh_sach")}
                                                        lang={Config.lang("La_danh_sach")}
                                                        id={"islistLbW20F1001"}
                                                        value={isList ? true : false}
                                                        onOptionChanged={(e) => {
                                                            this.setState({ isList: !isList })
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <CheckBox
                                                        disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                                        style={{ marginLeft: 20, marginTop: 8 }}
                                                        text={Config.lang("KSD")}
                                                        lang={Config.lang("KSD")}
                                                        id={"disabledLbW20F1001"}
                                                        value={disabled ? true : false}
                                                        onOptionChanged={(e) => {
                                                            this.setState({ disabled: !disabled });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col lg={history ? 12 : 2}>
                                    <label>{Config.lang('Kenh_truyen_thong')}</label>
                                </Col>
                                <Col lg={history ? 12 : 10}>
                                    <GridContainer
                                        reference={(e) => this.gridContainer = e}
                                        editing={editing}
                                        allowAdding={allowAdding}
                                        disabled={(mode === 'add' && saveSuccess) || mode === 'view'}
                                        onEditorPreparing={this.updateCell}
                                        onSearch={this.onSearch}
                                        onAddNew={this.addGrid}
                                        columnAutoWidth={true}
                                        dataSource={dataGrid}
                                    >
                                        <Column
                                            caption={Config.lang("STT")}
                                            cellRender={(e) => e.rowIndex + 1}
                                            alignment={'center'}
                                            allowSorting={false}
                                            allowEditing={false}
                                            showInColumnChooser={false}
                                            width={80}
                                        />
                                        <Column
                                            caption={Config.lang("Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            allowEditing={false}
                                            showInColumnChooser={false}
                                            cellRender={this.renderAction}
                                            width={100}
                                        />
                                        <Column
                                            dataField={'MediaID'}
                                            caption={Config.lang("Kenh_truyen_thong")}
                                            alignment={'left'}
                                            validationRules={[{
                                                type: "required"
                                            }]}
                                            allowSorting={true}
                                            showInColumnChooser={false}
                                        >
                                            <Lookup dataSource={this.dataCboMedia} displayExpr={'Name'}
                                                    valueExpr={'ID'}/>
                                        </Column>
                                        <Column
                                            dataField={'MediaNameU'}
                                            alignment={'left'}
                                            allowSorting={false}
                                            allowEditing={true}
                                            showInColumnChooser={false}
                                            visible={false}
                                        >
                                        </Column>
                                        <Column
                                            dataField={'Quantity'}
                                            dataType={'number'}
                                            caption={Config.lang("So_luong")}
                                            width={200}
                                            alignment={'left'}
                                            allowSorting={false}
                                            allowEditing={true}
                                            showInColumnChooser={false}
                                        />
                                    </GridContainer>
                                </Col>
                            </FormGroup>
                        </Col>
                        {history && codeID &&
                            <ActivityComponent
                                condition={{codeID: codeID}}
                                ref={'activityRef'}
                                onClose={() => this.showHistory()}
                            >
                            </ActivityComponent>}
                    </FormGroup>
                </Form>
                <input
                    id={'attachmentW20F1001'}
                    multiple={'multiple'}
                    type="file"
                    className="media_upload_input hide"
                    onChange={this.upLoadAttach}
                />
            </PageContainer>
        )
    }
}

export default connect(null,
    (dispatch) => ({
        W20F1001Actions: bindActionCreators(W20F1001Actions, dispatch),
        w19F1031Action: bindActionCreators(w19F1031Action, dispatch),
        userActions: bindActionCreators(userActions, dispatch),


    }))(W20F1001)