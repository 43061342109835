import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import React from "react";
import { Animated } from "react-animated-css";
import InlineSVG from "react-inlinesvg";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import * as W94F1000Actions from "../../actions/w94/W94F1000/W94F1000-actions";
import Config from "../../config/index";
import GridContainer from "../common/grid-container/grid-container";

const drawerWidth = '47%';
const heightHeader = 50;
const styles = theme => {
    return {
        drawer: {
            width: drawerWidth,
            maxWidth: 700,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                zIndex: '1502 !important',
            },
        },
        drawerHeader: {
            // height: heightHeader,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '5px 0'
        },
        drawerPaper: {
            width: drawerWidth,
            maxWidth: 700,
            [theme.breakpoints.down(768)]: {
                width: '100%',
            },
            top: heightHeader,
            backgroundColor: '#3e3e3e',
            opacity: 1,
            // zIndex: 1502,
            padding: '0 15px 15px 15px',
            overflow: 'hidden',
            height: 'calc(100% - 50px)'
        },
        tabs: {
            maxWidth: 'calc(100% - 60px)',
        },
        tabContent: {
            padding: '10px 0',
            minHeight: 400
        },
        itemContent: {
            lineClamp: 3,
            maxHeight: '5.2rem',
            whiteSpace: 'pre-line',
            [theme.breakpoints.down(768)]: {
                lineClamp: 2,
                maxHeight: '2.8rem',
            }
        },
        item: {
            width: 200,
            height: 260,
            borderRadius: 24,
            margin: 14,
            padding: 28,
            color: '#3E4050',
            position: 'relative',
            overflow: 'hidden',
            '& span': {
                fontSize: '4rem',
                lineHeight: '4rem',
                fontWeight: 700,
                // color: '#979797'
            },
            '&:hover .btn-hover': {
                display: 'flex !important',
            },
            '& .btn-hover': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.4)',
                justifyContent: 'center',
                display: 'none',
                '& span': {
                    border: '1px solid white',
                    borderRadius: 6,
                    fontSize: '1.7rem',
                    fontWeight: 300,
                    color: 'white',
                    width: '60%',
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'center',
                }
            },
            '& b': {
                fontSize: '2rem'
            },
            [theme.breakpoints.down(768)]: {
                width: '100%',
                height: 50,
                padding: '5px 10px',
                margin: '15px 0 0',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& span': {
                    marginRight: 10,
                    fontSize: '2.2rem',
                },
                '& b': {
                    display: 'none'
                },
                '& .btn-hover span': {
                    borderRadius: 100,
                    fontSize: '0.8rem',
                    fontWeight: 300,
                },
            },
        },
        numberIdx: {
            width: 30,
            height: 30,
            borderRadius: 6,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            fontWeight: 700
        },
        iconEye: {
            borderRadius: 100,
            padding: 3,
            margin: 'auto',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#E1E1E1'
            }
        },
        reportsListMode: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        },
        reportTitle: {
            fontSize: '18px',
            fontWeight: 700,
            marginBottom: 15,
            textTransform: 'uppercase'
        },
        customHeader: {
            '& .dx-datagrid-headers': {
                '& td': {
                    backgroundColor: '#eeeded !important'
                }
            }
        }
    }
};

class W94F1000Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDataLoadded: false,
            tabIdx: null,
            isGrid: Config.getLocalStorage("REPORT_LIST_VIEW") !== "list" // boolean
        };
        this.Language = Config.language || "84";
        this.FormID = "W94F1000";
        this.paramGetReports = {
            MReportID: ""
        }
        this.themes = [ // Bảng màu default
            {
                "MainColor": "#5A003C",
                "SubColor": "#FFF1FF",
            },
            {
                "MainColor": "#bf0653",
                "SubColor": "#f5dce6",
            },
            {
                "MainColor": "#1145d4",
                "SubColor": "#e8eeff",
            },
            {
                "MainColor": "#b81ed4",
                "SubColor": "#fae8fc",
            },
            {
                "MainColor": "#ed7002",
                "SubColor": "#faede1",
            },
            {
                "MainColor": "#753f0d",
                "SubColor": "#faede1",
            }
        ];
    }

    componentDidMount = () => {
        let { dataGroupList } = this.props;
        if (_.isEmpty(dataGroupList)) {
            this.getDataReports();
        } else {
            if (dataGroupList.constructor.name === "Object") dataGroupList = [dataGroupList];
            this.setState({
                tabIdx: dataGroupList[0].ReportGroupID,
                isGrid: Config.getLocalStorage("REPORT_LIST_VIEW") !== "list"
            });
        }
    }

    getDataReports = () => {
        let { MReportID } = this.paramGetReports;
        const { tabIdx } = this.state;
        const params = {
            MReportID
        };
        this.setState({ reportDataLoadded: true })
        this.props.W94F1000Actions.getGroupList(params, (err, data) => {
            this.setState({ reportDataLoadded: false })
            if (err) {
                let message = err.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (!_.isEmpty(data) && !tabIdx) {
                if (data.constructor.name === "Object") data = [data];
                this.setState({
                    tabIdx: data[0].ReportGroupID,
                    isGrid: Config.getLocalStorage("REPORT_LIST_VIEW") !== "list"
                });
            }
        });
    }

    onLink = (item) => {
        if (!item) return null;
        if (item.ReportType === "EMBED" && !item.EmbedCode) {
            Config.popup.show("INFO", Config.lang("No_link"));
            return;
        }
        const { ModuleID = "", MReportID = "", MReportName = "", URL = "", strSQL = "", Sheet = "", FileName = "" } = item;
        browserHistory.push({
            pathname: Config.getRootPath() + (item.ReportType === "EMBED" ? "W94F1001" : "W94F1002"),
            state: {
                URL,
                Sheet,
                strSQL,
                ModuleID,
                FileName,
                MReportID,
                MReportName,
            }
        })
    };

    renderReportName = (data) => {
        return <div>{data.value}</div>;
    };

    render() {
        const { dataGroupList, classes } = this.props;
        const { isGrid, reportDataLoadded, tabIdx } = this.state;
        this.indexHidden = 0;
        return (
            <div id={"frmW94F1000"} className={"page-container"}>
                <div className={classes.reportTitle}>{Config.lang("Danh_sach_bao_cao")}
                    <div style={{ display: 'inline-block' }} onClick={() => {
                        this.setState({ isGrid: !this.state.isGrid }, () => {
                            Config.setLocalStorage("REPORT_LIST_VIEW", this.state.isGrid ? "grid" : "list");
                            browserHistory.push({
                                pathname: Config.getRootPath() + "W94F1000",
                                state: {
                                    ReportGroupID: tabIdx,
                                    isGrid: this.state.isGrid
                                }
                            });
                        })
                    }} >
                        <InlineSVG style={{ marginLeft: 20 }} width={14} height={14} src={isGrid ? require("../../images/svg/icon-grid.svg") : require("../../images/svg/icon-th-list.svg")} />
                    </div>
                </div>

                <div className={"display_row flex-wrap"}>
                    {isGrid && (
                        <GridContainer
                            dataSource={dataGroupList}
                            selection={{
                                mode: "single",
                            }}
                            elementAttr={{ class: `report-list-grid` }}
                            loading={reportDataLoadded}
                            onCellClick={(e) => this.onLink(e.data)}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                        >
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("So_thu_tu")}
                                sortOrder={"asc"}
                                cellRender={(data) => {
                                    let indexColor = "none";
                                    if (this.themes && this.themes.length > 0) {
                                        indexColor = parseInt(data.value) % this.themes.length;
                                    }
                                    return (
                                        <span className={classes.numberIdx}
                                            style={{
                                                backgroundColor: indexColor === 'none' ? 'white' : this.themes[indexColor].SubColor,
                                                color: indexColor === 'none' ? '#707070' : this.themes[indexColor].MainColor,
                                                border: indexColor === 'none' ? '1px solid #979797' : 'none',
                                            }}>#{data.value}</span>)
                                }}
                                dataType={"number"}
                                dataField={"OrderNo"}
                                alignment={"center"}
                                width={window.innerWidth < 576 ? '120' : '140'}
                            />
                            <Column
                                cssClass={"custom-align"}
                                style={{ backgroundColor: '#b3bbc1' }}
                                caption={Config.lang("Bao_cao")}
                                cellRender={this.renderReportName}
                                dataField={"MReportName"}
                                alignment={"left"}
                            />
                        </GridContainer>
                    )}
                    {!isGrid && dataGroupList && dataGroupList.map((item, idx) => {
                        let indexColor = "none";
                        if (this.themes && this.themes.length > 0) indexColor = idx % this.themes.length;
                        return (
                            <Animated key={idx}
                                animationIn={"flipInX"}
                                animationOut={"rotateOut"}
                                isVisible={true}
                                className={`display_col jus-between ${classes.item}`}
                                style={{
                                    display: 'flex',
                                    backgroundColor: indexColor === 'none' ? 'white' : this.themes[indexColor].SubColor,
                                    border: indexColor === 'none' ? '1px solid #979797' : 'none'
                                }}
                                onClick={() => this.onLink(item)}
                            >
                                <span style={{ color: indexColor === 'none' ? '#707070' : this.themes[indexColor].MainColor }} >#{idx - this.indexHidden + 1}</span>
                                <div className={`txt-two-line ${classes.itemContent}`}>
                                    <b>{Config.lang("Bao_cao") + "\n"}</b>
                                    {item.MReportName}
                                </div>
                                <div className={"btn-hover display_row align-center"}
                                    onClick={() => this.onLink(item)}>
                                    <span>{Config.lang("Xem")}</span>
                                </div>
                            </Animated>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default compose(connect((state) => ({
    dataGroupList: state.w94F1000.dataGroupList,
}), (dispatch) => ({
    W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch)
})), withStyles(styles, { withTheme: true }))(W94F1000Page);