/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/08/12 11:00
 * @update 2019/08/12 11:00
 */

import React from 'react';
import {
    Col,
    FormGroup,
    Row,
    Collapse, InputGroup

} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19F1030Actions from "../../actions/w19/w19f1030/w19f1030-actions";
import * as userActions from "../../actions/user-actions";

import EventTracking from "../common/tracking/event";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import {DateBox, Validator} from "devextreme-react";
import ButtonAdd from "../common/button/button-add";
import InputGroupAddon from "react-bootstrap/es/InputGroupAddon";
import Combo from "../common/dropdown/combo";
import ButtonEdit from "../common/button/button-edit";
import ButtonDelete from "../common/button/button-delete";
import {CompareRule} from "devextreme-react/validator";
import {browserHistory} from "react-router";

import moment from "moment";
import 'moment/locale/vi'
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import * as w19F1031Action from "../../actions/w19/w19F1031/w19f1031-actions";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import DOMPurify from "dompurify";
import ButtonExport from "../common/button/button-export";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";

// Preview files
import Modal from '../common/modal/modal';
import FileViewer from "../common/fileviewer/fileviewer";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Slider from "react-slick";

moment.locale('vi');

const styles = {
    slick: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        position: 'absolute',
        top: "50%",
        zIndex: 2,
    }
};

const NextArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slick, left: "93.5%" }} size="medium" onClick={onClick} disableFocusRipple={true}>
            <KeyboardArrowRight fontSize="large" />
        </IconButton>
    );
}

const PrevArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slick, left: "2%" }} size="medium" onClick={onClick} disableFocusRipple={false}>
            <KeyboardArrowLeft fontSize="large" />
        </IconButton>
    );
}

class W19F1030Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            collapse: false,
            search: '',
            salePersonID: '',
            transTypeID: '',
            evaluateTypeID: '',
            typeTime: '',
            CreateDateFrom: '',
            CreateDateTo: '',
            groupSalesID: '',
			currentSlide: 0
        };
        this.filter = {
            skip: 0,
            limit: 10,
            search: '',
        };
        this.isReload = !!props.location.state;
        this.dataEvaluation = [];
        this.dataSalePerson = [];
        this.typeTimeID = '';
        this.mode = '';
        this.timer = null;
        this.timeDeplay = 3000;
    };

    toggle() {
        this.setState({collapse: !this.state.collapse});
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadEvaluation();
        this.loadFilters();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1930", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    formatDate = (date) => {
        if (date) {
            return moment(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    loadFilters() {
        const filters = Config.getStoreState(this);
        if (filters) {
            this.filter.search = filters.search;
            this.loadGrid();
        }
    }

    loadGrid() {
        const paramGrid = {
            "SalesPersonID": this.state.salePersonID,
            "EvaluateTypeID": this.state.evaluateTypeID,
            "DateFrom": this.formatDate(this.state.CreateDateFrom),
            "DateTo": this.formatDate(this.state.CreateDateTo),
            "StrSearch": this.filter.search,
            "GroupSalesID": this.state.groupSalesID
        };
        this.props.w19F1030Actions.loadGrid(paramGrid, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === "F1022E002") {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                } else {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });
    }

    loadEvaluation() {
        this.props.w19F1030Actions.loadCbEvaluation({}, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === "F1022E002") {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                } else {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
            } else if (data && data.length > 0) {
                this.dataEvaluation = data.map(o => {
                    return {
                        EvaluateTypeID: o.EvaluateTypeID,
                        EvaluateTypeName: o.EvaluateTypeName
                    };
                });
            } else {
                this.dataEvaluation = [];
            }
        });
    }

    onChangeComboSalePerson(data) {
        if (data) {
            this.setState({
                salePersonID: data.SalesPersonID
            }, () => {
                this.loadGrid()
            });
        } else {
            this.setState({
                salePersonID: ''
            }, () => {
                this.loadGrid()
            });
        }
    }

    onChangeComboEvaluation(data) {
        if (data) {
            this.setState({
                evaluateTypeID: data.EvaluateTypeID
            }, () => {
                this.loadGrid()
            });
        } else {
            this.setState({
                evaluateTypeID: ''
            }, () => {
                this.loadGrid()
            });
        }
    }

    onChangeTimeEnd(data, mode) {
        if (!this.isReload || !mode) {
            this.setState({
                CreateDateTo: data.DateTo,
                CreateDateFrom: data.DateFrom,
                typeTime: data.ID
            }, () => {
                this.loadGrid()
            });
        }
    }

    onSearch(text) {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.setState({
            search: text
        },()=>{
            this.timer = setTimeout(() => {
                this.filter.search = text;
                this.loadGrid()
            }, 500);
        });

    }

    onAddNew() {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W19F1031',
            state: {mode: "add"}
        });
    }

    onEdit(value) {
        const evaluateID = value && value.EvaluateID ? value.EvaluateID : '';
        const storeData = {
            collapse: this.state.collapse,
            search: this.filter.search,
            salePersonID: this.state.salePersonID,
            evaluateTypeID: this.state.evaluateTypeID,
            typeTime: this.state.typeTime,
            CreateDateFrom: this.state.CreateDateFrom,
            CreateDateTo: this.state.CreateDateTo
        };
        Config.setStoreState(this, storeData);
        browserHistory.push({
            pathname: Config.getRootPath() + 'W19F1031',
            state: {mode: "edit", id: evaluateID}
        });
    }

    onDelete(value) {
        const evaluateID = value && value.EvaluateID ? value.EvaluateID : '';
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const param = {
                "EvaluateID": evaluateID
            };
            this.props.w19F1030Actions.deleteEvaluation(param, async (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1030E001":
                            message = Config.lang("Xoa_");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1030E002":
                            message = Config.lang("Khu_vuc_nay_da_co_nhan_vien_so_huu");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1030E003":
                            message = Config.lang("Khu_vuc_nay_da_co_nhan_vien_so_huu");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;

                } else {
                    const historyStatus = await this.saveHistory(evaluateID);
                    if (historyStatus !== 200) {
                        Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                        return false;
                    }
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGrid();
                }
            });
        });
    }


    saveHistory = async (evaluateID) => {
        let el = {};
        let data = [];
        let status = '';
        el.codeID = evaluateID;
        el.formID = 'W19F1030';
        el.linkedTrans = '';
        el.oldValue = evaluateID;
        el.newValue = '';
        el.description84 = Config.lang("Danh_sach_danh_gia_chung", 'vi');
        el.description01 = Config.lang("Danh_sach_danh_gia_chung", 'en');
        el.action = 2;
        data.push(el);
        const param = {
            attributes: JSON.stringify(data)
        };
        await this.props.w19F1031Action.addHistory(param, (error) => {
            status = error ? 400 : 200
        });
        return status
    };

    onChangeGroupSales = (e) => {
        this.setState({
            groupSalesID:e
        },()=>{
            this.loadGrid();
        })
    };

	renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png':
				return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/image.png")}/>);

            case 'doc':
            case 'docx':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/word.png")}/>);

            case 'xls':
            case 'xlsx':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/excel.png")}/>);

            case 'ppt':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/icon-ppt.png")}/>);
            case 'pptx':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/icon-pptx.png")}/>);

            case 'pdf':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/pdf.png")}/>);

            case 'txt':
                return (<img style={{marginTop: -1, height: 16}} alt="" src={require("../../images/file/icon-txt.png")}/>);

            default:
                return (<img style={{marginTop: -1, height: 16}} alt="" src={url} className="img-background"/>);
        }
    };

    render() {
        if (this.state.iPermission <= 0) return null;
        const {dataGrid} = this.props;
		const {showFileViewer} = this.state;

        const paramPrint = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || '84'},
            {type: "NVarChar", id: "StrSearch", value: this.filter.search},
            {type: "NVarChar", id: "SalesPersonID", value: this.state.salePersonID},
            {type: "VarChar", id: "EvaluateTypeID", value: this.state.evaluateTypeID},
            {type: "DATETIME", id: "DateFrom", value: this.formatDate(this.state.CreateDateFrom)},
            {type: "DATETIME", id: "DateTo", value: this.formatDate(this.state.CreateDateTo)},
            {type: "VarChar", id: "GroupSalesID", value: this.state.groupSalesID},
        ];
		const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
			afterChange: index => this.setState({currentSlide: index})
        };
        return (
            <div className="page-container" id="W19F1030" style={{minHeight: '400px'}}>
                <EventTracking category={"TRACKING_USER"} action={"W18F1022"} label={Config.profile.UserID}/>
                <div className="grid-title">{Config.lang("CRM_Danh_sach_danh_gia_chung")}</div>
                <FormGroup className={'row'}>
                    <Col sm={12}>
                        <Row>
                            <Col sm={2} className={'pull-left'}>
                                <ButtonAdd
                                    className={`${this.state.iPermission < 2 ? 'isDisabled' : ''} pull-left mgr10`}
                                    onClick={() => {
                                        if (this.state.iPermission >= 2) {
                                            this.onAddNew()
                                        }
                                    }} name={Config.lang("CRM_Them_moi1")}/>
                            </Col>
                            <Col sm={3} className={'pull-right'}>
                                <ButtonExport name={Config.lang('CRM_Xuat_du_lieu')}
                                              transTypeID={'ExportQuotation'}
                                              reportTypeID={'W19F1030'}
                                              param={paramPrint}
                                              className={'mgr5'}/>
                            </Col>
                            <Col sm={7}>
                                <InputGroup style={{width: '100%'}} className={'pull-left mgb15'}>
                                    <input id={'search-user-profile'} autoComplete={'off'}
                                           placeholder={Config.lang("CRM_Nhap_noi_dung_kien_nghi_can_tim_kiem")}
                                           onChange={(e) => this.onSearch(e.target.value)} type="text"
                                           value={this.state.search}
                                           className="form-control"/>
                                    <InputGroupAddon id={'search-button-user-profile'}>
                                        <i style={{cursor: "pointer"}} onClick={() => {
                                            this.toggle()
                                        }}
                                           className={!this.state.collapse ? "fas fa-arrow-alt-down" : "fas fa-arrow-alt-up"}/>
                                    </InputGroupAddon>
                                </InputGroup>
                                <Collapse in={this.state.collapse} className={'pull-left'} style={{width: '100%'}}>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={4}  className={'mgb15'}>
                                                <DropDownSaleGroup showClearButton={true}
                                                                   onChange={this.onChangeGroupSales}
                                                                   placeholder={Config.lang('CRM_Nhom_kinh_doanh')}
                                                                   value={this.state.groupSalesID}
                                                />
                                            </Col>
                                            <Col lg={4}  className={'mgb15'}>

                                                <DropDownSalePerson showClearButton={true}
                                                                    groupSalesID={this.state.groupSalesID}
                                                                    placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                                                    value={this.state.salePersonID}
                                                                    reset={this.state.resetComboPS}
                                                                    onChange={(e) => {
                                                                        this.onChangeComboSalePerson(e)
                                                                    }}/>
                                            </Col>
                                            <Col lg={4}  className={'mgb15'}>
                                                <Combo
                                                    dataSource={this.dataEvaluation}
                                                    showClearButton={true}
                                                    placeholder={Config.lang("CRM_Loai_danh_gia")}
                                                    valueExpr={'EvaluateTypeID'}
                                                    displayExpr={'EvaluateTypeName'}
                                                    value={this.state.evaluateTypeID}
                                                    onActionWhenSelectChange={(value) => {
                                                        this.onChangeComboEvaluation(value)
                                                    }}
                                                >
                                                    <Column dataField={'EvaluateTypeID'} caption={Config.lang("Ma")}
                                                            visible={false}
                                                            dataType={'string'}/>
                                                    <Column dataField={'EvaluateTypeName'}
                                                            caption={Config.lang("Ten")}
                                                            dataType={'string'}/>
                                                </Combo>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}  className={"mgb15"}>
                                                <DropDownTypeTime
                                                    listType={"W19F1030_TypeTime"}
                                                    placeholder={""}
                                                    value={this.state.typeTime}
                                                    onChange={(data) => this.onChangeTimeEnd(data)}
                                                    onLoad={(data) => this.onChangeTimeEnd(data, 'load')}
                                                >
                                                </DropDownTypeTime>
                                            </Col>
                                            <Col lg={4} md={6} className={"mgb15"}>
                                                <DateBox
                                                    width={"100%"}
                                                    id={'CreateDateFrom'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    value={this.state.CreateDateFrom}
                                                    type={'date'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={false}
                                                    displayFormat={'dd/MM/y'}
                                                    onValueChanged={(e) => {
                                                        this.setState({
                                                            CreateDateFrom: e.value
                                                        }, () => {
                                                            this.loadGrid()
                                                        })
                                                    }}
                                                >
                                                </DateBox>
                                            </Col>

                                            <Col lg={4} md={6} className={"mgb15"}>
                                                <DateBox
                                                    width={"100%"}
                                                    id={'CreateDateTo'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    value={this.state.CreateDateTo}
                                                    type={'date'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={false}
                                                    displayFormat={'dd/MM/y'}
                                                    onValueChanged={(e) => {
                                                        this.setState({
                                                            CreateDateTo: e.value
                                                        }, () => {
                                                            this.loadGrid()
                                                        })
                                                    }}
                                                >
                                                    <Validator>
                                                        <CompareRule
                                                            message={Config.lang("CRM_Ngay_den_phai_lon_hon_ngay_tu")}
                                                            reevaluate={false}
                                                            ignoreEmptyValue={true}
                                                            comparisonType={'>='}
                                                            comparisonTarget={() => {
                                                                return this.state.CreateDateFrom
                                                            }}
                                                        />
                                                    </Validator>
                                                </DateBox>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Collapse>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>

                <FormGroup className={'row'}>
                    <div className='title-evaluation' id="nonew19f1030">
                        <span>
                            {Config.lang("CRM_Noi_dung")}
                        </span>
                        <span>
                            {Config.lang("CRM_Kien_nghi")}
                        </span>
                        <span>
                            {Config.lang("Ghi_chu")}
                        </span>
                    </div>

                    {dataGrid && dataGrid.total === 0 && <div style={{
                        width: '100%', height: '100%', display: "flex", justifyContent: "center"
                    }}>
                        {Config.lang("CRM_Khong_co_du_lieu")}
                    </div>}
                    {dataGrid && dataGrid.rows && dataGrid.rows.length > 0 && dataGrid.rows.map((value, index) => {
                        return (
                            <div key={index} className='col-lg-12 col-md-12 col-sm-12 col-xs-12 image-sale-evaluation'
                                 id="styleW19F1030">
                                <Row>
                                    <Col md={2} className={'display_row'}>
                                        <img alt={''} className="img-evaluation"
                                             src={value.URL ? value.URL : require('../../images/hinh3.png')}/>
                                        <div className="content-evaluation">
                                            <div className="content_name">
                                                {value.SalesPersonNameU}
                                            </div>
                                            <div className="content_date">
                                                {moment(value.EvaluationDate).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={4} style={{borderRight: '1px solid #f4f2f2', minHeight: 100}}>
                                        <div className="evaluation">
                                            <div className="evaluation_content"
                                                 style={{float: "left", whiteSpace: "pre-wrap"}}>
                                                <span style={{fontSize: '16px', color: '#adaba9'}}
                                                      className='noneHeader'>{Config.lang("Noi_dung")}:
                                                </span>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value.Content)}}
                                                    className='editor-content'/>
                                            </div>
                                            <div className={'ribbon'} style={{
                                                backgroundColor: value.Color,
                                            }}>
                                                {value.EvaluateTypeName}
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={3} style={{ borderRight: '1px solid #f4f2f2', minHeight: 100 }}>
                                        <div className="mendation">
                                            <div style={{ float: "left", paddingBottom: '10px', whiteSpace: "pre-wrap" }}>
                                                <span style={{ fontSize: '16px', color: '#adaba9' }}
                                                    className='noneHeader'>{Config.lang("Kien_nghi")}:</span>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value?.Recommendation) }}
                                                    className="editor-recommendation" />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={3} className={'display_row align-between'}>
                                        <div className="mendation">
                                            <div style={{float: "left", paddingBottom: '10px', whiteSpace: "pre-wrap"}}>
                                                <span style={{fontSize: '16px', color: '#adaba9'}}
                                                      className='noneHeader'> {Config.lang("Ghi_chu")}:</span>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value?.Notes)}}
                                                    className="editor-recommendation"/>
                                            </div>
                                        </div>
                                        <div className="footer-evaluation display_col">
                                            <ButtonEdit className={this.state.iPermission >= 3 ? "" : "isDisabled"}
                                                        onClick={() => {
                                                            if (this.state.iPermission >= 3) {
                                                                this.onEdit(value)
                                                            }
                                                        }}/>
                                            <ButtonDelete className={this.state.iPermission >= 4 ? "" : "isDisabled"}
                                                          onClick={() => {
                                                              if (this.state.iPermission >= 4) {
                                                                  this.onDelete(value)
                                                              }
                                                          }}/>
                                        </div>
                                    </Col>
                                </Row>
								<Row>
									<Col md={2}></Col>
									<Col md={10}>
										<div style={{float: "left", width: '100%', position: 'relative'}}>
                                            <div style={{'marginTop': '10px', 'display': 'flex', 'flexWrap': 'wrap'}}>
                                                {value.attached && value.attached.length ? value.attached.map((attachment, index) => {
                                                    if (index > 2) {
                                                        return null;
                                                    }
                                                    return (<span onClick={() => { this.setState({ showFileViewer: value, slideIndex: index, currentSlide: index }) }}
                                                                    key={index} className="jss7" title={attachment.FileName}>
                                                                {this.renderIcon(attachment.FileName, attachment.URL)} {attachment.FileName}
                                                            </span>)
                                                }) : ''}
                                                {value.attached && value.attached.length && value.attached.length > 3 ?
                                                    (<span className="jss7" title={"+" + (value.attached.length - 3)}
                                                    onClick={() => { this.setState({ showFileViewer: value, slideIndex: 3, currentSlide: 3 }) }}>
                                                        +{value.attached.length - 3}
                                                    </span>) : ''
                                                }
                                            </div>
                                        </div>
									</Col>
								</Row>
                            </div>
                        );
                    })}
					{showFileViewer && <Modal className={"modal-review-file"} style={{ zIndex: 1504 }}
                        open={!!showFileViewer}
                        maxWidth={"lg"}
                        fullWidth={true}
                        paperProps={{ style: { height: '100%' } }}
                        title={Config.lang('CRM_Xem_tap_tin') + '#%#' + showFileViewer.attached[this.state.currentSlide].FileName}
                        onClose={() => { this.setState({ showFileViewer: null }) }}>
                        <Slider initialSlide={this.state.slideIndex} {...settings}>
                            {showFileViewer.attached.map((file, idx) => {
                                const FileName = file && file.FileName ? file.FileName : "";
                                const FileExt = file && file.fileExt ? file.fileExt : Config.helpers.getExtFile(file ? file.FileName : '');
                                const URL = file && file.URL ? (file.URL.indexOf('http') < 0 ? Config.env.cdn + '/' + file.URL : file.URL) : "";
                                return (
									<FileViewer
										key={idx}
										fileName={FileName}
										filePath={URL}
										fileType={FileExt}
										height={790} />
                                )
                            })}
                        </Slider>
                    </Modal>}
                </FormGroup>
            </div>


        )
    }
}

export default connect(state => ({
        dataGrid: state.w19f1030.dataGrid,
        cbSalePerson: state.w19f1030.cbSalePerson,
        cbEvaluation: state.w19f1030.cbEvaluation,
        deleteEvaluation: state.w19f1030.deleteEvaluation,
        cbTypeTime: state.w19f1030.cbTypeTime,
    }),
    (dispatch) => ({
        w19F1030Actions: bindActionCreators(w19F1030Actions, dispatch),
        w19F1031Action: bindActionCreators(w19F1031Action, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W19F1030Page);
