/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config/index';
import * as w20f1000Actions from "../../actions/w20/w20f1000-actions";
import * as w19F1031Action from "../../actions/w19/w19F1031/w19f1031-actions";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import PropTypes from 'prop-types';
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
import _ from "lodash";


export class W20F1000Contact extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            search: '',
            where: props.listCustomerID && JSON.stringify({
                listCustomerID: props.listCustomerID
            }),
            limit: 15,
            skip: 0
        };

        this.state = {
            dataGrid: {
                rows: [],
                total: 0
            }
        }
    }

    loadGrid(firstLoad = false) {
        const {paramGridDetail} = this.props;
        if (firstLoad && !Config.isEmpty(paramGridDetail?.filter)) { // Load lần đầu khi chuyển tab thì lấy filter cũ
            this.filter = { ...this.filter, ...paramGridDetail.filter};
        }
        const param = {
            ..._.omit(paramGridDetail, "filter"),
            search: this.filter.search,
            limit: this.filter.limit,
            skip: this.filter.skip,
        };
        this.props.w20f1000Actions.getContactGrid(param, (error, data) => {
            if (error) return false;
            this.setState({
                dataGrid: {
                    rows: data?.rows || [],
                    total: data?.total ?? 0,
                }
            })
        })
    }

    clearData = () => {
        this.filter = {
            search: '',
            where: '',
            skip: 0
        };
        this.setState({
            dataGrid: {
                rows: [],
                total: 0,
            }
        })
    };

    componentDidMount() {
        const {listCustomerID, onRef} = this.props;
        if (onRef) onRef(this);

        if (listCustomerID) this.loadGrid(true);
    }

    onSearch = text => {
        this.filter.search = text;
        this.loadGrid();
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = per => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    saveHistory = async (param) => {
        let status = 200;
        let el = param ? param : {};
        if (!param) {
            el.codeID = this.rowData && this.rowData.ListCustomerID;
            el.formID = 'W20F1000';
            el.linkedTrans = '';
            el.oldValue = '';
            el.newValue = this.rowData && this.rowData.ListCustomerName;
            el.description84 = Config.lang("CRM_Danh_sach_khach_hang", 'vi');
            el.description01 = Config.lang("CRM_Danh_sach_khach_hang", 'en');
            el.action = 2;
        }
        if (Object.keys(el).length) {
            const param = {
                attributes: JSON.stringify([el])
            };

            await this.props.w19F1031Action.addHistory(param, (error) => {
                if (error) status = 400;
            });
        }
        return status
    };

    deleteRowContact = data => {
        const param = {
            ContactID: data.ContactID,
            ListCustomerID: this.props.listCustomerID
        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.w20f1000Actions.deleteContact(param, async (error) => {
                if (error) {
                    Config.popup.show('INFO', Config.lang("CRM_Co_loi_khi_xoa"));
                    return false;
                }
                else{
                    const paramHistory = {
                        codeID: this.props.listCustomerID,
                        formID: 'W20F1001',
                        linkedTrans: '',
                        oldValue: '',
                        newValue: '',
                        hasURL:false,
                        description84: `${Config.lang('CRM_Lien_he', 'vi')} ${data.FullNameU}${Config.lang('CRM_cua_khach_hang','vi')}${data.CompanyNameU}`,
                        description01: `${Config.lang('CRM_Lien_he', 'en')} ${data.FullNameU}${Config.lang('CRM_cua_khach_hang','en')}${data.CompanyNameU}`,
                        action: 2,
                    };
                    const historyStatus = await this.saveHistory(paramHistory);
                    if (historyStatus !== 200) {
                        Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                        return false;
                    }
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGrid();
                }
            })
        });
    };

    renderAction = (rowData) => {
        const {permission} = this.props;

        if (!rowData || !rowData.data) return null;
        return (
            <GridActionBar
                isPer={permission}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    const {data} = rowData;
                    this.deleteRowContact(data)
                }}
            />
        )
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {listCustomerID} = this.props;

        if (prevProps.listCustomerID !== listCustomerID) {
            if (!listCustomerID) {
                this.clearData();
                return false;
            }
            this.filter.where = JSON.stringify({listCustomerID: listCustomerID});
            this.loadGrid();
        }
    }


    render() {
        const {dataGrid} = this.state;
        const {permission, onAddNew} = this.props;
        return (
            <GridContainer title={""}
                           disabledAddNew={permission < 2 || !permission}
                           onAddNew={onAddNew}
                           valueSearch={this.filter.search}
                           listPerPage={[15, 20, 30, 50]}
                           totalItems={dataGrid.total}
                           itemPerPage={this.filter.limit}
                           dataSource={dataGrid.rows}
                           onSearch={this.onSearch}
                           onChangePage={this.onChangePage}
                           onChangePerPage={this.onChangePerPage}
            >
                <Column cellRender={this.renderAction}
                        caption={Config.lang("CRM_Hanh_dong")}
                        fixed={true}
                        width={90}
                />
                <Column dataField={'FullNameU'}
                        caption={Config.lang("CRM_Ho_va_ten")}
                />
                <Column dataField={'CompanyNameU'}
                        caption={Config.lang("CRM_Khach_hang")}
                />
                <Column dataField={'JobPositionU'}
                        caption={Config.lang("CRM_Chuc_vu")}
                />
                <Column dataField={'Gender'}
                        caption={Config.lang("CRM_Gioi_tinh")}
                />
                <Column dataField={'Birthday'}
                        caption={Config.lang("CRM_Ngay_sinh")}
                        dataType={'date'}
                        alignment={'center'}
                        format={'dd-MM-yyyy'}
                />
                <Column dataField={'Email'}
                        caption={`Email ${Config.lang("CRM_Cong_ty")}`}
                />
                <Column dataField={'Email2'}
                        caption={`Email ${Config.lang("CRM_Ca_nhan")}`}
                />
                <Column dataField={'OfficeTelNo'}
                        caption={Config.lang("CRM_Dien_thoai_cong_ty")}
                />
                <Column dataField={'MobileNo'}
                        caption={Config.lang("CRM_Dien_thoai_di_dong")}
                />
                <Column dataField={'Skype'}
                        caption={'Skype'}
                />
                <Column dataField={'Facebook'}
                        caption={Config.lang("Facebook")}
                />
                <Column dataField={'HomeAddressU'}
                        caption={Config.lang("CRM_Dia_chi")}

                />
                <Column dataField={'CreateDate'}
                        caption={Config.lang("CRM_Ngay_them")}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'center'}
                />
                <Column dataField={'CreateUserName'}
                        caption={Config.lang("CRM_Nguoi_them")}
                />
                <Column dataField={'ContactID'}
                        caption={Config.lang("CRM_Ma_lien_he")}
                />
            </GridContainer>
        )
    }
}

W20F1000Contact.propTypes = {
    listCustomerID: PropTypes.string,
    permission: PropTypes.number,
    onAddNew: PropTypes.func,
    paramGridDetail: PropTypes.object,
    onRef: PropTypes.func
};

W20F1000Contact.defaultProps = {
    permission: 0,
    paramGridDetail: {}
};

export default connect(null,
    (dispatch) => ({
        w20f1000Actions: bindActionCreators(w20f1000Actions, dispatch),
        w19F1031Action: bindActionCreators(w19F1031Action, dispatch),
    })
)(W20F1000Contact);
