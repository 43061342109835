/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */
import React from 'react';
import {connect} from "react-redux";
import Config from "../../config";
import EventTracking from "../common/tracking/event";
import {Form} from "react-bootstrap";
import ButtonBack from "../common/button/button-back";
import ButtonSave from "../common/button/button-save";
import ButtonSaveNext from "../common/button/button-savenext";
import {DateBox} from "devextreme-react";
import Select from 'react-select'
import * as w19F1031Action from '../../actions/w19/w19F1031/w19f1031-actions'
import FileUploadAsync from "../../actions/file-upload-new-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileDeleteAsync from "../../actions/file-delete-async";
import ButtonCustom from "../common/button/button-custom";
import ActivityComponent from "../common/activity/activity-component";
import {bindActionCreators} from "redux";
import notify from "devextreme/ui/notify";
import {browserHistory} from "react-router";
import {Editor} from '@tinymce/tinymce-react';
import moment from 'moment';
import { Row, Col} from 'diginet-core-ui/components';
const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

export class W19F1031 extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        const {mode} = this.getInfo();
        this.handleChange = this.handleChange.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeRecomment = this.changeRecomment.bind(this);
        this.changeContent = this.changeContent.bind(this);
        this.saveData = this.saveData.bind(this);
        this.goBack = this.goBack.bind(this);
        this.state = {
            history: false,
            evaluateType: null,
            evaluationDate: mode === 'add' ? moment().format('YYYY-MM-DD') : null,
            content: '',
            recomment: '',
            saveSuccess: false,
            dataAttach: [],
            attached: [],
            notes: ''
        };
        this.timer = null;
        this.evaluateID = null;
        this.oldData = null;
        this.id = null;/// Id khi thêm mới sinh ra khi gọi API add
        this.OldAttachData = [];
    }

    saveHistory = async (newData) => {
        if (!this.getInfo()) return false;
        const {mode} = this.getInfo();
        let status = 200;
        let data = [];//Chứa giá trị bị thay đổi
        if (mode === 'edit') {
            const key = Object.keys(newData);
            key.filter((value) => {
                return value !== 'EvaluateID'
            }).forEach((value) => {
                if (newData[value] !== this.oldData[value]) {
                    const description = document.getElementById(`${value.toLowerCase()}LbW19F1031`).getAttribute('lang');
                    let el = {}; //truyền param -tham khảo api history add
                    el.codeID = newData.EvaluateID;
                    el.type = value === 'EvaluateTypeName' ? 'highlight' : 'text';
                    el.formID = 'W19F1031';
                    el.linkedTrans = '';
                    el.oldValue = this.oldData[value];
                    el.newValue = newData[value];
                    el.description84 = Config.lang(description, 'vi');
                    el.description01 = Config.lang(description, 'en');
                    el.action = 1;
                    data.push(el);
                }
                return false;

            });
        } else {
            let el = {}; //truyền param -tham khảo api history add
            el.codeID = newData.EvaluateID;
            el.formID = 'W19F1031';
            el.linkedTrans = '';
            el.oldValue = '';
            el.newValue = newData.Content;
            el.description84 = Config.lang("CRM_Cap_nhat_danh_gia", 'vi');
            el.description01 = Config.lang("CRM_Cap_nhat_danh_gia", 'en');
            el.action = 0;
            data.push(el);
        }
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data)
            };
            await this.props.w19F1031Action.addHistory(param, (error) => {
                if (error) status = 400;
            });
        }
        return status
    };

    saveData = () => {
        const {evaluateType, evaluationDate, content, recomment, notes} = this.state;
        const {id, mode} = this.getInfo();
        if (!evaluateType) {
            Config.popup.show('INFO', Config.lang('CRM_Loai_danh_gia_khong_duoc_de_trong'), null, null, () => {
                if (this.timer) clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    if (this.refs['evaluateTypeW19F1031Ref']) this.refs['evaluateTypeW19F1031Ref'].focus();

                }, 300)
            });
            return false;
        }

        if (!evaluationDate) {
            Config.popup.show('INFO', Config.lang('CRM_Ngay_danh_gia_khong_duoc_de_trong'), null, null, () => {
                if (this.timer) clearTimeout(this.timer);
                setTimeout(() => {
                    if (this.refs['evaluationDateRefW19F1031']) {
                        this.refs['evaluationDateRefW19F1031'].instance.focus();
                    }

                }, 300);
            });
            return false;
        }

        let paramAttach = '';
        const dataAttach = [...this.state.dataAttach];
        const OldAttachData = this.OldAttachData;
        const param = {
            EvaluateTypeID: evaluateType.value,
            EvaluationDate: evaluationDate,
            Content: content.replace(/\n/g, ""),
            Recommendation: recomment.replace(/\n/g, ""),
            Notes: notes.replace(/\n/g, "")
        };

        if (mode === 'add') {
            this.props.w19F1031Action.saveDataW19F1031(param, async (error, data) => {
                if (error) {
                    let message;
                    switch (error.code) {
                        case "F1031E007":
                            message = Config.lang('CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep');
                            break;
                        case "F1031E008":
                            message = Config.lang('CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep');
                            break;
                        default:
                            message = Config.lang('CRM_Co_loi_khi_luu');
                            break;
                    }
                    Config.popup.show("INFO", message);
                    return false;

                }

                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': data.KeyID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': 'D17T1930'
                        };

                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }

                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached
                        });
                    }
                }

                const paramHistory = {
                    // EvaluateID:data.KeyID
                    EvaluateID: data.KeyID,
                    Content: content,
                    Notes: notes,
                };
                const historyStatus = await this.saveHistory(paramHistory);
                if (historyStatus !== 200) {
                    Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                    return false;
                }
                this.id = data.KeyID;
                if (this.refs['activityRef']) {
                    this.refs['activityRef'].getWrappedInstance().resetHistory();
                }
                this.setState({
                    saveSuccess: true
                });
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
            })
        } else {
            param.EvaluateID = id;
            let paramHistory = Object.assign({}, param);
            delete paramHistory.EvaluateTypeID;
            paramHistory.EvaluateTypeName = evaluateType.label;

            this.props.w19F1031Action.editDataW19F1031(param, async (error) => {
                if (error) {
                    let message;
                    switch (error.code) {
                        case "F1031E007":
                            message = Config.lang('CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep');
                            break;
                        case "F1031E008":
                            message = Config.lang('CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep');
                            break;
                        default:
                            message = Config.lang('CRM_Co_loi_khi_luu');
                            break;
                    }
                    Config.popup.show("INFO", message);
                    return false;
                }

                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': param.EvaluateID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': 'D17T1930'
                        };
                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }
                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached,
                            disabled: false
                        });
                    }
                }

                if (OldAttachData.length > 0) {
                    let paramDeleteAttach;
                    let paramRemoveAttach;
                    for (let i = 0; i < OldAttachData.length; i++) {
                        paramDeleteAttach = {
                            'AttachmentID': OldAttachData[i].AttachmentID
                        };
                        paramRemoveAttach = {
                            'path': OldAttachData[i].URL
                        };
                        await FileDeleteAsync(paramDeleteAttach);

                        await FileRemoveAsync(paramRemoveAttach);
                    }
                    OldAttachData.length = 0;
                }

                const historyStatus = await this.saveHistory(paramHistory);
                if (historyStatus !== 200) {
                    Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                    return false;
                }
                if (this.refs['activityRef']) {
                    this.refs['activityRef'].getWrappedInstance().resetHistory();
                }
                this.loadDatamaster();
                const activity = document.getElementById('Activity-Container');
                if (activity) activity.scrollTo(0, 0);
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
            })

        }

    };

    showHistory() {
        this.setState({
            history: !this.state.history
        })
    }

    loadDatamaster() {
        if (!this.getInfo()) return;

        const {id} = this.getInfo();
        const param = {
            EvaluateID: id
        };

        this.props.w19F1031Action.dataMasterW19F1031(param, (error, data) => {
            if (error) {
                Config.popup.show('INFO', Config.lang("CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu"));
                return false;
            }
            this.oldData = data;
            if (this.oldData.EvaluationDate) {
                this.oldData.EvaluationDate = moment(this.oldData.EvaluationDate).format('YYYY-MM-DD');
            }
            this.evaluateID = data.SalesPersonNameU;
            this.setState({
                evaluateType: {
                    value: data.EvaluateTypeID || '',
                    label: data.EvaluateTypeName || ''
                },
                evaluationDate: data.EvaluationDate ? moment(data.EvaluationDate).format('YYYY-MM-DD') : null,
                content: data.Content,
                recomment: data.Recommendation,
                notes: data?.Notes || '',
            })
            this.getAttach(data.EvaluateID);
        });
    }

    componentDidMount() {
        if (!this.getInfo()) return;
        const {mode} = this.getInfo();
        this.props.w19F1031Action.getCboEvaluatetypeW19F1031();
        if (mode === 'edit') {
            this.loadDatamaster();
        }
    }

    changeDate(e) {
        this.setState({
            evaluationDate: e.value
        })
    }

    handleChange(e) {
        this.setState({
            evaluateType: e
        })
    }

    changeRecomment(e) {
        this.setState({
            recomment: e
        })
    }

    changeContent(e) {
        this.setState({
            content: e
        })
    }

    changeNotes = (e) => {
        this.setState({
            notes: e
        })
    };

    getInfo = (flag) => {
        const {location} = this.props;
        if (location && location.state) {
            return {id: location.state.id, mode: location.state.mode}
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W19F1030');
                return null;
            }
            return false;
        }
    };

    goBack() {
        browserHistory.goBack();
        // browserHistory.push(Config.env.url + '/W19F1030');
    }

    clearData() {
        this.id = null;
        this.setState({
            evaluateType: null,
            evaluationDate: moment().format('YYYY-MM-DD'),
            content: '',
            recomment: '',
            saveSuccess: false
        });
    }

    checkFileType(filetype) {
        const allowedExtensions = [
            'image/jpeg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    getAttach(id) {
        const param = {
            KeyID: id
        };

        this.props.w19F1031Action.getAttach(param, (error, data) => {
            if (error) return false;
            if (data) {
                this.setState({
                    attached: data
                })
            }
        })
    }

    upLoadAttach = (e) => {
        if (!e) return;
        const files = e?.target?.files || [];
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")}` + sizeLimit + ' MB');
        } else {
            let dataAttach = [...this.state.dataAttach];
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({file: file, FileName: file.name, URL: window.URL.createObjectURL(file)});
            }
            this.setState({
                dataAttach: dataAttach,
            });
        }
    }

    async deleteAttach(file) {
        const {dataAttach} = this.state;
        let arr = dataAttach.filter((value) => {
            return value.FileName !== file.FileName;
        });

        this.setState({
            dataAttach: arr
        });
    }

    deleteOldAttach(attachment) {
        this.OldAttachData.push(attachment);
        const attached = this.state.attached;
        let arr;
        arr = attached.filter(function (obj) {
            return obj.AttachmentID !== attachment.AttachmentID;
        });
        this.setState({
            attached: arr
        })
    }

    renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'doc':
                return (<img alt="" src={require("../../images/file/icon-doc.png")}/>);
            case 'docx':
                return (<img alt="" src={require("../../images/file/icon-docx.png")}/>);

            case 'xls':
                return (<img alt="" src={require("../../images/file/icon-xls.png")}/>);
            case 'xlsx':
                return (<img alt="" src={require("../../images/file/icon-xlsx.png")}/>);

            case 'ppt':
                return (<img alt="" src={require("../../images/file/icon-ppt.png")}/>);
            case 'pptx':
                return (<img alt="" src={require("../../images/file/icon-pptx.png")}/>);

            case 'pdf':
                return (<img alt="" src={require("../../images/file/icon-pdf.png")}/>);

            case 'txt':
                return (<img alt="" src={require("../../images/file/icon-txt.png")}/>);

            default:
                return (<img alt="" src={url} className="img-background"/>);
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const {mode, id} = this.getInfo();
        let codeID = mode === 'edit' ? id : this.id;
        const {history, evaluateType, evaluationDate, content, recomment, saveSuccess, dataAttach, attached, notes} = this.state;
        const {cboEvaluate} = this.props;
        let optionCbo = [];
        cboEvaluate && cboEvaluate.forEach((value) => {
            optionCbo.push({
                value: value.EvaluateTypeID,
                label: value.EvaluateTypeName
            })
        });
        return (
            <div id={'W19F1031Container'} className="page-container">
                <div className="grid-title">{Config.lang("CRM_Cap_nhat_danh_gia")}</div>
                <EventTracking action={"FORM"} label={"W19F1031"}/>
                <Form horizontal={true} id={'W19F1031'} style={{position: 'relative'}}>
                    <Row>
                        <Col id={'masterW19F1031'} lg={history ? 8 : 12} md={history ? 7 : 12} sm={history ? 7 : 12}>
                            <Row>
                                <Col id={'menuButtonW19F1031'} lg={12} md={12}>
                                    <ButtonBack name={Config.lang('CRM_Quay_lai')} onClick={this.goBack} />
                                    {saveSuccess && mode === 'add' &&
                                        <ButtonSaveNext
                                            onClick={() => {
                                                this.clearData()
                                            }}
                                            color={'text-black'} name={Config.lang("CRM_Nhap_tiep")}
                                        />}
                                    {mode === 'add' && saveSuccess ? '' :
                                        <ButtonSave name={Config.lang('CRM_Luu')} onClick={this.saveData} />
                                    }
                                    {mode !== 'view' &&
                                        <ButtonCustom className={'mgr5'} icon={'fas fa-cloud-upload'} isAwesome={true}
                                            color={'text-green'} name={Config.lang("CRM_Dinh_kem")}
                                            onClick={() => {
                                                document.getElementById('attachmentW19F1031').click();
                                            }}
                                            disabled={mode === 'add' && this.state.isDisabled}
                                        />}
                                    {
                                        /* eslint-disable */
                                        <a id={'buttonHistoryW19F1031'} onClick={() => this.showHistory()}
                                            style={{ float: 'right' }}
                                            className={'mgt5 text-muted'}>{Config.lang('CRM_Lich_su')}
                                            <i className={`mgl5 fas fa-chevron-${!history ? 'right' : 'left'}`} /></a>
                                        /* eslint-enable */
                                    }
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label style={{whiteSpace: 'nowrap'}} id={'evaluatetypenameLbW19F1031'}
                                           lang={'CRM_Loai_danh_gia'}
                                           className={'control-label mgb0'}>{Config.lang("CRM_Loai_danh_gia")}<span
                                        className={'text-red mgl5'}>(*)</span></label>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <Select
                                        styles={selectStyles}
                                        ref={'evaluateTypeW19F1031Ref'}
                                        id={'evaluateTypeW19F1031'}
                                        placeholder={''}
                                        noOptionsMessage={() => {
                                            return Config.lang('CRM_Khong_tim_thay_du_lieu');
                                        }}
                                        isClearable={true}
                                        value={evaluateType}
                                        options={optionCbo}
                                        isDisabled={saveSuccess && mode === 'add'}
                                        onChange={this.handleChange}/>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label className={'control-label mgb0'}
                                           id={'Nguoi_lap'}>{Config.lang('CRM_Nguoi_lap')}</label>
                                </Col>
                                <Col xs={12} lg={4} className={'control-label display_row align-center'}>
                                    {mode === 'add' ? Config.profile.SalesPersonNameU : this.evaluateID}
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label id={'evaluationdateLbW19F1031'} lang={'CRM_Ngay_danh_gia'}
                                           className={'control-label mgb0'}>{Config.lang('CRM_Ngay_danh_gia')}</label>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <DateBox
                                                dateSerializationFormat={'yyyy-MM-dd'}
                                                ref={'evaluationDateRefW19F1031'}
                                                width={"100%"}
                                                id={'evaluationDateW19F1031'}
                                                value={evaluationDate}
                                                onValueChanged={this.changeDate}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={false}
                                                type={'date'}
                                                disabled={saveSuccess && mode === 'add'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'dd/MM/y'}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label id={'contentLbW19F1031'} lang={'CRM_Noi_dung'}
                                           className={'control-label mgb0'}>{Config.lang('CRM_Noi_dung')}</label>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Editor
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: "fullscreen paste",
                                            toolbar: ['undo redo | bold italic underline forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | bullist numlist | link image media',
                                                'styleselect |  outdent indent table visualblocks charmap hr pagebreak  | preview code fullscreen'],
                                            paste_auto_cleanup_on_paste: true,
                                            paste_remove_styles: true,
                                            paste_remove_styles_if_webkit: true,
                                            paste_strip_class_attributes: true,
                                            entity_encoding: 'raw'
                                        }}
                                        onEditorChange={this.changeContent}
                                        value={content}
                                        disabled={saveSuccess && mode === 'add'}
                                    />
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label id={'recommendationLbW19F1031'} lang={'CRM_Kien_nghi'}
                                           className={'control-label mgb0'}>{Config.lang('CRM_Kien_nghi')}</label>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Editor
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: "fullscreen paste",
                                            toolbar: ['undo redo | bold italic underline forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | bullist numlist | link image media',
                                                'styleselect |  outdent indent table visualblocks charmap hr pagebreak  | preview code fullscreen'],
                                            paste_auto_cleanup_on_paste: true,
                                            paste_remove_styles: true,
                                            paste_remove_styles_if_webkit: true,
                                            paste_strip_class_attributes: true,
                                            entity_encoding: 'raw'
                                        }}
                                        onEditorChange={this.changeRecomment}
                                        value={recomment}
                                        disabled={saveSuccess && mode === 'add'}
                                    />
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label id={'notesLbW19F1031'} lang={'Ghi_chu'}
                                           className={'control-label mgb0'}>{Config.lang('Ghi_chu')}</label>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Editor
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: "fullscreen paste",
                                            toolbar: ['undo redo | bold italic underline forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | bullist numlist | link image media',
                                                'styleselect |  outdent indent table visualblocks charmap hr pagebreak  | preview code fullscreen'],
                                            paste_auto_cleanup_on_paste: true,
                                            paste_remove_styles: true,
                                            paste_remove_styles_if_webkit: true,
                                            paste_strip_class_attributes: true,
                                            entity_encoding: 'raw'
                                        }}
                                        onEditorChange={this.changeNotes}
                                        value={notes}
                                        disabled={saveSuccess && mode === 'add'}
                                    />
                                </Col>
                                <Col xs={12} lg={2}>
                                    <label lang={'CRM_Tai_lieu_dinh_kem'} className={'control-label mgb0'}>
                                        {Config.lang('CRM_Tai_lieu_dinh_kem')}
                                    </label>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Row md={12} sm={12} xs={12} lg={12} className={'mg0 display_row list-attach'}>
                                        {(dataAttach.length === 0 && attached.length === 0) && <span
                                            className={'text-no-data'}>{Config.lang('CRM_Khong_co_du_lieu')}</span>}

                                        {attached && attached.map((value, index) => {
                                            return (
                                                <label className={'view-attachment mgr5 control-label small mgb0'}
                                                    key={'view-attachment' + index}>
                                                    <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                    download>
                                                        {this.renderIcon(value.FileName, value.URL)}
                                                    </a>
                                                    {value.FileName}
                                                    <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                    onClick={() => {
                                                        this.deleteOldAttach(value)
                                                    }}
                                                    />
                                                </label>
                                            )
                                        })}

                                        {dataAttach.map((value, index) => {
                                            if (value.uploading) {
                                                return (<div className={'view-loading'}>
                                                    <i key={index}
                                                    className="fas fa-spinner fa-spin"/>
                                                </div>)
                                            } else return (
                                                <label className={'view-attachment mgr5 control-label small mgb0'}
                                                    key={'view-attachment1' + index}>
                                                    {this.renderIcon(value.FileName, value.URL)}
                                                    {value.FileName}
                                                    <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                    onClick={() => {
                                                        this.deleteAttach(value)
                                                    }}/>
                                                </label>
                                            );
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {history && codeID &&
                        <ActivityComponent
                            condition={{codeID: codeID}}
                            ref={'activityRef'}
                            onClose={() => this.showHistory()}
                        >
                        </ActivityComponent>}
                    </Row>
                </Form>
                <input
                    id={'attachmentW19F1031'}
                    multiple={'multiple'}
                    type="file"
                    className="media_upload_input hide"
                    onChange={this.upLoadAttach}
                />
            </div>
        )
    }
}

export default connect(state => ({
        cboEvaluate: state.w19f1031.cboEvaluate,
    }),
    (dispatch) => ({
        w19F1031Action: bindActionCreators(w19F1031Action, dispatch)
    }))(W19F1031)
